import React, { useState, useEffect, useMemo } from "react";
import {
  Divider,
  LinkButton,
  NumberInput,
  PageContainer,
  TabController,
  TextInput,
} from "../../../components";
import { PortasPedidoGrid } from "./components/PortasPedidoGrid";
import { Card, Row, Spinner } from "reactstrap";
import { useParams } from "react-router-dom";
import { apiGet, apiPost, apiDelete, apiPut } from "../../../api";
import { useHistory } from "react-router-dom";
import IncluirKitPortasAbrir from "./components/IncluirKitPortasAbrir";
import { TabBody } from "../../../components/TabController";
import IncluirKitPortasCorrer from "./components/IncluirKitPortasCorrer";
import IncluirComponentes from "./componentes/IncluirComponentes";
import ComponentesGrid from "./componentes/components/ComponentesGrid";
import IncluirKitMarcoPassagem from "./components/IncluirKitMarcoPassagem";
import { ArrowLeft } from "react-feather";
import pedidosRoutes from "../../../routes/modules/pedidos";
import IncluirKitPortasDupla from "./components/IncluirKitPortasDupla";

export const IncluirAlterarPedido = () => {
  const { id } = useParams();
  const [idPedidoCab, setIdPedidoCab] = useState(id);
  const [descricaoPed, setDescricaoPed] = useState(null);
  const [loadingDadosPedido, setLoadingDadosPedido] = useState(true);
  const [markup, setMarkup] = useState(0);
  const [totalPedido, setTotalPedido] = useState(0);
  const [params, setParams] = useState(null);
  const [recalcularMarkup, setRecalcularMarkup] = useState(false);
  const [kitsPortas, setKitsPortas] = useState([]);
  const [componentes, setComponentes] = useState([]);
  const history = useHistory();

  const cabAberto = useMemo(
    () => ![0, null, undefined].includes(idPedidoCab),
    [idPedidoCab]
  );

  const semItens = useMemo(
    () => kitsPortas.length + componentes.length === 0,
    [kitsPortas, componentes]
  );

  const buscarDadosPedido = async (idPedido) => {
    const ret = await apiGet(`pedido/buscar_dados/${idPedido}/`);
    if (ret) {
      setDescricaoPed(ret.descricao_ped);
      setMarkup(ret.markup_ped);
      setKitsPortas(ret.itens);
      setTotalPedido(parseFloat(ret.vlr_tot_itens));
    }
  };

  const buscarItensPedido = async (idPedido) => {
    const ret = await apiGet(`pedido/listar_itens/${idPedido}/`);
    if (ret) {
      setTotalPedido(ret.tot_pedido);
      setKitsPortas(ret.itens);
    }
  };

  const buscarComponentesPedido = async (idPedido) => {
    const ret = await apiGet(`pedido/componentes/listar/${idPedido}/`);
    if (ret) {
      setTotalPedido(ret.tot_pedido);
      setComponentes(ret.itens);
    }
  };

  const buscarDados = async () => {
    try {
      await buscarParametros();
      if (id != null) {
        // Buscar dados do pedido para alteração
        setIdPedidoCab(id);
        await buscarDadosPedido(id);
        await buscarItensPedido(id);
        await buscarComponentesPedido(id);
      }
    } finally {
      setLoadingDadosPedido(false);
    }
  };

  const buscarParametros = async () => {
    const ret = await apiGet("pedido/buscar_params/");

    if (ret) {
      setParams(ret);
      setMarkup(ret.markup_pad);
    } else {
      history.goBack();
    }
  };

  useEffect(() => {
    buscarDados();
  }, []);

  const abrirCab = async () => {
    const payload = {
      descricao_ped: descricaoPed ?? "",
      markup_ped: parseFloat(markup),
    };
    const ret = await apiPost("pedido/abrir_cab/", payload, {
      returnDataSuccess: true,
      successMesage: false,
    });

    if (ret?.id_pedido) {
      setIdPedidoCab(ret.id_pedido);
      return ret.id_pedido;
    } else {
      return null;
    }
  };

  const handleDeleteItem = async (idItem) => {
    const ret = await apiDelete(`pedido/excluir_item/${idItem}/`);

    if (ret) {
      buscarItensPedido(idPedidoCab);
    }
  };

  const handleDeleteComponente = async (idComponente) => {
    const ret = await apiDelete(`pedido/componentes/excluir/${idComponente}/`);

    if (ret) {
      buscarComponentesPedido(idPedidoCab);
    }
  };

  const handleMarkupAlterado = async () => {
    if (recalcularMarkup && idPedidoCab) {
      const ret = await apiPut(`pedido/recalcular_markup/`, {
        id_pedido: parseInt(idPedidoCab),
        markup_novo: parseFloat(markup),
      });

      if (ret) {
        setRecalcularMarkup(false);
        buscarItensPedido(idPedidoCab);
        buscarComponentesPedido(idPedidoCab);
      }
    }
  };

  const incluirItem = async (payload) => {
    let idPedidoVincularItem = idPedidoCab;
    if (!cabAberto) {
      const retAbrirCab = await abrirCab();
      if (retAbrirCab) {
        idPedidoVincularItem = retAbrirCab;
      } else {
        return;
      }
    }

    payload = {
      ...payload,
      id_pedido: parseInt(idPedidoVincularItem),
      markup: parseFloat(markup),
    };

    const ret = await apiPost("pedido/incluir_item/", payload, {
      successMesage: false,
    });

    if (ret) {
      buscarItensPedido(idPedidoVincularItem);
    }

    return ret;
  };

  const incluirComponente = async (payload) => {
    let idPedidoVincularItem = idPedidoCab;
    if (!cabAberto) {
      const retAbrirCab = await abrirCab();
      if (retAbrirCab) {
        idPedidoVincularItem = retAbrirCab;
      } else {
        return;
      }
    }

    payload.id_pedido = parseInt(idPedidoVincularItem);

    const ret = await apiPost("pedido/componentes/incluir/", payload, {
      successMesage: false,
    });

    if (ret) {
      await buscarComponentesPedido(idPedidoVincularItem);
    }

    return ret;
  };

  const qtdPortasPedido = useMemo(() => {
    return kitsPortas.length;
  }, [kitsPortas]);

  return (
    <PageContainer
      title={
        <>
          <ArrowLeft
            size={15}
            style={{ marginBottom: "3.55px", cursor: "pointer" }}
            onClick={() => {
              history.push(pedidosRoutes.path);
            }}
          />{" "}
          {id ? `Alterar Pedido Nº ${id}` : "Incluir Pedido"}
        </>
      }
      showNumber={false}
    >
      {loadingDadosPedido ? (
        <div
          style={{
            height: "100vh",
            display: "flex",
            transform: "translateY(-106px)",
          }}
        >
          <Spinner
            style={{
              width: "3rem",
              height: "3rem",
              display: "flex",
              margin: "auto",
            }}
          />
        </div>
      ) : (
        <>
          <Card body>
            <Row>
              <TextInput
                autoFocus
                md={5}
                label="Descrição do Pedido"
                onChange={setDescricaoPed}
                value={descricaoPed}
                maxLength={15}
              />
              <NumberInput
                label="Markup"
                decimalPlaces={2}
                value={markup}
                onChange={(v) => {
                  if (v !== markup) {
                    setRecalcularMarkup(true);
                    setMarkup(v);
                  }
                }}
                onBlur={handleMarkupAlterado}
                isPercentage
                md="auto"
              />
              <NumberInput
                md="auto"
                divClassName="ml-auto"
                label="Total dos Itens"
                disabled
                disabledBackground="#c1dec9"
                value={totalPedido}
              />
              <LinkButton
                color="success"
                md="auto"
                pathname={`/pedidos/${idPedidoCab}/confirmar/`}
                disabled={!cabAberto || semItens}
                disabledHint="Por favor, inclua ao menos um item no pedido"
              >
                Continuar Pedido
              </LinkButton>
            </Row>
          </Card>
          <Card body>
            <TabController>
              <TabBody title="Kit Portas de Abrir">
                <IncluirKitPortasAbrir
                  params={params}
                  incluirItem={incluirItem}
                  qtdPortas={qtdPortasPedido}
                />
              </TabBody>
              <TabBody title="Kit Portas de Correr">
                <IncluirKitPortasCorrer
                  params={params}
                  incluirItem={incluirItem}
                  qtdPortas={qtdPortasPedido}
                />
              </TabBody>
              <TabBody title="Kit Marco de Passagem">
                <IncluirKitMarcoPassagem
                  incluirItem={incluirItem}
                  qtdPortas={qtdPortasPedido}
                />
              </TabBody>
              <TabBody title="Kit Portas Duplas">
                <IncluirKitPortasDupla
                  incluirItem={incluirItem}
                  qtdPortas={qtdPortasPedido}
                />
              </TabBody>
              <TabBody title="Componentes">
                <IncluirComponentes
                  params={params}
                  incluirComponente={incluirComponente}
                />
              </TabBody>
            </TabController>
          </Card>
          {kitsPortas.length > 0 && (
            <Card body>
              <Divider className="mt-2">
                Kits de Porta - Qtd {kitsPortas.length}
              </Divider>
              <PortasPedidoGrid
                data={kitsPortas}
                handleDeleteItem={handleDeleteItem}
              />
            </Card>
          )}
          {componentes.length > 0 && (
            <Card body>
              <Divider className="mt-2">
                Componentes - Qtd {componentes.length}
              </Divider>
              <ComponentesGrid
                data={componentes}
                handleDeleteItem={handleDeleteComponente}
              />
            </Card>
          )}
        </>
      )}
    </PageContainer>
  );
};
