import React, { useState } from "react";
import { UncontrolledTooltip } from "reactstrap";

// Campo scheme aguarda lista de objetos seguindo o esquema:
// {value: "valor", hint: "descrição", color: "cor",}
export const ColorField = ({ scheme, value }) => {
  const [id] = useState(Math.floor(Math.random() * Date.now()));
  return (
    <>
      <div
        style={{
          backgroundColor:
            scheme.find((e) => e.value === value)?.color ?? "white",
          width: "40px",
          height: "20px",
          borderRadius: "4px",
          marginLeft: "auto",
          marginRight: "auto",
        }}
        id={`color-field-${id}`}
      />
      <UncontrolledTooltip placement="bottom" target={`color-field-${id}`}>
        {scheme.find((e) => e.value === value)?.hint}
      </UncontrolledTooltip>
    </>
  );
};
