import Cookies from "js-cookie";
import React, { FC, useEffect, useState } from "react";
import { Settings } from "react-feather";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import {
  Collapse,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  Input,
  ListGroup,
  ListGroupItem,
  Nav,
  Navbar,
  UncontrolledDropdown,
} from "reactstrap";
import { toggleSidebar } from "../redux/actions/sidebarActions";
import { logoff } from "../auth/session";

interface NavbarSearchOptions {
  link: string;
  label: string;
}

const NavbarComponent: FC = () => {
  const [data, setData] = useState<NavbarSearchOptions[]>([]);
  const [filteredData, setFilteredData] = useState<NavbarSearchOptions[]>([]);
  const [query, setQuery] = useState<string>("");
  const dispatch = useDispatch();

  const onLogout = async () => {
    await logoff();
    window.location.href = "/acesso/login/";
  };

  const getNickname = () => {
    let nickname = Cookies.get("nickname");
    if (nickname) {
      return nickname;
    } else {
      return "Não autenticado";
    }
  };

  const clearQuery = () => setQuery("");

  const getOptions = () => {
    const options: NavbarSearchOptions[] = [
      {
        link: "/resultados/vendas/vendas_por_grupo",
        label: "Resultados > Vendas > Vendas por Grupo e Vendedor [5003]",
      },
      {
        link: "/resultados/vendas/vendas_por_vendedor",
        label: "Resultados > Vendas > Vendas por Vendedor [5004]",
      },
      {
        link: "/resultados/vendas/percentual_vendas_vendedor",
        label: "Resultados > Vendas > Percentual de Vendas por Vendedor [5005]",
      },
      {
        link: "/resultados/vendas/cumulativo_vendas_vendedor",
        label: "Resultados > Vendas > Cumulativo de Vendas por Vendedor [5006]",
      },
      {
        link: "/resultados/comissionamento/detalhamento",
        label: "Resultados > Comissionamento > Detalhamento [5009]",
      },
      {
        link: "/resultados/comissionamento/gerencia",
        label: "Resultados > Comissionamento > Gerência [5012]",
      },
      {
        link: "/resultados/venda-os-rel-forma-recebimento",
        label: "Resultados > Relatório Forma de Recebimento [5008]",
      },
      {
        link: "/bancario/boleto",
        label: "Bancário > Cobrança > Impressão de Boletos [0001]",
      },
      {
        link: "/bancario/remessa",
        label: "Bancário > Cobrança > Remessa Bancária [0002]",
      },
      {
        link: "/financeiro/duplicatas_receber",
        label: "Financeiro - Receber > Contas a Receber [0005]",
      },
      {
        link: "/crm/sug_venda_os",
        label: "CRM > Sugestão de Agendamento [0007]",
      },
      {
        link: "/ordem_servico/atendimento",
        label: "Ordens de Serviço > Atendimento [0006]",
      },
      {
        link: "/ordem_servico/demonstrativos/rendimento_por_tecnico",
        label:
          "Ordens de Serviço > Demonstrativos > Rendimento por Técnico [5002]",
      },
      {
        link: "/ordem_servico/demonstrativos/horas_vendidas_x_horas_trabalhadas",
        label:
          "Ordens de Serviço > Demonstrativos > Horas Vendidas x Trabalhadas [5011]",
      },
      {
        link: "/ordem_servico/demonstrativos/horas_cliente",
        label: "Ordens de Serviço > Demonstrativos > Horas por Cliente [5001]",
      },
      {
        link: "/venda_direta/cadastros/rotas",
        label:
          "Frente de Venda Mobile > Cadastro > Rotas e Roteirizações [0010]",
      },
      {
        link: "/venda_direta/cadastros/despesas_jornada",
        label: "Frente de Venda Mobile > Cadastro > Despesas de Jornada [0011]",
      },
      {
        link: "/venda_direta/cadastro/veiculos_empresa",
        label: "Frente de Venda Mobile > Cadastro > Veículos [0012]",
      },
      {
        link: "/venda_direta/cadastro/lista_precos",
        label: "Frente de Venda Mobile > Cadastro > Lista de Preços [0013]",
      },
      {
        link: "/venda_direta/jornada",
        label: "Frente de Venda Mobile > Jornada Principal [0009]",
      },
      {
        link: "/venda_direta/jornada_dia",
        label: "Frente de Venda Mobile > Jornada Diária [0014]",
      },
      {
        link: "/frente_venda_desktop/cadastro/permissoes",
        label: "Frente de Venda Desktop > Cadastro > Uso de Cashback [0008]",
      },
      {
        link: "/controladoria/dre",
        label: "Controladoria > DRE [5007]",
      },
      {
        link: "/controladoria/fluxo_caixa",
        label: "Controladoria > Fluxo de Caixa [5010]",
      },
      {
        link: "/cartoes/cadastro/taxas_cartoes",
        label: "Cartões > Cadastro > Taxas de Cartão [0003]",
      },
      {
        link: "/cartoes/cadastro/taxas_cartoes",
        label: "Cartões > Gerenciamento [0004]",
      },
      {
        link: "/venda_direta/gerenciamento_vendas",
        label:
          "Frente de Venda Mobile > Gerenciamento de Vendas Diretas [0024]",
      },
    ];

    const _filteredData = options.filter((element) => {
      return element.label.toLowerCase().includes(query.toLowerCase());
    });

    setData(options);
    setFilteredData(_filteredData);
  };

  const handleInputText = (event: React.FormEvent<HTMLInputElement>) => {
    const _query = event.currentTarget.value;

    const _filteredData = data.filter((element) => {
      return element.label.toLowerCase().includes(/\s/ + query.toLowerCase());
    });

    getOptions();
    setFilteredData(_filteredData);
    setQuery(_query);
  };

  useEffect(() => {
    const handleToggle = (event: KeyboardEvent) => {
      if (event.key === "q" && document.activeElement!.tagName === "BODY") {
        dispatch(toggleSidebar());
      }
    };

    document.addEventListener("keydown", handleToggle);
  }, [dispatch]);

  return (
    <Navbar color="white" light expand>
      <span
        className="sidebar-toggle d-flex mr-2"
        onClick={() => dispatch(toggleSidebar())}
      >
        <i className="hamburger align-self-center" />
      </span>

      <Form inline>
        <Input
          type="text"
          placeholder="Pesquisa rápida..."
          aria-label="Pesquisa"
          className="form-control-no-border mr-sm-2"
          onChange={handleInputText}
          value={query}
        />
        {query !== "" && (
          <div className="group-options scrollable">
            <ListGroup>
              {filteredData.map((i) => (
                <ListGroupItem className="search-item">
                  <Link
                    to={{
                      pathname: i.link,
                    }}
                    onClick={clearQuery}
                  >
                    {i.label}
                  </Link>
                </ListGroupItem>
              ))}
            </ListGroup>
          </div>
        )}
      </Form>

      <Collapse navbar>
        <Nav className="ml-auto" navbar>
          <UncontrolledDropdown nav inNavbar>
            <span className="d-inline-block d-sm-none">
              <DropdownToggle nav caret>
                <Settings size={18} className="align-middle" />
              </DropdownToggle>
            </span>

            <span className="d-none d-sm-inline-block">
              <DropdownToggle nav caret>
                <span className="text-dark">{getNickname()}</span>
              </DropdownToggle>
            </span>

            <DropdownMenu right>
              <DropdownItem onClick={onLogout}>Sair</DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Nav>
      </Collapse>
    </Navbar>
  );
};

export default NavbarComponent;
