import React from "react";
import { ColorField, TableDelete } from "../../../../components";
import { formatNumber } from "../../../../coreUtils";
import { Table } from "../../../../components/Table";

const statusPedido = [
  {
    value: "DIG",
    color: "#8102a1",
    hint: "Em Digitação",
  },
  {
    value: "AEE",
    color: "#4287f5",
    hint: "Aguardando Envio para Ecoplac",
  },
  {
    value: "DPA",
    color: "#b2771d",
    hint: "Devolvido para Ajuste",
  },
  {
    value: "AGC",
    color: "#1cb9fc",
    hint: "Enviados para Ecoplac",
  },
];

export const GerenciamentoPedidosGrid = ({
  data,
  setSelected,
  handleDelete,
}) => {
  const columns = [
    {
      dataField: "nro_pedido",
      text: "#",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "descricao_ped",
      text: "Descrição",
      align: "left",
      headerAlign: "left",
    },
    {
      dataField: "nome_cliente",
      text: "Cliente",
      align: "left",
      headerAlign: "left",
    },
    {
      dataField: "vlr_ecoplac",
      text: "Valor Ecoplac",
      align: "right",
      headerAlign: "right",
      formatter: (c) => formatNumber(c, true, 2),
    },
    {
      dataField: "markup_ped",
      text: "Markup",
      align: "right",
      headerAlign: "right",
      formatter: (c) => formatNumber(c, true, 2),
    },
    {
      dataField: "custo_frete",
      text: "Custo Frete",
      align: "right",
      headerAlign: "right",
      formatter: (c) => formatNumber(c ?? 0, true, 2),
    },
    {
      dataField: "vlr_frete",
      text: "Frete Cliente",
      align: "right",
      headerAlign: "right",
      formatter: (c) => formatNumber(c ?? 0, true, 2),
    },
    {
      dataField: "vlr_servico_instalacao",
      text: "Instalação",
      align: "right",
      headerAlign: "right",
      formatter: (c) => formatNumber(c ?? 0, true, 2),
    },
    {
      dataField: "vlr_espuma_expansiva",
      text: "Espuma Expansiva",
      align: "right",
      headerAlign: "right",
      formatter: (c) => formatNumber(c ?? 0, true, 2),
    },
    {
      dataField: "vlr_total",
      text: "Valor de Venda",
      align: "right",
      headerAlign: "right",
      formatter: (c) => formatNumber(c, true, 2),
    },
    {
      dataField: "status",
      text: "Status",
      align: "center",
      headerAlign: "center",
      formatter: (c) => <ColorField scheme={statusPedido} value={c} />,
    },
    {
      dataField: "act_delete",
      text: "",
      align: "center",
      headerAlign: "center",
      formatter: (c, row) => (
        <TableDelete
          tooltipPlacement="left"
          hint="Descartar Pedido"
          onClick={() => {
            handleDelete(row.id, row.nro_pedido);
          }}
        />
      ),
    },
  ];

  return (
    <Table
      data={data}
      columns={columns}
      onSelect={setSelected}
      paginated
      clearSelectionOnUpdate
      growIntoPageSize
    />
  );
};
