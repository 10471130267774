import React, { useState, useCallback, useEffect } from "react";
import {
  AsyncComboBox,
  Divider,
  FormButton,
  FormCheckbox,
  NumberInput,
  PageContainer,
  RadioGroup,
  TextInput,
} from "../../../components";
import { Card, Row, Spinner } from "reactstrap";
import { apiGet, apiPost, apiPut } from "../../../api";
import { toastr } from "react-redux-toastr";
import { useHistory, useParams } from "react-router-dom";
import { Redirect } from "react-router-dom";
import { alterarPedidoRoute } from "../../../routes/modules/pedidos";
import { ArrowLeft } from "react-feather";
import { useSingleRequest } from "../../../api";

const locaisEntrega = [
  { label: "Eu Retiro na Ecoplac", value: "EU_RETIRO" },
  { label: "Ecoplac Entrega", value: "ECO_ENTREGA" },
  { label: "Transportadora", value: "TRANSP_RETIRA" },
];

const enderecosEntrega = [
  { label: "Meu Endereço", value: "END_USUARIO" },
  { label: "Outros", value: "OUTRO" },
];

const tipoLocaisEntrega = [
  { label: "Casa do Cliente", value: "CASA_CLI" },
  { label: "Condomínio", value: "CONDOMINIO" },
  { label: "Entrega Especial Combinada", value: "ESPECIAL" },
];

export const ConfirmarPedido = () => {
  const singleRequestWrapper = useSingleRequest();
  const { id: idPedido } = useParams();
  const history = useHistory();
  const [redirect, setRedirect] = useState(false);
  const [dadosPedido, setDadosPedido] = useState({});
  const [informarCliente, setInformarCliente] = useState(false);
  const [localEntrega, setLocalEntrega] = useState(locaisEntrega[0].value);
  const [tipoLocalEntrega, setTipoLocalEntrega] = useState(
    tipoLocaisEntrega[0].value
  );
  const [idCliente, setIdCliente] = useState(null);
  const [enderecoEntrega, setEnderecoEntrega] = useState(
    enderecosEntrega[0].value
  );
  const [contatoReceb, setContatoReceb] = useState("");
  const [logradouro, setLogradouro] = useState("");
  const [numeroEntrega, setNumeroEntrega] = useState("");
  const [complementoEntrega, setComplementoEntrega] = useState("");
  const [bairroEntrega, setBairroEntrega] = useState("");
  const [cidadeEntrega, setCidadeEntrega] = useState(null);
  const [nomeTransportadora, setNomeTransportadora] = useState("");
  const [vlrFrete, setVlrFrete] = useState(0);
  const [vlrServicoInstalacao, setVlrServicoInstalacao] = useState(0);
  const [vlrEspumaExpansiva, setVlrEspumaExpansiva] = useState(0);
  const [custoFrete, setCustoFrete] = useState(0);
  const [observ, setObserv] = useState(null);
  const [loading, setLoading] = useState(true);

  const buscarDadosPedido = useCallback(async () => {
    console.log("procou buscarDadosPedido");
    const dadosPed = await apiGet(`pedido/buscar_dados/${idPedido}/`);

    if (dadosPed) {
      setDadosPedido(dadosPed);
      if (dadosPed.id_cliente) {
        setInformarCliente(true);
        setIdCliente(dadosPed.id_cliente);
      }
      setObserv(dadosPed.observ);
      setVlrFrete(parseFloat(dadosPed.vlr_frete));
      setVlrServicoInstalacao(parseFloat(dadosPed.vlr_servico_instalacao));
      setVlrEspumaExpansiva(parseFloat(dadosPed.vlr_espuma_expansiva));
      setCustoFrete(parseFloat(dadosPed.custo_frete));

      if (dadosPed.tem_entrega === false) {
        setLocalEntrega("EU_RETIRO");
      } else {
        setLocalEntrega("ECO_ENTREGA");
        setTipoLocalEntrega(dadosPed.entrega?.tipo_local_entrega);
        setEnderecoEntrega(dadosPed.entrega?.local_entrega);
        setContatoReceb(dadosPed.entrega?.contato_receb);
        setLogradouro(dadosPed.entrega?.endereco);
        setNumeroEntrega(dadosPed.entrega?.numero);
        setBairroEntrega(dadosPed.entrega?.bairro);
        setComplementoEntrega(dadosPed.entrega?.complemento);
        setCidadeEntrega(dadosPed.entrega?.id_cidade);
      }

      setLoading(false);
    } else {
      toastr.error("Erro!", "Não foi possível encontrar os dados do pedido");
      history.goBack();
    }
  }, [idPedido]);

  useEffect(() => {
    buscarDadosPedido();
  }, [buscarDadosPedido]);

  const confirmarPedido = async () => {
    let locEntrega;
    if (localEntrega === "ECO_ENTREGA") {
      locEntrega = enderecoEntrega;
    } else {
      locEntrega = localEntrega;
    }
    const payload = {
      id_pedido: parseInt(idPedido),
      id_cliente: idCliente,
      local_entrega: locEntrega,
      tipo_local_entrega: tipoLocalEntrega,
      contato_receb: contatoReceb,
      endereco: logradouro,
      numero: numeroEntrega,
      complemento: complementoEntrega,
      bairro: bairroEntrega,
      id_cidade: cidadeEntrega,
      nome_transportadora: nomeTransportadora ?? "",
      vlr_frete: vlrFrete ?? 0,
      vlr_serv_instalacao: vlrServicoInstalacao ?? 0,
      vlr_espuma_expansiva: vlrEspumaExpansiva ?? 0,
      custo_frete: custoFrete ?? 0,
      observ: observ || "",
    };

    const ret = await apiPut("/pedido/concluir_pedido/", payload);
    if (ret) {
      setRedirect(true);
    }
  };

  useEffect(() => {
    if (!informarCliente) {
      setIdCliente(null);
    }
  }, [informarCliente]);

  const buscarVlrFrete = async () => {
    if (enderecoEntrega === "END_USUARIO" && localEntrega === "ECO_ENTREGA") {
      const ret = await apiGet(`cadastro/user/buscar_custo_frete/`);

      if (ret) {
        setCustoFrete(parseFloat(ret.custo_frete) * dadosPedido.qtd_itens);
        return;
      }
    }
    setCustoFrete(0);
  };

  useEffect(() => {
    buscarVlrFrete();
  }, [enderecoEntrega, localEntrega]);

  const recalcularCustoFrete = async () => {
    if (localEntrega === "ECO_ENTREGA" && enderecoEntrega === "OUTRO") {
      const payload = {
        id_pedido: parseInt(idPedido),
        local_entrega: tipoLocalEntrega || "",
        id_cidade_entrega: cidadeEntrega || null,
      };

      const ret = await singleRequestWrapper((options) =>
        apiPost(
          "pedido/uteis/calcular_custo_frete/",
          payload,
          {
            returnDataSuccess: true,
            successMesage: false,
          },
          options
        )
      );
      if (ret) {
        setCustoFrete(parseFloat(ret.vlr_custo_frete));
        return;
      }
    }

    setCustoFrete(0);
  };

  useEffect(() => {
    recalcularCustoFrete();
  }, [tipoLocalEntrega, localEntrega, enderecoEntrega, cidadeEntrega]);

  useEffect(() => {
    if (localEntrega !== "ECO_ENTREGA") {
      setEnderecoEntrega("END_USUARIO");
    }
  }, [localEntrega]);

  const confirmarPedidoBtn = (
    <FormButton color="success" onClick={confirmarPedido}>
      Confirmar
    </FormButton>
  );

  return redirect ? (
    <Redirect to="/pedidos/" />
  ) : (
    <PageContainer
      title={
        <>
          <ArrowLeft
            size={15}
            style={{ marginBottom: "3.55px", cursor: "pointer" }}
            onClick={() => {
              history.push(alterarPedidoRoute.path.replace(":id", idPedido));
            }}
          />{" "}
          Confirmar Pedido
        </>
      }
      number="0002"
    >
      {loading ? (
        <Spinner
          style={{
            width: "3rem",
            height: "3rem",
            display: "flex",
            margin: "auto",
          }}
        />
      ) : (
        <>
          <Card body>
            <Row>
              <TextInput
                md={5}
                label="Descrição do Pedido"
                disabled
                value={dadosPedido.descricao_ped}
              />
              <div style={{ marginLeft: "auto", display: "flex" }}>
                <NumberInput
                  label="Markup"
                  value={dadosPedido.markup_ped}
                  disabled
                />
                <NumberInput
                  label="Total dos Itens"
                  value={parseFloat(dadosPedido.vlr_tot_itens ?? 0)}
                  disabled
                />
                <NumberInput
                  label="Total do Pedido"
                  value={
                    parseFloat(dadosPedido.vlr_tot_itens ?? 0) +
                    vlrFrete +
                    vlrEspumaExpansiva +
                    vlrServicoInstalacao +
                    custoFrete
                  }
                  disabled
                />
              </div>
            </Row>
          </Card>
          <Card body>
            <Row style={{ zIndex: 999 }}>
              <FormCheckbox
                label="Informar o cliente"
                checked={informarCliente}
                onChange={() => setInformarCliente(!informarCliente)}
              />
              {informarCliente && (
                <AsyncComboBox
                  isConcatField
                  concatModelName="cliente"
                  label="Cliente"
                  isClearable
                  onChange={setIdCliente}
                  value={idCliente}
                  defaultOptions
                />
              )}
            </Row>
            <Row className="mt-3">
              <TextInput
                label="Observação do Pedido"
                md={null}
                type="textarea"
                value={observ}
                onChange={setObserv}
                rows={3}
              />
            </Row>
            <Row>
              <NumberInput
                label="Valor Total de Frete"
                md="auto"
                value={vlrFrete}
                onChange={setVlrFrete}
              />
              <NumberInput
                label="Valor Total Instalação"
                md="auto"
                value={vlrServicoInstalacao}
                onChange={setVlrServicoInstalacao}
              />
              <NumberInput
                label="Valor Total Espuma Expansiva"
                md="auto"
                value={vlrEspumaExpansiva}
                onChange={setVlrEspumaExpansiva}
              />
            </Row>
            <Divider>Dados de Entrega</Divider>
            <Row>
              <RadioGroup
                label="Local de Entrega"
                options={locaisEntrega}
                value={localEntrega}
                onChange={setLocalEntrega}
              />
              {localEntrega === "ECO_ENTREGA" && (
                <>
                  <RadioGroup
                    label="Endereço de Entrega"
                    options={enderecosEntrega}
                    value={enderecoEntrega}
                    onChange={setEnderecoEntrega}
                  />
                  <NumberInput
                    label="Custo de Frete"
                    md={2}
                    value={custoFrete}
                    disabled
                  />
                </>
              )}
              {localEntrega === "TRANSP_RETIRA" && (
                <TextInput
                  label="Nome da Transportadora"
                  md={null}
                  value={nomeTransportadora}
                  onChange={setNomeTransportadora}
                />
              )}
              {enderecoEntrega !== "OUTRO" && <>{confirmarPedidoBtn}</>}
            </Row>
            {enderecoEntrega === "OUTRO" && (
              <>
                <Row className="pb-2">
                  <RadioGroup
                    label="Tipo de Local de Entrega"
                    options={tipoLocaisEntrega}
                    value={tipoLocalEntrega}
                    onChange={setTipoLocalEntrega}
                  />
                  <AsyncComboBox
                    isConcatField
                    concatModelName="cidade"
                    label="Cidade"
                    defaultOptions
                    onChange={setCidadeEntrega}
                    value={cidadeEntrega}
                  />
                </Row>
                <Row>
                  <TextInput
                    label="Contato de Quem irá receber"
                    md={3}
                    onChange={setContatoReceb}
                    value={contatoReceb}
                  />
                  <TextInput
                    label="Endereço de Entrega"
                    md={5}
                    onChange={setLogradouro}
                    value={logradouro}
                  />
                  <TextInput
                    label="Número"
                    md={2}
                    onChange={setNumeroEntrega}
                    value={numeroEntrega}
                  />
                </Row>
                <Row>
                  <TextInput
                    label="Bairro"
                    md={3}
                    onChange={setBairroEntrega}
                    value={bairroEntrega}
                  />
                  <TextInput
                    label="Complemento"
                    md={3}
                    onChange={setComplementoEntrega}
                    value={complementoEntrega}
                  />
                  {confirmarPedidoBtn}
                </Row>
              </>
            )}
          </Card>
        </>
      )}
    </PageContainer>
  );
};
