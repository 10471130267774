import { View } from "@react-pdf/renderer";
import React from "react";
import { TextRow } from "./TextRow";

export const ReportDataList = ({
  columns = [],
  data = [],
  stripped,
  fixedHeader,
  headerStyle,
  rowStyle,
}) => {
  return (
    <View>
      <TextRow
        fixed={fixedHeader}
        style={headerStyle}
        columns={columns.map((item) => {
          return {
            col: item.col,
            text: item.text,
            style: {
              textAlign: item.align,
              fontFamily: "Open Sans Bold",
              marginTop: "auto",
              ...item.headerStyle,
            },
          };
        })}
      />
      {data.map((item, index) => (
        <TextRow
          wrap={false}
          style={{
            backgroundColor: stripped
              ? index % 2 === 0
                ? "#DDD"
                : "white"
              : "",
            ...rowStyle,
          }}
          columns={columns.map((col) => {
            return {
              col: col.col,
              text: col.formatter
                ? col.formatter(item[col.dataField], item, index)
                : item[col.dataField],
              style: { textAlign: col.align, ...col.cellStyle },
            };
          })}
        />
      ))}
    </View>
  );
};
