import { coresPortas } from "./formatacao";

export const tiposMaterial = [
  {
    label: <span style={{ color: coresPortas.MDF }}>Premium HD</span>,
    value: "MDF",
  },
  { label: <span style={{ color: coresPortas.PVC }}>PVC</span>, value: "PVC" },
  { label: "Crua", value: "CRU" },
];

export const tiposMaterialKit = tiposMaterial.filter((e) => e.value !== "CRU");

export const tiposMaterialKitEconomico = [
  tiposMaterial[0],
  {
    label: <span style={{ color: coresPortas.M25 }}>Econômico</span>,
    value: "M25",
  },
  tiposMaterial[1],
];

export const tiposMaterialEconomico = [
  tiposMaterial[0],
  {
    label: <span style={{ color: coresPortas.M25 }}>Econômico</span>,
    value: "M25",
  },
  tiposMaterial[1],
  tiposMaterial[2],
];
