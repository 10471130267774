import React from "react";
import { ComboBox, ModalBase } from "../../../../components";
import {
  marcasFechadura,
  tiposFecha,
  tiposFechaCorrer,
} from "../componentes/utils";
import { useState } from "react";
import { Row } from "reactstrap";
import { toastr } from "react-redux-toastr";

const tamanhosCaixaFecha = [
  { label: "40", value: 40 },
  { label: "45", value: 45 },
  { label: "55", value: 55 },
];

const ModalUsinagemFechadura = ({ onConfirm, toggle, correr = false }) => {
  const [tamanhoCaixaFecha, setTamanhoCaixaFecha] = useState(null);
  const [tipoFechadura, setTipoFechadura] = useState(null);
  const [marcaFechadura, setMarcaFechadura] = useState(
    marcasFechadura[0].value
  );

  const onConfirmInternal = async () => {
    if (!tamanhoCaixaFecha) {
      toastr.warning(
        "Verifique os Campos",
        "Por favor, informe o tamanho da caixa da fechadura"
      );
      return;
    }

    if (!tipoFechadura) {
      toastr.warning(
        "Verifique os Campos",
        "Por favor, informe o tipo da fechadura"
      );
      return;
    }

    if (!marcaFechadura) {
      toastr.warning(
        "Verifique os Campos",
        "Por favor, informe a marca da fechadura"
      );
      return;
    }

    await onConfirm(tamanhoCaixaFecha, tipoFechadura, marcaFechadura);
    toggle();
  };

  return (
    <ModalBase
      isOpen
      onConfirm={onConfirmInternal}
      toggle={toggle}
      title="Por favor, informe as informações da fechadura para usinagem"
      size="lg"
    >
      <Row>
        <ComboBox
          md={4}
          label="Tamanho da Caixa"
          options={tamanhosCaixaFecha}
          value={tamanhoCaixaFecha}
          onChange={setTamanhoCaixaFecha}
          isSearchable={false}
        />
        <ComboBox
          md={4}
          label="Tipo de Fechadura"
          options={correr ? tiposFechaCorrer : tiposFecha}
          value={tipoFechadura}
          onChange={setTipoFechadura}
          isSearchable={false}
        />
        <ComboBox
          md={4}
          label="Marca Fechadura"
          options={marcasFechadura}
          value={marcaFechadura}
          onChange={setMarcaFechadura}
          isSearchable={false}
        />
      </Row>
    </ModalBase>
  );
};

export default ModalUsinagemFechadura;
