import React, { useState, useEffect } from "react";
import { Row } from "reactstrap";
import {
  AsyncComboBox,
  ComboBox,
  FormButton,
  IntegerInput,
  NumberInput,
  RadioGroup,
} from "../../../../../components";
import { apiGet } from "../../../../../api";

const tiposVista = [
  { label: "Fixa", value: "FIXA" },
  { label: "Regulável", value: "REGULAVEL" },
  { label: "Correr", value: "CORRER" },
  { label: "Super Regulável", value: "SUPER_REGULA" },
];

const IncluirVistaForm = ({ incluirComponente, tiposMaterial }) => {
  const [idComponente, setIdComponente] = useState(null);
  const [descricaoComponente, setDescricaoComponente] = useState(null);
  const [vlrUnitComponente, setVlrUnitComponente] = useState(0);
  const [quantidadeComponente, setQuantidadeComponente] = useState(1);
  const [idCor, setIdCor] = useState(null);
  const [tipoMaterial, setTipoMaterial] = useState(tiposMaterial[0].value);
  const [tipoVista, setTipoVista] = useState(tiposVista[0].value);
  const [larguraVista, setLarguraVista] = useState(null);
  const [largurasVista, setLargurasVista] = useState([]);
  const [comprimentoVista, setComprimentoVista] = useState(null);
  const [comprimentosVistas, setComprimentosVistas] = useState([]);

  const [coresDisponiveis, setCoresDisponiveis] = useState([]);

  const [loadingIncluirComponente, setLoadingIncluirComponente] =
    useState(false);

  const limparIncluirNovoComponente = () => {
    setIdComponente(null);
    setDescricaoComponente(null);
    setVlrUnitComponente(0);
    setQuantidadeComponente(1);
    setTipoMaterial(tiposMaterial[0].value);
    setIdCor(null);
    setComprimentoVista(null);
    setLarguraVista(null);
    setTipoVista(tiposVista[0].value);
  };

  const incluir = async () => {
    setLoadingIncluirComponente(true);
    try {
      const payload = {
        tipo_componente: "VIST",
        id_componente: idComponente,
        descricao_componente: descricaoComponente ?? "",
        quantidade: quantidadeComponente,
        vlr_unit: vlrUnitComponente,
        vlr_total: quantidadeComponente * vlrUnitComponente,
        obs: "",
      };

      if (await incluirComponente(payload)) {
        limparIncluirNovoComponente();
      }
    } finally {
      setLoadingIncluirComponente(false);
    }
  };

  const buscarVista = async () => {
    if (
      tipoMaterial != null &&
      idCor != null &&
      tipoVista != null &&
      larguraVista != null &&
      comprimentoVista != null
    ) {
      const ret = await apiGet("/cadastro/vista/listar/", {
        tipo_material: tipoMaterial,
        id_cor: idCor,
        comprimento: comprimentoVista,
        largura: larguraVista,
        tipo_vista: tipoVista,
      });
      if (ret?.length > 0) {
        setIdComponente(ret[0].id);
        setVlrUnitComponente(parseFloat(ret[0].vlr_preco));
      } else {
        setIdComponente(null);
        setVlrUnitComponente(0);
      }
    } else {
      setIdComponente(null);
      setVlrUnitComponente(0);
    }
  };

  useEffect(() => {
    buscarVista();
  }, [tipoMaterial, idCor, comprimentoVista, larguraVista, tipoVista]);

  const buscarComprimentos = async () => {
    setComprimentosVistas([]);
    setLargurasVista([]);

    const retComprimentos = await apiGet(
      "/cadastro/vista/listar_comprimentos/",
      { id_cor: idCor, tipo_material: tipoMaterial, tipo_vista: tipoVista }
    );
    setComprimentosVistas(retComprimentos || []);
    setComprimentoVista(null);
  };

  useEffect(() => {
    buscarComprimentos();
  }, [idCor, tipoMaterial, tipoVista]);

  const buscarLarguras = async () => {
    const retLarguras = await apiGet("/cadastro/vista/listar_todas_larguras/", {
      id_cor: idCor,
      tipo_material: tipoMaterial,
      tipo_vista: tipoVista,
      comprimento_vista: comprimentoVista,
    });
    setLargurasVista(retLarguras || []);
    setLarguraVista(null);
  };

  useEffect(() => {
    buscarLarguras();
  }, [idCor, tipoMaterial, tipoVista, comprimentoVista]);

  const buscarCoresDisponiveis = async (tipoMat) => {
    const ret = await apiGet(`cadastro/vista/listar_cores/${tipoMat}/`);

    setCoresDisponiveis(ret);
  };

  useEffect(() => {
    setIdCor(null);
    buscarCoresDisponiveis(tipoMaterial);
  }, [tipoMaterial]);

  return (
    <>
      <Row>
        <RadioGroup
          label="Tipo de Material*"
          options={tiposMaterial}
          value={tipoMaterial}
          onChange={setTipoMaterial}
        />
        <ComboBox
          label="Cor*"
          options={coresDisponiveis}
          onChange={setIdCor}
          md="auto"
          value={idCor}
          isSearchable={false}
          isDisabled={coresDisponiveis.length === 0}
        />
        <ComboBox
          md={2}
          label="Tipo Vista*"
          options={tiposVista}
          value={tipoVista}
          onChange={setTipoVista}
          isSearchable={false}
        />
        <ComboBox
          md={2}
          label="Comprimento da Vista*"
          options={comprimentosVistas}
          value={comprimentoVista}
          onChange={setComprimentoVista}
          isSearchable={false}
          noOptionsMessage={() => "Revise os filtros"}
          disabled={comprimentosVistas.length === 0}
        />
        <ComboBox
          md={2}
          label="Largura da Vista*"
          options={largurasVista}
          value={larguraVista}
          onChange={setLarguraVista}
          isSearchable={false}
          disabled={largurasVista.length === 0}
          noOptionsMessage={() => "Revise os filtros"}
        />
      </Row>
      <Row>
        <IntegerInput
          md={2}
          label="Quantidade"
          onChange={setQuantidadeComponente}
          value={quantidadeComponente}
        />
        <NumberInput
          md={2}
          label="Valor Unitário"
          disabled
          value={vlrUnitComponente}
        />
        <NumberInput
          md={2}
          label="Total do Item"
          disabled
          value={(quantidadeComponente ?? 0) * (vlrUnitComponente ?? 0)}
        />
        <FormButton
          md="auto"
          loading={loadingIncluirComponente}
          onClick={incluir}
          disabled={!idComponente}
          disabledHint="Preencha todos os campos marcados com *"
          color="info"
        >
          Incluir Componente
        </FormButton>
      </Row>
    </>
  );
};

export default IncluirVistaForm;
