import axios from "axios";
import Cookies from "js-cookie";
import { toastr } from "react-redux-toastr";
import settings from "./settings";
import { isDevEnv } from "./coreUtils";
import { useState } from "react";

const kClientId = "EiMAYE3RtOi3GxQtcZePyluC8PsJKQHDIoxaSLoX";
const kClientSecret =
  "ehpq98ely4mMieZDlMZbLnXHaGzPjFgQm0MiMcklpzTV1KWDA3QFsG1hzjJeShB7weIlckVRjgUyFOkUFZFP3tmriBjCCUvm45fqs6Gcz95Z2omFst8wwnbVwWpSaYVo";

let api = axios.create({
  baseURL: isDevEnv() ? settings.API_ADDRESS_DEV : settings.API_ADDRESS,
  headers: {
    Authorization: `Bearer ${Cookies.get("access-token")}`,
  },
});

export const updateAPI = (accessToken) => {
  api = axios.create({
    baseURL: isDevEnv() ? settings.API_ADDRESS_DEV : settings.API_ADDRESS,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

// Atualiza token caso necessário
const refreshToken = async (error) => {
  try {
    const data = new URLSearchParams({
      grant_type: "refresh_token",
      refresh_token: Cookies.get("refresh-token"),
    });
    await api
      .post(`/o/token/`, data, {
        timeout: 5000,
        auth: {
          username: kClientId,
          password: kClientSecret,
        },
      })
      .then(async (res) => {
        Cookies.set("access-token", res.data.access_token);
        Cookies.set("refresh-token", res.data.refresh_token);
        return res;
      })
      .catch((_) => {
        return error;
      });
  } catch (err) {
    return err;
  }
};

api.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const access_token = Cookies.get("access-token");
    if ([403, 401].includes(error.response.status) && access_token) {
      const response = await refreshToken(error);
      return response;
    }
    return Promise.reject(error);
  }
);

export { api, kClientId, kClientSecret };

export const urlParams = (params) =>
  ![null, undefined, {}].includes(params)
    ? "?" +
      new URLSearchParams(
        Object.keys(params)
          .filter((key) => ![null, undefined, ""].includes(params[key]))
          .reduce((obj, key) => {
            if (params[key] instanceof Date) {
              params[key] = params[key].toISOString().split("T")[0];
            }
            return Object.assign(obj, {
              [key]: params[key],
            });
          }, {})
      ).toString()
    : "";

export const revokeToken = async () => {
  try {
    const data = new URLSearchParams({
      token: Cookies.get("access-token"),
      client_id: kClientId,
      client_secret: kClientSecret,
    });
    await api
      .post(`/o/revoke-token/`, data, {
        timeout: 5000,
      })
      .then(async (res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  } catch (err) {
    return err;
  }
};

const processaRetorno = (ret, options) => {
  if (ret.data.success || (ret.data.hasOwnProperty("err") && !ret.data.err)) {
    if (options?.successMesage) {
      toastr.success("Sucesso", ret.data.msg);
    }

    return options?.returnDataSuccess ? ret.data.res : true;
  } else {
    const tipoRetorno = ret.data.type || "ERROR";
    if (tipoRetorno === "ERROR") {
      toastr.error("Erro", ret.data.msg, {
        disableCloseButtonFocus: true,
      });
    } else if (tipoRetorno === "WARNING") {
      toastr.warning("Atenção", ret.data.msg, {
        disableCloseButtonFocus: true,
      });
    } else if (tipoRetorno === "INFO") {
      toastr.info("Informação!", ret.data.msg, {
        disableCloseButtonFocus: true,
      });
    }
    return false;
  }
};

const processaErro = (err) => {
  if (err.message === msgCancelado) {
    return false;
  }
  toastr.error("Erro", err.message);
  return false;
};

const apiGet = async (url, params, options) =>
  await api
    .get(url + urlParams(params), { cancelToken: options?.cancelToken })
    .then((ret) =>
      processaRetorno(ret, { returnDataSuccess: true, ...options })
    )
    .catch((err) => processaErro(err));

const apiPost = async (url, payload, options) =>
  await api
    .post(url, payload)
    .then((ret) => processaRetorno(ret, { successMesage: true, ...options }))
    .catch((err) => processaErro(err));

const apiPut = async (url, payload, options) =>
  await api
    .put(url, payload)
    .then((ret) => processaRetorno(ret, { successMesage: true, ...options }))
    .catch((err) => processaErro(err));

const apiDelete = async (url) =>
  await api
    .delete(url)
    .then((ret) => processaRetorno(ret, { successMesage: true }))
    .catch((err) => processaErro(err));

const msgCancelado = "REQ_CANCELADA";

// ========== useSingleRequest ==========
// Uma forma de evitar sobreposições de requisições (por exemplo,
// em pesquisas de clientes e produtos, onde requisições mais lentas
// acabam sendo recebidas após requisições mais rápidas).
// O useSingleRequest deve ser chamado no componente da tela para cada
// "fluxo de requisições" que se quer controlar,
// gerando uma função singleRequestWrapper para uso individual.
//
// ATENÇÃO: em caso de funções handlePista com useCallback e debouncer,
// o singleRequestWrapper da função buscarDados deve ser incluído
// nas dependências do useCallback; caso contrário, não surtirá efeito
export const useSingleRequest = () => {
  const [call, setCall] = useState();

  const singleRequestWrapper = (apiFunction) => {
    if (call) {
      call.cancel(msgCancelado);
    }

    const callInner = axios.CancelToken.source();
    setCall(callInner);

    return apiFunction({ cancelToken: callInner.token });
  };

  return singleRequestWrapper;
};

export { apiGet, apiPost, apiPut, apiDelete };
