import React from "react";
import { useEffect } from "react";
import { Container } from "reactstrap";
import { useHistory } from "react-router-dom";
import { ArrowLeft } from "react-feather";

export const PageContainer = ({
  title = "Sem Título",
  number = "Sem Número",
  className = "p-0",
  canGoBack = false,
  children,
  topOptions,
  onKeyDown,
  showNumber = false,
}) => {
  const history = useHistory();

  useEffect(() => {
    if (onKeyDown) {
      const func = (e) =>
        !document.getRootNode().body.className.includes("modal-open") &&
        onKeyDown(e);

      window.addEventListener("keydown", func);

      return () => {
        window.removeEventListener("keydown", func);
      };
    }
  }, []);

  return (
    <Container fluid className={className}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <h5
          className="h5 mb-2"
          style={{ fontWeight: "bold", textTransform: "uppercase" }}
        >
          {canGoBack && (
            <>
              <ArrowLeft
                size={15}
                style={{ marginBottom: "3.55px", cursor: "pointer" }}
                onClick={() => {
                  history.goBack();
                }}
              />{" "}
            </>
          )}
          {title}
        </h5>
        {topOptions}
      </div>
      <form onSubmit={(e) => e.preventDefault()}>{children}</form>
      {showNumber && (
        <div className="num-page-div h-auto">
          <span className="text-muted text-sm num-page-span">[{number}]</span>
        </div>
      )}
    </Container>
  );
};
