import React, { useEffect, useMemo, useState } from "react";
import { Card, Row } from "reactstrap";
import { GerenciamentoPedidosGrid } from "./components/GerenciamentoPedidosGrid";
import {
  BotaoPesquisar,
  PageContainer,
  FiltroPeriodoDatas,
  LinkButton,
  FormButton,
  IntegerInput,
  ComboBox,
} from "../../../components";
import { apiDelete, apiGet, apiPut } from "../../../api";
import { PedidoPDF } from "./pdf/PedidoPDF";
import { printReport } from "../../../coreUtils";
import {
  alterarPedidoRoute,
  incluirPedidoRoute,
} from "../../../routes/modules/pedidos";
import moment from "moment";
import { toastr } from "react-redux-toastr";

const statusPedido = [
  { label: "Em Digitação", value: "DIG" },
  { label: "Aguardando Envio para Ecoplac", value: "AEE" },
  { label: "Devolvido para Ajuste", value: "DPA" },
  { label: "Enviados para Ecoplac", value: "AGC" },
];

export const GerenciamentoPedidos = () => {
  const [numPedido, setNumPedido] = useState(null);
  const [status, setStatus] = useState("DIG");
  const [dados, setDados] = useState([]);
  const [selected, setSelected] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingImpPedido, setLoadingImpPedido] = useState(false);
  const [loadingEnvioEcoplac, setLoadingEnvioEcoplac] = useState(false);
  const [dataIni, setDataIni] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1)
  );
  const [dataFim, setDataFim] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)
  );

  const buscarDados = async () => {
    const params = {
      status: status,
      numero: numPedido,
      data_ini: moment(dataIni).format("YYYY-MM-DD"),
      data_fim: moment(dataFim).format("YYYY-MM-DD"),
    };
    const ret = await apiGet("/pedido/listar/", params);
    setDados(ret || []);
  };

  const carregarDados = async () => {
    setLoading(true);
    try {
      await buscarDados();
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    carregarDados();
  }, []);

  const imprimirPedido = async () => {
    setLoadingImpPedido(true);
    try {
      const ret = await apiGet(`/pedido/impressao/buscar/${selected}/`);
      if (ret) {
        await printReport(<PedidoPDF data={ret} />, "0001");
      }
    } finally {
      setLoadingImpPedido(false);
    }
  };

  const enviarPedidoParaEcoplac = async () => {
    setLoadingEnvioEcoplac(true);
    try {
      const ret = await apiPut(`/pedido/enviar_para_ecoplac/`, {
        id_pedido: selected,
      });
      if (ret) {
        carregarDados();
      }
    } finally {
      setLoadingEnvioEcoplac(false);
    }
  };

  const pedidoSelecionadoGrid = ![0, null, undefined].includes(selected);

  let routeAlterar = null;
  if (pedidoSelecionadoGrid) {
    routeAlterar = alterarPedidoRoute.path.replace(":id", selected);
  }

  const handleDeletePedido = (idPedido, nroPedido) => {
    toastr.confirm(
      <h4>Você tem certeza que deseja descartar o pedido #{nroPedido}</h4>,
      {
        onOk: () =>
          apiDelete(`/pedido/excluir_pedido/${idPedido}/`).then(
            (v) => v && carregarDados()
          ),
        okText: "Excluir",
        cancelText: "Cancelar",
      }
    );
  };

  const statusPodeAlterar = useMemo(
    () => !["AEE", "DIG", "DPA"].includes(selectedStatus),
    [selectedStatus]
  );

  return (
    <PageContainer title="Pedidos" number="0001">
      <Card body>
        <Row>
          <IntegerInput
            label="Nº do Pedido"
            value={numPedido}
            onChange={setNumPedido}
            md={2}
          />
          <ComboBox
            label="Status do Pedido"
            md={3}
            options={statusPedido}
            value={status}
            onChange={setStatus}
          />
          <FiltroPeriodoDatas
            defaultOption="MESATU"
            defaultStart={dataIni}
            defaultEnd={dataFim}
            onChange={(dIni, dFim) => {
              setDataIni(dIni);
              setDataFim(dFim);
            }}
          />
          <BotaoPesquisar onClick={carregarDados} loading={loading} />
        </Row>
        <Row className="mt-2">
          <LinkButton
            pathname={incluirPedidoRoute.path}
            md="auto"
            color="info"
            padded={false}
          >
            Novo Pedido
          </LinkButton>
          <LinkButton
            pathname={routeAlterar}
            md="auto"
            color="warning"
            disabled={!pedidoSelecionadoGrid || statusPodeAlterar}
            disabledHint={
              !pedidoSelecionadoGrid
                ? "Por favor, selecione o pedido a ser alterado"
                : "O pedido selecionado não pode mais ser alterado"
            }
            padded={false}
          >
            Alterar Pedido
          </LinkButton>
          <FormButton
            md="auto"
            loading={loadingImpPedido}
            onClick={imprimirPedido}
            color="primary"
            disabled={!pedidoSelecionadoGrid}
            padded={false}
          >
            Imprimir
          </FormButton>
          <FormButton
            md="auto"
            loading={loadingEnvioEcoplac}
            onClick={enviarPedidoParaEcoplac}
            color="success"
            disabled={selectedStatus !== "AEE"}
            disabledHint="Por favor, selecione um pedido Ag. Envio para Ecoplac"
            padded={false}
          >
            Enviar para Ecoplac
          </FormButton>
        </Row>
      </Card>
      <Card body>
        <GerenciamentoPedidosGrid
          data={dados || []}
          setSelected={(id, _, row) => {
            setSelected(id);
            setSelectedStatus(row?.status);
          }}
          handleDelete={handleDeletePedido}
        />
      </Card>
    </PageContainer>
  );
};
