import classnames from "classnames";
import React, { Children, cloneElement, useRef, useState } from "react";
import { useEffect } from "react";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { filterFormFocusableElements } from "../coreUtils";

export const TabBody = ({
  title,
  children,
  active,
  autoFocusFirst,
  onActivate,
  disabled,
}) => {
  const ref = useRef();

  useEffect(() => {
    if (active) {
      if (autoFocusFirst && !disabled) {
        const focusableElements = filterFormFocusableElements(
          ref.current?.elements
        );

        if (focusableElements.length > 0 && focusableElements[0].focus) {
          focusableElements[0].focus();
        }
      }

      if (onActivate) onActivate();
    }
  }, [active]);
  return (
    <form name={title} onSubmit={(e) => e.preventDefault()} ref={ref}>
      {children}
    </form>
  );
};

export const TabController = ({
  children,
  onTabChange = () => {},
  autoFocusFirst = true,
  defaultActiveTab,
  numbered,
  contentStyle,
  tabClassName = "p-2",
  pills = false,
}) => {
  const [activeTab, changeTab] = useState("1");

  useEffect(() => {
    if (defaultActiveTab) {
      changeTab(defaultActiveTab);
    }
  }, []);

  return (
    <>
      <Nav tabs={!pills} pills={pills}>
        {Children.map(children, (e, index) =>
          e ? (
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === `${index + 1}` })}
                onClick={() => {
                  changeTab(`${index + 1}`);
                  onTabChange(index + 1, e.props.name);
                }}
                disabled={e.props.disabled}
              >
                {numbered && `${index + 1}.`}
                {e.props.title}
              </NavLink>
            </NavItem>
          ) : (
            <></>
          )
        )}
      </Nav>
      <TabContent activeTab={activeTab} style={contentStyle}>
        {Children.map(children, (e, index) =>
          e ? (
            <TabPane tabId={`${index + 1}`} className={tabClassName}>
              {cloneElement(e, {
                active: activeTab === `${index + 1}`,
                autoFocusFirst: autoFocusFirst,
              })}
            </TabPane>
          ) : (
            <></>
          )
        )}
      </TabContent>
    </>
  );
};
