import React, { useEffect, useState } from "react";
import { Row } from "reactstrap";
import {
  ComboBox,
  FormButton,
  IntegerInput,
  NumberInput,
} from "../../../../../components";
import { useRef } from "react";
import { apiGet } from "../../../../../api";

const tiposComponentes = [
  { label: "Batente de Correr", value: "BATE" },
  { label: "Bobina", value: "BOBI" },
  { label: "Borracha", value: "BORR" },
  { label: "Cantoneira", value: "CANT" },
  { label: "Cola", value: "COLA" },
  { label: "Dobradiça", value: "DOBR" },
  { label: "Espaçador", value: "ESPA" },
  { label: "Fechadura", value: "FECH" },
  { label: "Fecho Unha", value: "FCUN" },
  { label: "Fita de Borda", value: "FITA" },
  { label: "Grelha", value: "GREL" },
  { label: "Kit Caixa de Trilho", value: "KITC" },
  { label: "Puxador", value: "PUXA" },
  { label: "Rodapé", value: "RODA" },
  { label: "Trilho de Porta", value: "TRLH" },
];

const IncluirOutrosForm = ({ incluirComponente }) => {
  const [loadingOpcoes, setLoadingOpcoes] = useState(false);
  const [idComponente, setIdComponente] = useState(null);
  const [descricaoComponente, setDescricaoComponente] = useState(null);
  const [vlrUnitComponente, setVlrUnitComponente] = useState(0);
  const [quantidadeComponente, setQuantidadeComponente] = useState(1);
  const [tipoComponente, setTipoComponente] = useState(null);

  const [loadingIncluirComponente, setLoadingIncluirComponente] =
    useState(false);

  const [opcoesComponente, setOpcoesComponente] = useState([]);

  const componenteRef = useRef();

  const limparIncluirNovoComponente = () => {
    setIdComponente(null);
    setDescricaoComponente(null);
    setVlrUnitComponente(0);
    setQuantidadeComponente(1);
    componenteRef.current.clearValue();
  };

  const incluir = async () => {
    setLoadingIncluirComponente(true);
    try {
      const payload = {
        tipo_componente: tipoComponente,
        id_componente: idComponente,
        descricao_componente: descricaoComponente ?? "",
        quantidade: quantidadeComponente,
        vlr_unit: vlrUnitComponente,
        vlr_total: quantidadeComponente * vlrUnitComponente,
        obs: "",
      };

      if (await incluirComponente(payload)) {
        limparIncluirNovoComponente();
      }
    } finally {
      setLoadingIncluirComponente(false);
    }
  };

  const labelComboBox = {
    BATE: "Batente de Correr",
    BOBI: "Bobina",
    BORR: "Borracha",
    CANT: "Cantoneira",
    COLA: "Cola",
    DOBR: "Dobradiça",
    ESPA: "Espaçador",
    FECH: "Fechadura",
    FCUN: "Fecho Unha",
    FITA: "Fita de Borda",
    GREL: "Grelha",
    KITC: "Kit Caixa de Trilho",
    PUXA: "Puxador",
    RODA: "Rodapé",
    TRLH: "Trilho de Porta",
  }[tipoComponente];

  const buscarOpcoesComponentes = async () => {
    setLoadingOpcoes(true);
    try {
      setOpcoesComponente([]);
      if (tipoComponente) {
        const concatModelName = {
          BATE: "batente",
          BOBI: "bobina",
          BORR: "borracha",
          CANT: "cantoneira",
          COLA: "cola",
          DOBR: "dobradica",
          ESPA: "espacador",
          FECH: "fechadura",
          FCUN: "fecho_unha",
          FITA: "fita_borda",
          GREL: "grelha",
          KITC: "caixa_trilho",
          PUXA: "puxador",
          RODA: "rodape",
          TRLH: "trilho_porta",
        }[tipoComponente];

        if (concatModelName) {
          const ret = await apiGet(`/cadastro/concat/${concatModelName}/`);

          setOpcoesComponente(ret || []);
        }
      }
    } finally {
      setLoadingOpcoes(false);
    }
  };

  useEffect(() => {
    buscarOpcoesComponentes();
  }, [tipoComponente]);

  return (
    <>
      <Row>
        <ComboBox
          label="Tipo de Componente"
          options={tiposComponentes}
          onChange={(s) => {
            setTipoComponente(s);
            if (s !== tipoComponente) {
              setIdComponente(null);
              setDescricaoComponente(null);
              setVlrUnitComponente(0);
            }
          }}
          isClearable={false}
          isSearchable={false}
        />
        <ComboBox
          label={labelComboBox || "Selecione um item ao lado"}
          options={opcoesComponente}
          value={idComponente}
          onChange={(_, selected) => {
            setIdComponente(selected?.value);
            setDescricaoComponente(selected?.label);
            setVlrUnitComponente(parseFloat(selected?.vlr_preco ?? "0"));
          }}
          md={6}
          isDisabled={opcoesComponente.length === 0}
          isLoading={loadingOpcoes}
          isClearable
          ref={componenteRef}
        />
      </Row>
      <Row>
        <IntegerInput
          md={2}
          label="Quantidade"
          onChange={setQuantidadeComponente}
          value={quantidadeComponente}
        />
        <NumberInput
          md={2}
          label="Valor Unitário"
          disabled
          value={vlrUnitComponente}
        />
        <NumberInput
          md={2}
          label="Total do Item"
          disabled
          value={(quantidadeComponente ?? 0) * (vlrUnitComponente ?? 0)}
        />
        <FormButton
          disabled={!idComponente || (quantidadeComponente ?? 0) === 0}
          disabledHint="Por favor, revise os campos e tente novamente"
          md="auto"
          loading={loadingIncluirComponente}
          onClick={incluir}
          color="info"
        >
          Incluir Componente
        </FormButton>
      </Row>
    </>
  );
};

export default IncluirOutrosForm;
