import React, { useState } from "react";
import {
  Col,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import {
  Divider,
  FormButton,
  FormCheckbox,
  IntegerInput,
} from "../../../../components";
import { formatNumber } from "../../../../coreUtils";
import semRecorte from "../../../../assets/img/recortes/sem recorte.png";
import retangPequeno from "../../../../assets/img/recortes/recorte retangulo pequeno.png";
import retangGrande from "../../../../assets/img/recortes/recorte retangulo grande.png";
import circular from "../../../../assets/img/recortes/recorte circular.png";
import desclocBaseRetangulo from "../../../../assets/img/recortes/desloc base retangulo.png";
import alturaRetangulo from "../../../../assets/img/recortes/altura retangulo.png";
import larguraRetangulo from "../../../../assets/img/recortes/largura retangulo.png";
import deslocDireitaRetangulo from "../../../../assets/img/recortes/desloc direita retangulo.png";
import deslocDireitaRecorteCircular from "../../../../assets/img/recortes/desloc direita recorte circular.png";
import desclocBaseRecorteCircular from "../../../../assets/img/recortes/desloc base recorte circular.png";
import raioRecorteCircular from "../../../../assets/img/recortes/raio recorte circular.png";
import classNames from "classnames";

const nomesRecorte = {
  RETANG_PEQ: "Retângulo Pequeno",
  RETANG_GRD: "Retângulo Grande",
  CIRCULAR: "Circular",
};

const InfoRecorteModal = ({
  infosRecorte,
  onChange,
  mostrarCusto,
  custoRecorte,
  lado,
  portaDupla = false,
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const toggleModal = () => setModalIsOpen(!modalIsOpen);

  const styleImgs = {};
  if (lado === "ESQ") {
    styleImgs["transform"] = "rotateY(180deg)";
  }

  return (
    <>
      <FormButton color="secondary" md="auto" onClick={toggleModal}>
        Recorte
      </FormButton>
      {infosRecorte.tipoRecorte && (
        <div
          style={{
            display: "block",
            marginLeft: "12px",
            marginRight: "12px",
            fontSize: "12px",
            fontStyle: "italic",
            fontWeight: "bolder",
          }}
        >
          <Label style={{ display: "block" }}>
            Tipo: {nomesRecorte[infosRecorte.tipoRecorte]}
            {mostrarCusto && ` | R$ ${formatNumber(custoRecorte, true, 2)}`}
          </Label>
          <Label style={{ display: "block" }}>
            Deslocamento | Base:{" "}
            {formatNumber(infosRecorte.deslocPosBaseMm, false, 2, true)}mm |
            {lado === "DIR" ? " Direita" : " Esquerda"}:{" "}
            {formatNumber(infosRecorte.deslocPosDirMm, false, 2, true)}
            mm
          </Label>
          <Label style={{ display: "block" }}>
            {infosRecorte.tipoRecorte === "CIRCULAR" ? (
              <>
                Raio do Círculo:{" "}
                {formatNumber(infosRecorte.circularRaioMm, false, 2, true)}mm
              </>
            ) : (
              <>
                Largura:{" "}
                {formatNumber(infosRecorte.retangLarguraMm, false, 2, true)}mm |
                Altura:{" "}
                {formatNumber(infosRecorte.retangAlturaMm, false, 2, true)}mm
              </>
            )}
          </Label>
        </div>
      )}
      {["RETANG_PEQ", "RETANG_GRD"].includes(infosRecorte.tipoRecorte) && (
        <FormCheckbox
          checked={infosRecorte.incluirBaguete}
          label="Incluir Baguete"
          onChange={() =>
            onChange("incluirBaguete", !infosRecorte.incluirBaguete)
          }
        />
      )}
      <Modal isOpen={modalIsOpen} toggle={toggleModal} size="xl" centered>
        <ModalHeader toggle={toggleModal} tag="div">
          Informações do Recorte
          <div
            style={{
              display: "flex",
              margin: "auto",
              color: "red",
              fontWeight: "bold",
              textAlign: "center",
              transform: "translateX(-37px)",
            }}
          >
            Quando as folhas possuírem o mesmo tamanho, o recorte será feito NAS
            DUAS FOLHAS.
            <br />
            Se as folhas tiverem tamanhos diferentes, o recorte será feito
            SOMENTE na folha PRINCIPAL.
          </div>
        </ModalHeader>
        <ModalBody>
          <Divider className="mt-0">Selecione o Tipo de Recorte</Divider>
          <Row className="d-flex" style={{ justifyContent: "space-between" }}>
            <Col
              md={{
                size: "auto",
                offset: "1",
              }}
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <img
                style={styleImgs}
                src={semRecorte}
                alt="Sem Recorte"
                className={classNames(
                  "img-sel-recorte",
                  "mx-auto",
                  "selectable",
                  {
                    active: infosRecorte.tipoRecorte === null,
                  }
                )}
                onClick={() => onChange("tipoRecorte", null)}
              />
              <Label style={{ textAlign: "center" }}>Sem Recorte</Label>
            </Col>
            <Col
              md="auto"
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <img
                style={styleImgs}
                src={retangPequeno}
                alt="Retângulo Pequeno"
                className={classNames(
                  "img-sel-recorte",
                  "mx-auto",
                  "selectable",
                  {
                    active: infosRecorte.tipoRecorte === "RETANG_PEQ",
                  }
                )}
                onClick={() => onChange("tipoRecorte", "RETANG_PEQ")}
              />
              <Label style={{ textAlign: "center" }}>Retângulo Pequeno</Label>
            </Col>
            <Col
              md="auto"
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <img
                style={styleImgs}
                src={retangGrande}
                alt="Retângulo Grande"
                className={classNames(
                  "img-sel-recorte",
                  "mx-auto",
                  "selectable",
                  {
                    active: infosRecorte.tipoRecorte === "RETANG_GRD",
                  }
                )}
                onClick={() => onChange("tipoRecorte", "RETANG_GRD")}
              />
              <Label style={{ textAlign: "center" }}>Retângulo Grande</Label>
            </Col>
            <Col
              md="auto"
              style={{
                display: "flex",
                flexDirection: "column",
                marginRight: "8.33333%",
              }}
            >
              <img
                style={styleImgs}
                src={circular}
                alt="Circular"
                className={classNames(
                  "img-sel-recorte",
                  "mx-auto",
                  "selectable",
                  {
                    active: infosRecorte.tipoRecorte === "CIRCULAR",
                  }
                )}
                onClick={() => onChange("tipoRecorte", "CIRCULAR")}
              />
              <Label style={{ textAlign: "center" }}>Circular</Label>
            </Col>
          </Row>
          {infosRecorte.tipoRecorte !== null && (
            <>
              <Divider>Informe as Medidas</Divider>
              {infosRecorte.tipoRecorte === "CIRCULAR" ? (
                <>
                  <Row className="mt-3">
                    <Col
                      className="d-flex"
                      md={{
                        size: "auto",
                        offset: "1",
                      }}
                      style={{ alignItems: "center" }}
                    >
                      <img
                        style={styleImgs}
                        src={desclocBaseRecorteCircular}
                        alt="Deslocamento da Base do Recorte Circular"
                        className={classNames("img-sel-recorte", "mx-auto")}
                      />
                    </Col>
                    <IntegerInput
                      md="auto"
                      label="A - Deslocamento da Base (mm)"
                      value={infosRecorte.deslocPosBaseMm}
                      onChange={(v) => onChange("deslocPosBaseMm", v ?? 0)}
                    />
                    <Col
                      className="d-flex"
                      md={{
                        size: "auto",
                        offset: "1",
                      }}
                      style={{ alignItems: "center" }}
                    >
                      <img
                        style={styleImgs}
                        src={deslocDireitaRecorteCircular}
                        alt="Deslocamento da Direita do Recorte Circular"
                        className={classNames("img-sel-recorte", "mx-auto")}
                      />
                    </Col>
                    <IntegerInput
                      md="auto"
                      label={
                        <>
                          B - Deslocamento da{" "}
                          {lado === "DIR" ? "Direita" : "Esquerda"} (mm)
                        </>
                      }
                      value={infosRecorte.deslocPosDirMm}
                      onChange={(v) => onChange("deslocPosDirMm", v ?? 0)}
                    />
                  </Row>
                  <Row className="mt-3">
                    <Col
                      className="d-flex"
                      md={{
                        size: "auto",
                        offset: "1",
                      }}
                      style={{ alignItems: "center" }}
                    >
                      <img
                        style={styleImgs}
                        src={raioRecorteCircular}
                        alt="Raio do Recorte Circular"
                        className={classNames("img-sel-recorte", "mx-auto")}
                      />
                    </Col>
                    <IntegerInput
                      md="auto"
                      label="C - Raio do Círculo (mm)"
                      value={infosRecorte.circularRaioMm}
                      onChange={(v) => onChange("circularRaioMm", v ?? 0)}
                    />
                  </Row>
                </>
              ) : (
                <>
                  <Row className="mt-3">
                    <Col
                      className="d-flex"
                      md={{
                        size: "auto",
                        offset: "1",
                      }}
                      style={{ alignItems: "center" }}
                    >
                      <img
                        style={styleImgs}
                        src={desclocBaseRetangulo}
                        alt="Deslocamento da Base do Recorte Retangular"
                        className={classNames("img-sel-recorte", "mx-auto")}
                      />
                    </Col>
                    <IntegerInput
                      md="auto"
                      label="A - Deslocamento da Base (mm)"
                      value={infosRecorte.deslocPosBaseMm}
                      onChange={(v) => onChange("deslocPosBaseMm", v ?? 0)}
                    />
                    <Col
                      className="d-flex"
                      md={{
                        size: "auto",
                        offset: "1",
                      }}
                      style={{ alignItems: "center" }}
                    >
                      <img
                        style={styleImgs}
                        src={deslocDireitaRetangulo}
                        alt="Deslocamento da Direita do Recorte Retangular"
                        className={classNames("img-sel-recorte", "mx-auto")}
                      />
                    </Col>
                    <IntegerInput
                      md="auto"
                      label={
                        <>
                          B - Deslocamento da{" "}
                          {lado === "DIR" ? "Direita" : "Esquerda"} (mm)
                        </>
                      }
                      value={infosRecorte.deslocPosDirMm}
                      onChange={(v) => onChange("deslocPosDirMm", v ?? 0)}
                    />
                  </Row>
                  <Row className="mt-3">
                    <Col
                      className="d-flex"
                      md={{
                        size: "auto",
                        offset: "1",
                      }}
                      style={{ alignItems: "center" }}
                    >
                      <img
                        style={styleImgs}
                        src={larguraRetangulo}
                        alt="Largura do Recorte Retangular"
                        className={classNames("img-sel-recorte", "mx-auto")}
                      />
                    </Col>
                    <IntegerInput
                      md="auto"
                      label="D - Largura do Retângulo (mm)"
                      value={infosRecorte.retangLarguraMm}
                      onChange={(v) => onChange("retangLarguraMm", v ?? 0)}
                    />
                    <Col
                      className="d-flex"
                      md={{
                        size: "auto",
                        offset: "1",
                      }}
                      style={{ alignItems: "center" }}
                    >
                      <img
                        style={styleImgs}
                        src={alturaRetangulo}
                        alt="Altura do Recorte Retangular"
                        className={classNames("img-sel-recorte", "mx-auto")}
                      />
                    </Col>
                    <IntegerInput
                      md="auto"
                      label="C - Altura do Retângulo (mm)"
                      value={infosRecorte.retangAlturaMm}
                      onChange={(v) => onChange("retangAlturaMm", v ?? 0)}
                    />
                  </Row>
                </>
              )}
            </>
          )}
        </ModalBody>
        <ModalFooter>
          <FormButton
            padded={false}
            divClassName="pr-0"
            md="auto"
            color="danger"
            onClick={toggleModal}
          >
            Fechar
          </FormButton>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default InfoRecorteModal;
