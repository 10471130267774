export const espessurasFolha = [
  { label: "36mm", value: "36" },
  { label: "41mm", value: "41" },
];

export const espessurasFolhaPvcM25 = [{ label: "36mm", value: "36" }];

export const tiposFecha = [
  { label: "Interna", value: "INTER" },
  { label: "Externa", value: "EXTER" },
  { label: "Banheiro", value: "BANHE" },
  { label: "Rolete", value: "ROLET" },
  { label: "Asa de Avião", value: "ASAAV" },
  { label: "Concha", value: "CONCH" },
  { label: "Fechdura Aux.", value: "FEAUX" },
];

export const tiposFechaCorrer = [{ label: "Asa de Avião", value: "ASAAV" }];

export const marcasFechadura = [
  { label: "Stam", value: "STAM" },
  { label: "Imab", value: "IMAB" },
  { label: "Pado", value: "PADO" },
  { label: "Aliança", value: "ALIA" },
  { label: "Soprano", value: "SOPR" },
  { label: "Papaiz", value: "PAPA" },
  { label: "Haga", value: "HAGA" },
  { label: "Assa Abloy", value: "ASSA" },
  { label: "3F", value: "TREF" },
];

export const rebaixosMarco = [
  { label: "36mm", value: 36 },
  { label: "41mm", value: 41 },
];

export const largurasMarcos = [
  { label: "70mm", value: 70 },
  { label: "90mm", value: 90 },
  { label: "115mm", value: 115 },
  { label: "140mm", value: 140 },
  { label: "160mm", value: 160 },
  { label: "190mm", value: 190 },
  { label: "230mm", value: 230 },
];
