import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { selectedRowColor } from "../coreUtils";

export const totalRegistrosGrid = (v = 0, className = "") => (
  <span className={`table-record-count ${className}`.trim()}>
    {v} registro{v !== 1 ? "s" : ""}
  </span>
);

export const totalizeColumn = (colData) =>
  colData.reduce((acc, item) => parseFloat(acc) + parseFloat(item), 0);

export const DataTable = ({
  columns,
  data,
  keyField = "id",
  onSelect,
  onSelectAll,
  readOnly,
  isMulti,
  paginated = false,
  noWrap = false,
  hideCount = false,
  className = "",
  defaultSorted,
  striped = true,
  rowStyle,
  noDataIndication,
  expandRow,
  sizePerPage = 15,
  selected = undefined,
  cellEdit,
  hideSelectColumn,
  selectColumnPosition = "left",
  paginationTotalRenderer,
  selectable = true,
}) => {
  const onSelectInternal = (row, isSelect, rowIndex, e) =>
    onSelect && onSelect(row[keyField], row, isSelect, rowIndex, e);

  return (
    <>
      <BootstrapTable
        classes={`w-auto mn-100${noWrap ? " nowrap" : ""} ${className}`}
        selectRow={
          selectable
            ? {
                mode: isMulti ? "checkbox" : "radio",
                clickToSelect: !readOnly,
                clickToExpand: ![null, undefined].includes(expandRow),
                bgColor: selectedRowColor,
                onSelect: onSelectInternal,
                onClick: onSelectInternal,
                selected: selected,
                onSelectAll: onSelectAll,
                hideSelectColumn: hideSelectColumn ?? readOnly,
                selectColumnPosition: selectColumnPosition,
              }
            : undefined
        }
        keyField={keyField}
        data={data ? data.filter((e) => e) : []}
        columns={columns}
        bordered={false}
        striped={striped}
        noWrap={noWrap}
        pagination={
          paginated
            ? paginationFactory({
                sizePerPage: sizePerPage,
                sizePerPageList: [],
                showTotal: true,
                paginationTotalRenderer: (from, to, size) =>
                  paginationTotalRenderer
                    ? paginationTotalRenderer(from, to, size)
                    : !hideCount && totalRegistrosGrid(size),
              })
            : undefined
        }
        defaultSorted={defaultSorted}
        rowStyle={rowStyle}
        noDataIndication={noDataIndication}
        expandRow={expandRow}
        cellEdit={cellEdit}
        bootstrap4
      />
      {!paginated && !hideCount && totalRegistrosGrid(data?.length ?? 0)}
    </>
  );
};
