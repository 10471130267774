import React from "react";
import { TableCheck, TableDelete } from "../../../../../components";
import { formatNumber } from "../../../../../coreUtils";
import { Table } from "../../../../../components/Table";
import { UncontrolledTooltip } from "reactstrap";

const ComponentesGrid = ({ data, handleDeleteItem }) => {
  const columns = [
    {
      dataField: "item",
      text: "#",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "desc_tipo_componente",
      text: "Tipo",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "descricao_componente",
      text: "Descrição",
      align: "left",
      headerAlign: "left",
      formatter: (c, row) => {
        if (["FOLH"].includes(row.tipo_componente)) {
          const alturaPronta = formatNumber(row.altura_pronta, true, 0, true);
          const larguraPronta = formatNumber(row.largura_pronta, true, 0, true);
          const espessuraComp = formatNumber(
            row.espessura_componente,
            true,
            0,
            true
          );

          if (row.tipo_componente === "FOLH") {
            c += ` ${alturaPronta} x ${larguraPronta} x ${espessuraComp}`;

            if (row.usinar_para_fechadura) {
              c +=
                ` - Usinada ${row.desc_marca_fecha} ${row.desc_tipo_fecha} ` +
                `CX${parseInt(row.tamanho_caixa_fecha)}`;
            }
          }
        }
        return c;
      },
    },
    {
      dataField: "id_fita_borda",
      text: "Borda",
      align: "center",
      headerAlign: "center",
      formatter: (c, row) =>
        row.id_fita_borda && (
          <>
            <div
              style={{ width: "100%", height: "100%" }}
              id={`fita-borda-componente-${row.id}`}
            >
              <TableCheck value={true} />
            </div>
            <UncontrolledTooltip
              target={`fita-borda-componente-${row.id}`}
              placement="left"
            >
              {row.desc_fita_borda}
            </UncontrolledTooltip>
          </>
        ),
    },
    {
      dataField: "vao",
      text: "Medidas Brutas",
      align: "center",
      headerAlign: "center",
      formatter: (_, row) => {
        const alturaComp = formatNumber(row.altura_componente, true, 0, true);
        const larguraComp = formatNumber(row.largura_componente, true, 0, true);
        const espessuraComp = formatNumber(
          row.espessura_componente,
          true,
          0,
          true
        );
        if (row.tipo_componente === "FOLH") {
          return `${alturaComp} x ${larguraComp}`;
        }
        if (row.tipo_componente === "MARC") {
          if (isNaN(parseFloat(row.largura_componente))) {
            return `${alturaComp}x${espessuraComp}`;
          }
          return `${alturaComp}x${espessuraComp} R: ${larguraComp}`;
        }
        if (row.tipo_componente === "VIST") {
          return `${alturaComp} ${larguraComp}mm`;
        }
        return "";
      },
    },
    {
      dataField: "quantidade",
      text: "Qtd",
      align: "center",
      headerAlign: "center",
      formatter: (c) => formatNumber(c, true, 2, true),
    },
    {
      dataField: "vlr_unit",
      text: "V. Bruto",
      align: "right",
      headerAlign: "right",
      formatter: (c) => formatNumber(c, true, 2),
    },
    {
      dataField: "vlr_item",
      text: "V. Líquido",
      align: "right",
      headerAlign: "right",
      formatter: (c) => formatNumber(c, true, 2),
    },
    {
      dataField: "vlr_total",
      text: "Valor Total",
      align: "right",
      headerAlign: "right",
      formatter: (c) => formatNumber(c, true, 2),
    },
    {
      dataField: "act_delete",
      text: "",
      align: "center",
      headerAlign: "center",
      formatter: (c, row) => (
        <TableDelete
          onClick={() => {
            if (handleDeleteItem) {
              handleDeleteItem(row.id);
            }
          }}
        />
      ),
    },
  ];

  return (
    <Table
      data={data}
      columns={columns}
      paginated
      selectable={false}
      defineSize={false}
      growIntoPageSize
      showRegisterCount={false}
    />
  );
};

export default ComponentesGrid;
