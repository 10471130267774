import React from "react";
import { Document, Image, StyleSheet, Text, View } from "@react-pdf/renderer";
import {
  A4Page,
  ReportDataList,
  ReportDataListMultiRow,
  ReportFooter,
} from "../../../../components/pdf";
import { formatNumber, formatValueFromAPI } from "../../../../coreUtils";
import moment from "moment";

const styles = StyleSheet.create({
  text: {
    fontSize: "8",
    fontFamily: "Open Sans",
  },
  textBold: {
    fontSize: "8",
    fontFamily: "Open Sans Bold",
  },
  headerWrapper: {
    flexDirection: "row",
    marginBottom: 5,
    borderBottom: "0.5px solid #000",
    paddingBottom: "5px",
  },
  nomeEmpresa: { fontFamily: "Open Sans Bold", fontSize: 9 },
  logo: {
    maxHeight: "100%",
    maxWidth: 75,
    objectFit: "contain",
    alignSelf: "center",
    borderRight: "0.5px solid #000",
    padding: "10 10",
    marginRight: "10",
  },
  headerInfoEmpresa: { fontFamily: "Open Sans", fontSize: 8 },
  headerRow: {
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
  },
  total: {
    fontSize: "10",
    fontFamily: "Open Sans Bold",
    display: "flex",
  },
});

const columns1 = [
  {
    col: 0.4,
    dataField: "item",
    text: "Item",
    align: "center",
  },
  {
    col: 1.6,
    dataField: "descricao_material",
    text: "Material",
    align: "left",
  },
  {
    col: 2.3,
    dataField: "descricao",
    text: "Nome da Porta",
    align: "left",
  },
  {
    col: 1.7,
    dataField: "vao_largura",
    text: "Vão",
    align: "center",
    formatter: (c, row) =>
      `L: ${formatNumber(c, true, 2, true)} | ` +
      `A: ${formatNumber(row.vao_altura, true, 2, true)} | ` +
      `E: ${formatNumber(row.vao_espessura, true, 2, true)}`,
  },
  {
    col: 1.2,
    dataField: "fol_largura_pronta",
    text: "Folha Pronta",
    align: "center",
    formatter: (c, row) =>
      `L: ${formatNumber(c, true, 2, true)} | ` +
      `A: ${formatNumber(row.fol_altura_pronta, true, 2, true)}`,
  },
  {
    col: 1.5,
    dataField: "marco",
    text: "Marco",
    align: "left",
  },
  {
    col: 0.7,
    dataField: "modo_abertura",
    text: "Abertura",
    align: "left",
  },
  {
    col: 0.6,
    dataField: "lado_abertura",
    text: "Lado",
    align: "left",
  },
  {
    col: 2.2,
    dataField: "nome_negativo",
    text: "Cor - Negativo",
    align: "left",
    formatter: (_, row) =>
      row.nome_negativo
        ? `${row.nome_cor_acabamento} - ${row.nome_negativo}`
        : row.nome_cor_acabamento,
  },
];

const columns2 = [
  {
    col: 2,
    dataField: "vista",
    text: "",
    align: "left",
    formatter: (c) => (
      <>
        <Text style={styles.textBold}>Vista: </Text>
        <Text style={styles.text}>{c}</Text>
      </>
    ),
  },
  {
    col: 5,
    dataField: "fechadura",
    text: "",
    align: "left",
    formatter: (c) => (
      <>
        <Text style={styles.textBold}>Fechadura: </Text>
        <Text style={styles.text}>{c}</Text>
      </>
    ),
  },
  {
    col: 1,
    dataField: "tem_usinagem",
    text: "",
    align: "left",
    formatter: (c) => (
      <>
        <Text style={styles.textBold}>Usinagem: </Text>
        <Text style={styles.text}>{c ? "Sim" : "Não"}</Text>
      </>
    ),
  },
  {
    col: 1,
    dataField: "tem_montagem",
    text: "",
    align: "left",
    formatter: (c) => (
      <>
        <Text style={styles.textBold}>Montagem: </Text>
        <Text style={styles.text}>{c ? "Sim" : "Não"}</Text>
      </>
    ),
  },
  {
    col: 3,
    dataField: "vlr_total",
    text: "",
    align: "right",
    formatter: (c) => (
      <>
        <Text style={styles.textBold}>Vlr. Total: </Text>
        <Text style={styles.text}>{formatNumber(c, true, 2)}</Text>
      </>
    ),
  },
];

const columns3 = [
  {
    col: 12,
    dataField: "usinagens_especiais",
    text: "",
    align: "left",
    formatter: (c) => (
      <>
        <Text style={styles.textBold}>Itens Especiais: </Text>
        <Text style={styles.text}>{c?.map((e) => e.nome).join(", ")}</Text>
      </>
    ),
  },
];

const columnsComponentes = [
  {
    dataField: "item",
    text: "#",
    align: "center",
    headerAlign: "center",
    col: 0.5,
  },
  {
    dataField: "desc_tipo_componente",
    text: "Tipo",
    align: "center",
    headerAlign: "center",
    col: 1.5,
  },
  {
    dataField: "descricao_componente",
    text: "Descrição",
    align: "left",
    headerAlign: "left",
    col: 5.7,
    formatter: (c, row) => {
      if (["FOLH"].includes(row.tipo_componente)) {
        const alturaPronta = formatNumber(row.altura_pronta, true, 0, true);
        const larguraPronta = formatNumber(row.largura_pronta, true, 0, true);
        const espessuraComp = formatNumber(
          row.espessura_componente,
          true,
          0,
          true
        );

        if (row.tipo_componente === "FOLH") {
          c += ` ${alturaPronta} x ${larguraPronta} x ${espessuraComp}`;

          if (row.usinar_para_fechadura) {
            c +=
              ` - Usinada ${row.desc_marca_fecha} ${row.desc_tipo_fecha} ` +
              `CX${parseInt(row.tamanho_caixa_fecha)}`;
          }
        }
      }
      return c;
    },
  },
  {
    dataField: "vao",
    text: "Medidas Brutas",
    align: "center",
    headerAlign: "center",
    col: 2,
    formatter: (_, row) => {
      const alturaComp = formatNumber(row.altura_componente, true, 0, true);
      const larguraComp = formatNumber(row.largura_componente, true, 0, true);
      const espessuraComp = formatNumber(
        row.espessura_componente,
        true,
        0,
        true
      );
      if (row.tipo_componente === "FOLH") {
        return `${alturaComp} x ${larguraComp}`;
      }
      if (row.tipo_componente === "MARC") {
        if (isNaN(parseFloat(row.largura_componente))) {
          return `${alturaComp}x${espessuraComp}`;
        }
        return `${alturaComp}x${espessuraComp} R: ${larguraComp}`;
      }
      if (row.tipo_componente === "VIST") {
        return `${alturaComp} ${larguraComp}mm`;
      }
      return "";
    },
  },
  {
    dataField: "quantidade",
    text: "Quantidade",
    align: "center",
    headerAlign: "center",
    col: 0.8,
    formatter: (c) => formatNumber(c, true, 2, true),
  },
  {
    dataField: "vlr_item",
    text: "Unitário",
    align: "right",
    headerAlign: "right",
    col: 0.7,
    formatter: (c) => formatNumber(c, true, 2),
  },
  {
    dataField: "vlr_total",
    text: "Total",
    align: "right",
    headerAlign: "right",
    col: 0.8,
    formatter: (c) => formatNumber(c, true, 2),
  },
];

export const PedidoPDF = ({ data }) => {
  const dataAgora = moment(new Date());
  const impressao = `Impresso em ${dataAgora.format(
    "DD/MM/YYYY"
  )} às ${dataAgora.format("HH:mm")}`;

  return (
    <Document>
      <A4Page orientation="landscape">
        <View style={styles.headerWrapper}>
          {data.logo_empresa && data.logo_empresa !== "" && (
            <Image style={styles.logo} src={data.logo_empresa} />
          )}
          <View style={{ width: "100%" }}>
            <View style={styles.headerRow}>
              <Text style={styles.nomeEmpresa}>{data.nome_empresa}</Text>
            </View>
            <Text style={styles.headerInfoEmpresa}>{data.cidade_empresa}</Text>
            <Text style={styles.headerInfoEmpresa}>
              Fone: {data.telefone_empresa}
            </Text>
            <Text style={styles.headerInfoEmpresa}>
              Pedido {data.nro_pedido} - {data.descricao_ped}
            </Text>
            <Text style={styles.headerInfoEmpresa}>
              Cliente:{" "}
              {data.id_cliente
                ? formatValueFromAPI(data.nome_cliente, data.id_cliente)
                : data.nome_cliente}
            </Text>
            <View style={styles.headerRow}>
              <Text style={styles.headerInfoEmpresa}>
                Entrega: {data.tem_entrega ? "Sim" : "Não"}
              </Text>
              <Text style={styles.headerInfoEmpresa}>{impressao}</Text>
            </View>
          </View>
        </View>
        <ReportDataListMultiRow
          data={data.itens}
          columns={[columns1, columns2, columns3]}
          stripped
          rowStyle={{ paddingBottom: "5px" }}
          headerStyle={{ backgroundColor: "#DDD" }}
        />
        {data.componentes.length > 0 && (
          <>
            <View
              style={{
                display: "flex",
                justifyContent: "start",
                flexDirection: "row",
                width: "100%",
                paddingTop: "5px",
                paddingBottom: "5px",
              }}
            >
              <Text style={styles.total}>Componentes</Text>
            </View>
            <ReportDataList
              data={data.componentes}
              columns={columnsComponentes}
            />
          </>
        )}
        <View
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row",
            width: "100%",
            marginTop: "7px",
            borderColor: "#bbb",
            borderStyle: "solid",
            borderTop: "0.5px",
          }}
        >
          <Text style={styles.total}>
            Quantidade de Kits: {formatNumber(data.qtd_itens, true, 0)}
          </Text>
          <Text style={styles.total}>
            Quantidade de Componentes:{" "}
            {formatNumber(data.qtd_componentes, true, 0)}
          </Text>
          <Text style={{ ...styles.total, textAlign: "center" }}>
            Frete: {formatNumber(data.soma_frete, true, 2)}
          </Text>
          <Text style={{ ...styles.total, textAlign: "center" }}>
            Instalação: {formatNumber(data.vlr_servico_instalacao, true, 2)}
          </Text>
          <Text style={{ ...styles.total, textAlign: "center" }}>
            Espuma Expansiva: {formatNumber(data.vlr_espuma_expansiva, true, 2)}
          </Text>
          <Text style={{ ...styles.total, textAlign: "right" }}>
            Total do Pedido: {formatNumber(data.vlr_total, true, 2)}
          </Text>
        </View>
        {data.observ && (
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              marginTop: "7px",
              borderColor: "#bbb",
              borderStyle: "solid",
              borderTop: "0.5px",
            }}
          >
            <Text style={styles.total}>Observação: {data.observ}</Text>
          </View>
        )}
        <ReportFooter reportNumber="0001_1" />
      </A4Page>
    </Document>
  );
};
