import React, { useState, useEffect } from "react";
import { Nav, NavLink, TabContent, TabPane } from "reactstrap";
import IncluirFolhaPortaForm from "./components/IncluirFolhaPortaForm";
import classNames from "classnames";
import IncluirOutrosForm from "./components/IncluirOutrosForm";
import IncluirMarcoComRebaixoForm from "./components/IncluirMarcoComRebaixoForm";
import IncluirMarcoPassagemForm from "./components/IncluirMarcoPassagemForm";
import IncluirVistaForm from "./components/IncluirVistaForm";
import IncluirFolhaPortaNegativoForm from "./components/IncluirFolhaPortaNegativoForm";
import {
  tiposMaterial,
  tiposMaterialEconomico,
} from "../../../../utils/materiais";

const IncluirComponentes = ({ incluirComponente, params }) => {
  const [tiposMaterialLiberados, setTiposMaterialLiberados] =
    useState(tiposMaterial);

  const [tipoComponente, setTipoComponente] = useState("FOLH");

  useEffect(() => {
    if (params.oferecer_kit_economico === true) {
      setTiposMaterialLiberados(tiposMaterialEconomico);
    }
  }, [params.oferecer_kit_economico]);

  return (
    <>
      <Nav tabs>
        <NavLink
          className={classNames({ active: tipoComponente === "FOLH" })}
          onClick={() => setTipoComponente("FOLH")}
        >
          Folha de Porta
        </NavLink>
        <NavLink
          className={classNames({ active: tipoComponente === "FPCN" })}
          onClick={() => setTipoComponente("FPCN")}
        >
          Folha de Porta C/ Negativo
        </NavLink>
        <NavLink
          className={classNames({ active: tipoComponente === "MRCR" })}
          onClick={() => setTipoComponente("MRCR")}
        >
          Marco Com Rebaixo
        </NavLink>
        <NavLink
          className={classNames({ active: tipoComponente === "MPSG" })}
          onClick={() => setTipoComponente("MPSG")}
        >
          Marco de Passagem
        </NavLink>
        <NavLink
          className={classNames({ active: tipoComponente === "VIST" })}
          onClick={() => setTipoComponente("VIST")}
        >
          Vista
        </NavLink>
        <NavLink
          className={classNames({ active: tipoComponente === "OUTR" })}
          onClick={() => setTipoComponente("OUTR")}
        >
          Outros
        </NavLink>
      </Nav>
      <TabContent activeTab={tipoComponente} className="pt-2">
        <TabPane tabId={"FOLH"}>
          <IncluirFolhaPortaForm
            incluirComponente={incluirComponente}
            tiposMaterial={tiposMaterialLiberados}
            params={params}
          />
        </TabPane>
        <TabPane tabId={"FPCN"}>
          <IncluirFolhaPortaNegativoForm
            incluirComponente={incluirComponente}
            params={params}
          />
        </TabPane>
        <TabPane tabId={"MRCR"}>
          <IncluirMarcoComRebaixoForm
            incluirComponente={incluirComponente}
            tiposMaterial={tiposMaterialLiberados.filter(
              (e) => e.value !== "CRU"
            )}
          />
        </TabPane>
        <TabPane tabId={"MPSG"}>
          <IncluirMarcoPassagemForm incluirComponente={incluirComponente} />
        </TabPane>
        <TabPane tabId={"VIST"}>
          <IncluirVistaForm
            incluirComponente={incluirComponente}
            tiposMaterial={tiposMaterialLiberados.filter(
              (e) => e.value !== "CRU"
            )}
          />
        </TabPane>
        <TabPane tabId={"OUTR"}>
          <IncluirOutrosForm incluirComponente={incluirComponente} />
        </TabPane>
      </TabContent>
    </>
  );
};

export default IncluirComponentes;
