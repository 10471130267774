import { toastr } from "react-redux-toastr";
import React, { useState, useCallback, useEffect } from "react";
import { useRef } from "react";
import FormLabel from "../../../../components/FormLabel";
import { Row, Label } from "reactstrap";
import InfoRecorteModal from "./InfoRecorteModal";
import {
  FormButton,
  IntegerInput,
  TextInput,
  RadioGroup,
  AsyncComboBox,
  ComboBox,
  Divider,
} from "../../../../components";
import { apiGet } from "../../../../api";
import ModalUsinagemFechadura from "./ModalUsinagemFechadura";

const ladosAbertura = [
  { label: "Direita", value: "DIR" },
  { label: "Esquerda", value: "ESQ" },
];

const espessurasPorta = [
  { label: "36mm", value: "36" },
  { label: "41mm", value: "41" },
];

const infosRecorteDefault = {
  tipoRecorte: null,
  deslocPosBaseMm: 0,
  deslocPosDirMm: 0,
  retangAlturaMm: 0,
  retangLarguraMm: 0,
  circularRaioMm: 0,
  incluirBaguete: false,
};

function IncluirKitPortasCorrer({ incluirItem, qtdPortas }) {
  const [nomePorta, setNomePorta] = useState("");
  const [ladoAbertura, setLadoAbertura] = useState();
  const [espessuraPorta, setEspessuraPorta] = useState(
    espessurasPorta[0].value
  );
  const [altura, setAltura] = useState(null);
  const [largura, setLargura] = useState(null);
  const [espessura, setEspessura] = useState(null);
  const [cor, setCor] = useState(null);
  const [fechadura, setFechadura] = useState(null);
  const [larguraVista, setLarguraVista] = useState(null);
  const [negativo, setNegativo] = useState(null);
  const [usinagemKit, setUsinagemKit] = useState(true);
  const [folhaPorta, setFolhaPorta] = useState(null);
  const [marco, setMarco] = useState(null);
  const [concha, setConcha] = useState(null);
  const [batente, setBatente] = useState(null);
  const [caixaTrilho, setCaixaTrilho] = useState(null);
  const [deveBuscarFolha, setDeveBuscarFolha] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  // Recorte
  const [infosRecorte, setInfosRecorte] = useState(infosRecorteDefault);

  const [largurasVistaDisp, setLargurasVistaDisp] = useState([]);
  const [coresDisponiveis, setCoresDisponiveis] = useState([]);

  const nomePortaRef = useRef();
  const inputAlturaRef = useRef();
  const inputLarguraRef = useRef();
  const inputEspessuraRef = useRef();

  const validarPorta = () => {
    if (largura === null) {
      toastr.warning(
        "Campo não informado",
        "Por favor, informe a largura do vão"
      );
      inputLarguraRef.current.focus();
      return false;
    }
    if (altura === null) {
      toastr.warning(
        "Campo não informado",
        "Por favor, informe a altura do vão"
      );
      inputAlturaRef.current.focus();
      return false;
    }
    if (espessura === null) {
      toastr.warning(
        "Campo não informado",
        "Por favor, informe a espessura do vão da parede"
      );
      inputEspessuraRef.current.focus();
      return false;
    }
    if (folhaPorta === null) {
      toastr.warning(
        "Folha não encontrada",
        "Parece que o sistema não conseguiu definir a folha da porta. Por favor, revise os campos"
      );
      return false;
    }
    if (marco === null) {
      toastr.warning(
        "Marco não encontrado",
        "Parece que o sistema não conseguiu definir o marco. Por favor, revise os campos"
      );
      return false;
    }
    if (cor === null) {
      toastr.warning(
        "Cor de Acabamento não informada",
        "Por favor, selecione a cor de acabamento do kit"
      );
      return false;
    }
    if (larguraVista === null) {
      toastr.warning(
        "Vista não informada",
        "Por favor, selecione a largura da vista do kit"
      );
      return false;
    }

    return true;
  };

  const gerarPayload = ({
    somenteUsinagemFechadura = false,
    tamanhoCaixaFechaUsinagem = null,
    tipoFechaUsinagem = null,
    marcaFechaUsinagem = null,
  }) => {
    const payload = {
      descricao: nomePorta,
      vao_largura: largura,
      vao_altura: altura,
      vao_espessura: espessura,
      fol_largura_pronta: folhaPorta.largura_pronta,
      fol_altura_pronta: folhaPorta.altura_pronta,
      seg_fol_largura_pronta: null,
      seg_fol_altura_pronta: null,
      tipo_material: "MDF", // Portas de correr só estão disponíveis no MDF
      tipo_passagem: "CORRER",
      tem_usinagem: usinagemKit,
      tem_montagem: false,
      obs: "",
      id_dobradica: null,
      id_fechadura: fechadura?.value ?? null,
      id_folha: folhaPorta.id,
      id_seg_folha: null,
      id_negativo: negativo?.value ?? null,
      id_caixa_trilho: caixaTrilho,
      id_batente: batente,
      id_puxador: concha?.value ?? null,
      incluir_fecho_unha_vaivem: false,
      id_grelha: null,
      largura_vista: larguraVista,
      quantidades_vista: "",
      lado_abrir: ladoAbertura,
      id_marco: marco.id,
      usinagens_especiais: [],
      tem_recorte: false,
      somente_usinagem_fechadura: somenteUsinagemFechadura,
      tamanho_caixa_fecha_usinagem: tamanhoCaixaFechaUsinagem,
      tipo_fecha_usinagem: tipoFechaUsinagem,
      marca_fecha_usinagem: marcaFechaUsinagem,
    };

    if (infosRecorte.tipoRecorte !== null) {
      payload.tem_recorte = true;

      payload.info_recorte = {
        tipo_recorte: infosRecorte.tipoRecorte,
        desloc_pos_base_mm: infosRecorte.deslocPosBaseMm ?? 0,
        desloc_pos_dir_mm: infosRecorte.deslocPosDirMm ?? 0,
        retang_altura_mm: infosRecorte.retangAlturaMm ?? 0,
        retang_largura_mm: infosRecorte.retangLarguraMm ?? 0,
        circular_raio_mm: infosRecorte.circularRaioMm ?? 0,
        incluir_baguete: infosRecorte.incluirBaguete ?? false,
      };
    }
    return payload;
  };

  const incluirPorta = async () => {
    if (!validarPorta()) return;

    if (await incluirItem(gerarPayload({}))) {
      limparIncluirNovaPorta();
      nomePortaRef.current.focus();
    }
  };

  const incluirPortaSomenteUsinagemFechadura = async (
    tamanhoCaixaFechaUsinagem,
    tipoFechaUsinagem,
    marcaFechaUsinagem
  ) => {
    if (!validarPorta()) return;
    const payload = gerarPayload({
      somenteUsinagemFechadura: true,
      tamanhoCaixaFechaUsinagem: tamanhoCaixaFechaUsinagem,
      tipoFechaUsinagem: tipoFechaUsinagem,
      marcaFechaUsinagem: marcaFechaUsinagem,
    });

    if (await incluirItem(payload)) {
      limparIncluirNovaPorta();
      nomePortaRef.current.focus();
    }
  };

  const limparIncluirNovaPorta = () => {
    setAltura(null);
    inputAlturaRef.current.clear();
    setLargura(null);
    inputLarguraRef.current.clear();
    setEspessura(null);
    inputEspessuraRef.current.clear();
    setNomePorta("");
    nomePortaRef.current.focus();
    setFolhaPorta(null);
    setMarco(null);
    setEspessuraPorta(espessurasPorta[0].value);
    setLadoAbertura(null);
    setInfosRecorte(infosRecorteDefault);
  };

  const validarLargura = () => {
    let countErros = 0;

    if (largura !== null) {
      if (parseFloat(largura) < 300) {
        inputLarguraRef.current.setInvalid("A largura mínima do vão é 300mm");
        countErros++;
      }

      if (parseFloat(largura) > 1800) {
        inputLarguraRef.current.setInvalid("A largura máxima do vão é 1800mm");
        countErros++;
      }
    }

    return countErros;
  };

  const validarAltura = () => {
    let countErros = 0;

    if (altura !== null) {
      if (parseFloat(altura) < 300) {
        inputAlturaRef.current.setInvalid("A altura mínima do vão é 300mm");
        countErros++;
      }
      if (parseFloat(altura) > 2650) {
        inputAlturaRef.current.setInvalid(
          "A altura máxima do vão em portas MDF é de 2650mm"
        );
        countErros++;
      }
    }

    return countErros;
  };

  const botaoIncluir = (
    <FormButton
      md="auto"
      onClick={async () => {
        if ([null, undefined].includes(fechadura)) {
          const pedirDadosUsinaFecha = await new Promise((resolve) =>
            toastr.confirm(
              <h4>
                Você não informou nenhum fechadura. Deseja informar os dados
                para usinagem da folha e do marco?
              </h4>,
              {
                onOk: () => resolve(true),
                onCancel: () => resolve(false),
                okText: "Sim",
                cancelText: "Continuar Sem Fechadura",
              }
            )
          );
          if (pedirDadosUsinaFecha) {
            setModalOpen(true);

            return;
          }
        }

        incluirPorta();
      }}
      disabled={folhaPorta === null}
      disabledHint="A Folha da Porta deve ser encontrada"
    >
      Incluir Porta
    </FormButton>
  );

  const buscarFolha = useCallback(async () => {
    if (
      altura != null &&
      largura != null &&
      espessura != null &&
      espessuraPorta != null &&
      cor != null &&
      deveBuscarFolha
    ) {
      if (validarAltura() + validarLargura > 0) {
        return;
      }

      const ret = await apiGet("/pedido/retorno_vao/", {
        mater: "MDF",
        tipo_abre: "CORRER",
        vao_altu: altura,
        vao_larg: largura,
        vao_espe: espessura,
        esp_folha: espessuraPorta,
        id_negativo: negativo?.value || null,
        id_cor: cor.value,
        largura_vista: larguraVista,
      });

      if (ret) {
        setFolhaPorta(ret.folha_porta);
        setMarco(ret.marco);
        setBatente(ret?.id_batente);
        setCaixaTrilho(ret?.id_caixa_trilho);
      } else {
        setFolhaPorta(null);
        setMarco(null);
        setBatente(null);
        setCaixaTrilho(null);
      }
    } else {
      setFolhaPorta(null);
      setMarco(null);
      setBatente(null);
      setCaixaTrilho(null);
    }
  }, [
    espessuraPorta,
    altura,
    largura,
    espessura,
    cor,
    deveBuscarFolha,
    negativo,
    larguraVista,
  ]);

  useEffect(() => {
    buscarFolha();
  }, [buscarFolha]);

  useEffect(() => {
    if (!cor?.aceita_negativo) {
      setNegativo(null);
    }
  }, [cor]);

  useEffect(() => {
    if (fechadura) {
      setUsinagemKit(true);
    } else {
      setUsinagemKit(false);
    }
  }, [fechadura]);

  const buscarLargurasVistaDisp = async () => {
    const params = {
      tipo_material: "MDF",
      id_cor: cor.value,
    };
    const ret = await apiGet("cadastro/vista/listar_larguras/", params);

    setLargurasVistaDisp(ret);
  };

  useEffect(() => {
    if (cor) {
      buscarLargurasVistaDisp();
    } else {
      setLargurasVistaDisp([]);
      setLarguraVista(null);
    }
  }, [cor]);

  const handleChangeInfoRecorte = (campoAlterado, novoValor) => {
    let novasInfosRecorte = { ...infosRecorte, [campoAlterado]: novoValor };

    if (campoAlterado === "tipoRecorte") {
      if (!["RETANG_PEQ", "RETANG_GRD"].includes(novoValor)) {
        novasInfosRecorte["incluirBaguete"] = false;
      }

      if (novoValor === null) {
        novasInfosRecorte = { ...infosRecorteDefault };
      }
    }

    setInfosRecorte(novasInfosRecorte);
  };

  const buscarCoresDisponiveis = async () => {
    const ret = await apiGet(`cadastro/marco/listar_cores/MDF/`);

    setCoresDisponiveis(ret);
  };

  useEffect(() => {
    buscarCoresDisponiveis();
  }, []);

  return (
    <>
      <Row className="pb-2">
        <TextInput
          md={8}
          label="Endereço da Porta/Ambiente"
          value={nomePorta}
          onChange={setNomePorta}
          ref={nomePortaRef}
          maxLength={15}
        />
      </Row>
      <Row form className="pb-2">
        <IntegerInput
          md="auto"
          label={
            <span>
              Largura do <strong>VÃO</strong>
            </span>
          }
          onChange={(v) => {
            setLargura(v);
            setDeveBuscarFolha(false);
          }}
          placeholder="Em Milímetros"
          value={largura}
          onBlur={() => {
            validarLargura();
            setDeveBuscarFolha(true);
          }}
          ref={inputLarguraRef}
        />
        <FormLabel style={{ paddingTop: "26px" }} className="mr-2">{`${
          largura / 1000
        }m`}</FormLabel>
        <IntegerInput
          md="auto"
          label={
            <span>
              Altura do <strong>VÃO</strong>
            </span>
          }
          onChange={(v) => {
            setAltura(v);
            setDeveBuscarFolha(false);
          }}
          placeholder="Em Milímetros"
          value={altura}
          onBlur={() => {
            validarAltura();
            setDeveBuscarFolha(true);
          }}
          ref={inputAlturaRef}
        />
        <FormLabel style={{ paddingTop: "26px" }} className="mr-2">{`${
          altura / 1000
        }m`}</FormLabel>
        <IntegerInput
          md="auto"
          label={
            <span>
              Espessura da Parede do <strong>VÃO</strong>
            </span>
          }
          onChange={(v) => {
            setEspessura(v);
            setDeveBuscarFolha(false);
          }}
          placeholder="Em Milímetros"
          value={espessura}
          onBlur={() => setDeveBuscarFolha(true)}
          ref={inputEspessuraRef}
        />
        <FormLabel style={{ paddingTop: "26px" }}>{`${
          espessura / 10
        }cm`}</FormLabel>
        {folhaPorta && (
          <>
            <div
              style={{
                display: "block",
                marginLeft: "27px",
                marginRight: "27px",
                fontSize: "12px",
                fontStyle: "italic",
                fontWeight: "bolder",
                paddingTop: "15px",
              }}
            >
              <Label style={{ display: "block", paddingBottom: "5px" }}>
                Folha Crua{" "}
                {`A: ${parseFloat(folhaPorta.altura).toLocaleString("pt-br", {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })} | L: ${parseFloat(folhaPorta.largura).toLocaleString(
                  "pt-br",
                  { minimumFractionDigits: 0, maximumFractionDigits: 0 }
                )} | E: ${parseFloat(folhaPorta.espessura).toLocaleString(
                  "pt-br",
                  { minimumFractionDigits: 0, maximumFractionDigits: 0 }
                )}`}
              </Label>
              <Label style={{ display: "block" }}>
                Folha Pronta{" "}
                {`A: ${parseFloat(folhaPorta.altura_pronta).toLocaleString(
                  "pt-br",
                  { maximumFractionDigits: 0 }
                )} | L: ${parseFloat(folhaPorta.largura_pronta).toLocaleString(
                  "pt-br",
                  { maximumFractionDigits: 0 }
                )}`}
              </Label>
            </div>
          </>
        )}
        {marco && (
          <>
            <div
              style={{
                display: "block",
                marginLeft: "27px",
                marginRight: "27px",
                fontSize: "12px",
                fontStyle: "italic",
                fontWeight: "bolder",
                paddingTop: "15px",
              }}
            >
              <Label style={{ display: "block", paddingBottom: "5px" }}>
                Marco{" "}
                {`C: ${parseFloat(marco.comprimento).toLocaleString("pt-br", {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })} | L: ${parseFloat(marco.espessura).toLocaleString("pt-br", {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })} | R: ${parseFloat(marco.rebaixo).toLocaleString("pt-br", {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })}`}
              </Label>
            </div>
          </>
        )}
      </Row>
      <Row className="pb-2">
        <RadioGroup
          label="Espessura da Porta"
          options={espessurasPorta}
          value={espessuraPorta}
          onChange={setEspessuraPorta}
        />
        <div style={{ width: "27px" }}></div>
        <RadioGroup
          label="Lado de Abertura"
          options={ladosAbertura}
          value={ladoAbertura}
          onChange={setLadoAbertura}
        />
        <ComboBox
          label="Cor de Acabamento"
          options={coresDisponiveis}
          onChange={(_, c) => setCor(c)}
          md="auto"
          value={cor?.value}
          isClearable
          isSearchable={false}
          isDisabled={coresDisponiveis.length === 0}
          className="mr-4"
        />
        {qtdPortas > 0 && botaoIncluir}
      </Row>
      <Divider className="mt-2" />
      <Row className="pb-2">
        <ComboBox
          label="Vista"
          options={largurasVistaDisp}
          onChange={setLarguraVista}
          md={2}
          className="mr-4"
          value={larguraVista}
          isClearable
          isDisabled={largurasVistaDisp.length === 0}
        />
        {(cor?.aceita_negativo ?? false) && (
          <AsyncComboBox
            label="Negativo"
            isConcatField
            concatModelName="negativo"
            defaultOptions
            onChange={(_, negativo) => setNegativo(negativo)}
            md={2}
            isClearable
            placeholder="Sem Negativo"
            isSearchable={false}
          />
        )}
        <AsyncComboBox
          label="Fechadura"
          isConcatField
          concatModelName="fechadura"
          onChange={(_, fechadura) => setFechadura(fechadura)}
          md={4}
          aditional={{ modo_abert: "CORRER" }}
          value={fechadura?.value}
          isClearable
          placeholder="Sem Fechadura"
          defaultOptions
        />
        <AsyncComboBox
          label="Concha"
          isConcatField
          concatModelName="puxador"
          aditional={{ tipo: "CONCHA" }}
          onChange={(_, concha) => setConcha(concha)}
          md={4}
          value={concha?.value}
          isClearable
          defaultOptions
          placeholder="Sem Concha"
        />
      </Row>
      <Row>
        <InfoRecorteModal
          infosRecorte={infosRecorte}
          onChange={handleChangeInfoRecorte}
          lado={ladoAbertura}
        />
        {qtdPortas === 0 && botaoIncluir}
      </Row>
      {modalOpen && (
        <ModalUsinagemFechadura
          onConfirm={incluirPortaSomenteUsinagemFechadura}
          toggle={() => setModalOpen(false)}
          correr
        />
      )}
    </>
  );
}

export default IncluirKitPortasCorrer;
