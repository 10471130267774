import React, { useState, useCallback, useEffect, useMemo } from "react";
import { Row } from "reactstrap";
import {
  ComboBox,
  FormButton,
  IntegerInput,
  NumberInput,
  RadioGroup,
} from "../../../../../components";
import { apiGet } from "../../../../../api";
import { tiposMaterial } from "../../../../../utils/materiais";
import { largurasMarcos } from "../utils";

const IncluirMarcoPassagemForm = ({ incluirComponente }) => {
  const [idComponente, setIdComponente] = useState(null);
  const [descricaoComponente, setDescricaoComponente] = useState(null);
  const [vlrUnitComponente, setVlrUnitComponente] = useState(0);
  const [quantidadeComponente, setQuantidadeComponente] = useState(1);
  const [idCor, setIdCor] = useState(null);
  const [tipoMaterial, setTipoMaterial] = useState(tiposMaterial[0].value);
  const [larguraMarco, setLarguraMarco] = useState(null);
  const [comprimentoMarco, setComprimentoMarco] = useState(null);
  const [comprimentosMarcos, setComprimentosMarcos] = useState([]);

  const [coresDisponiveis, setCoresDisponiveis] = useState([]);

  const [loadingIncluirComponente, setLoadingIncluirComponente] =
    useState(false);

  const limparIncluirNovoComponente = () => {
    setIdComponente(null);
    setDescricaoComponente(null);
    setVlrUnitComponente(0);
    setQuantidadeComponente(1);
    setTipoMaterial(tiposMaterial[0].value);
    setIdCor(null);
    setComprimentoMarco(null);
    setLarguraMarco(null);
  };

  const incluir = async () => {
    setLoadingIncluirComponente(true);
    try {
      const payload = {
        tipo_componente: "MARC",
        id_componente: idComponente,
        descricao_componente: descricaoComponente ?? "",
        quantidade: quantidadeComponente,
        vlr_unit: vlrUnitComponente,
        vlr_total: quantidadeComponente * vlrUnitComponente,
        obs: "",
      };

      if (await incluirComponente(payload)) {
        limparIncluirNovoComponente();
      }
    } finally {
      setLoadingIncluirComponente(false);
    }
  };

  const buscarMarcos = useCallback(async () => {
    if (
      tipoMaterial != null &&
      idCor != null &&
      larguraMarco != null &&
      comprimentoMarco != null
    ) {
      const ret = await apiGet("/cadastro/marco/listar/", {
        tipo_material: tipoMaterial,
        id_cor: idCor,
        comprimento: comprimentoMarco,
        largura: larguraMarco,
        tipo_marco: "PASG",
      });
      if (ret?.length > 0) {
        setIdComponente(ret[0].id);
        setVlrUnitComponente(parseFloat(ret[0].vlr_preco));
      } else {
        setIdComponente(null);
        setVlrUnitComponente(0);
      }
    } else {
      setVlrUnitComponente(0);
      setIdComponente(null);
    }
  }, [tipoMaterial, idCor, comprimentoMarco, larguraMarco]);

  useEffect(() => {
    buscarMarcos();
  }, [buscarMarcos]);

  const buscarComprimentos = async () => {
    const ret = await apiGet("/cadastro/marco/listar_comprimentos/", {
      tipo_material: tipoMaterial,
      id_cor: idCor,
      tipo_marco: "PASG",
    });
    setComprimentosMarcos(ret || []);
    setComprimentoMarco(null);
  };

  useEffect(() => {
    buscarComprimentos();
  }, [tipoMaterial, idCor]);

  let largurasLiberadas = useMemo(() => {
    let larguras = [...largurasMarcos];
    if (tipoMaterial === "PVC") {
      larguras = larguras.filter((e) => ![70, 230].includes(e.value));
      if ([70, 230].includes(larguraMarco)) {
        setLarguraMarco(90);
      }
    }
    return larguras;
  }, [tipoMaterial]);

  const tiposMaterialLiberados = useMemo(() => {
    return [tiposMaterial[0]];
  }, []);

  const buscarCoresDisponiveis = async () => {
    const ret = await apiGet(
      `cadastro/marco/listar_cores/MDF/?tipo_marco=PASG`
    );

    setCoresDisponiveis(ret);
  };

  useEffect(() => {
    buscarCoresDisponiveis();
  }, []);

  return (
    <>
      <Row>
        <RadioGroup
          label="Tipo de Material*"
          options={tiposMaterialLiberados}
          value={tipoMaterial}
          onChange={setTipoMaterial}
        />
        <ComboBox
          label="Cor*"
          options={coresDisponiveis}
          onChange={setIdCor}
          md="auto"
          value={idCor}
          isClearable
          isSearchable={false}
          isDisabled={coresDisponiveis.length === 0}
        />
        <ComboBox
          md={2}
          label="Comprimento do Marco*"
          options={comprimentosMarcos}
          value={comprimentoMarco}
          onChange={setComprimentoMarco}
          isSearchable={false}
        />
        <ComboBox
          md={2}
          label="Largura do Marco*"
          options={largurasLiberadas}
          value={larguraMarco}
          onChange={setLarguraMarco}
          isSearchable={false}
        />
      </Row>
      <Row>
        <IntegerInput
          md={2}
          label="Quantidade"
          onChange={setQuantidadeComponente}
          value={quantidadeComponente}
        />
        <NumberInput
          md={2}
          label="Valor Unitário"
          disabled
          value={vlrUnitComponente}
        />
        <NumberInput
          md={2}
          label="Total do Item"
          disabled
          value={(quantidadeComponente ?? 0) * (vlrUnitComponente ?? 0)}
        />
        <FormButton
          md="auto"
          loading={loadingIncluirComponente}
          onClick={incluir}
          disabled={!idComponente}
          disabledHint="Preencha todos os campos marcados com *"
          color="info"
        >
          Incluir Componente
        </FormButton>
      </Row>
    </>
  );
};

export default IncluirMarcoPassagemForm;
