import React, { useState, useEffect, useRef } from "react";
import { Row } from "reactstrap";
import {
  AsyncComboBox,
  ComboBox,
  FormButton,
  FormCheckbox,
  IntegerInput,
  NumberInput,
  RadioGroup,
} from "../../../../../components";
import { apiGet } from "../../../../../api";
import {
  defaultDebounceTime,
  formatNumber,
  useDebounce,
} from "../../../../../coreUtils";
import InfoRecorteModal from "../../components/InfoRecorteModal";
import {
  espessurasFolha,
  espessurasFolhaPvcM25,
  marcasFechadura,
  tiposFecha,
} from "../utils";
import { useSingleRequest } from "../../../../../api";

const infosRecorteDefault = {
  tipoRecorte: null,
  deslocPosBaseMm: 0,
  deslocPosDirMm: 0,
  retangAlturaMm: 0,
  retangLarguraMm: 0,
  circularRaioMm: 0,
  incluirBaguete: false,
};

const IncluirFolhaPortaForm = ({
  incluirComponente,
  tiposMaterial,
  params,
}) => {
  // Dados componente inserido
  const singleRequestWrapper = useSingleRequest();
  const [idComponente, setIdComponente] = useState(null);
  const [descricaoComponente, setDescricaoComponente] = useState(null);
  const [vlrUnitComponente, setVlrUnitComponente] = useState(0);
  const [quantidadeComponente, setQuantidadeComponente] = useState(1);
  const [alturaFolhaPronta, setAlturaFolhaPronta] = useState(null);
  const [larguraFolhaPronta, setLarguraFolhaPronta] = useState(null);
  const [usinarParaFechadura, setUsinarParaFechadura] = useState(false);
  const [tamanhoCaixaFecha, setTamanhoCaixaFecha] = useState(null);
  const [tipoFechadura, setTipoFechadura] = useState(null);
  const [marcaFechadura, setMarcaFechadura] = useState(
    marcasFechadura[0].value
  );
  const [idFitaBorda, setIdFitaBorda] = useState(null);
  // Recorte
  const [infosRecorte, setInfosRecorte] = useState(infosRecorteDefault);

  // Filtros
  const [espessuraFolha, setEspessuraFolha] = useState(
    espessurasFolha[0].value
  );
  const [tipoMaterial, setTipoMaterial] = useState(tiposMaterial[0].value);
  const [idCor, setIdCor] = useState(null);
  const fitaBordaRef = useRef();

  const [tamanhosCaixaFecha, setTamanhosCaixaFecha] = useState([]);

  const [coresDisponiveis, setCoresDisponiveis] = useState([]);

  const [loadingIncluirComponente, setLoadingIncluirComponente] =
    useState(false);

  const buscarFolha = useDebounce(async () => {
    if (
      (tipoMaterial !== "CRU" && [0, null, undefined].includes(idCor)) ||
      ["", null, undefined].includes(tipoMaterial) ||
      !alturaFolhaPronta ||
      !larguraFolhaPronta ||
      !espessuraFolha
    ) {
      setIdComponente(null);
      setVlrUnitComponente(0);
      return;
    }

    const params = {
      material: tipoMaterial,
      id_cor: idCor,
      altura_pronta: alturaFolhaPronta,
      largura_pronta: larguraFolhaPronta,
      espessura_folha: espessuraFolha,
      com_negativo: false,
    };

    const ret = await singleRequestWrapper((options) =>
      apiGet("/pedido/componentes/buscar_folha/", params, options)
    );

    if (ret) {
      setIdComponente(ret.id_folha);
      setVlrUnitComponente(parseFloat(ret.vlr_unit));
    } else {
      setIdComponente(null);
      setVlrUnitComponente(0);
    }
  }, defaultDebounceTime);

  useEffect(() => {
    buscarFolha();
  }, [
    tipoMaterial,
    idCor,
    alturaFolhaPronta,
    larguraFolhaPronta,
    espessuraFolha,
  ]);

  const limparIncluirNovoComponente = () => {
    setIdComponente(null);
    setDescricaoComponente(null);
    setVlrUnitComponente(0);
    setQuantidadeComponente(1);
    setAlturaFolhaPronta(null);
    setLarguraFolhaPronta(null);
    setUsinarParaFechadura(false);
    setTamanhoCaixaFecha(null);
    setTipoFechadura(null);
    setIdFitaBorda(null);
    setMarcaFechadura(null);
    setInfosRecorte(infosRecorteDefault);
    fitaBordaRef.current.clearValue();
  };

  const incluir = async () => {
    setLoadingIncluirComponente(true);
    try {
      const payload = {
        tipo_componente: "FOLH",
        id_componente: idComponente,
        descricao_componente: descricaoComponente ?? "",
        quantidade: quantidadeComponente,
        vlr_unit: vlrUnitComponente,
        vlr_total: quantidadeComponente * vlrUnitComponente,
        obs: "",
        aux_folha: {
          usinar_para_fechadura: usinarParaFechadura,
          tamanho_caixa_fecha: tamanhoCaixaFecha,
          tipo_fecha: tipoFechadura,
          marca_fecha: marcaFechadura,
          id_negativo: null,
          id_fita_borda: idFitaBorda ?? null,
          altura_pronta: alturaFolhaPronta,
          largura_pronta: larguraFolhaPronta,
          tem_recorte: false,
        },
      };

      if (infosRecorte.tipoRecorte !== null) {
        payload.aux_folha.tem_recorte = true;

        payload.aux_folha.info_recorte = {
          tipo_recorte: infosRecorte.tipoRecorte,
          desloc_pos_base_mm: infosRecorte.deslocPosBaseMm ?? 0,
          desloc_pos_dir_mm: infosRecorte.deslocPosDirMm ?? 0,
          retang_altura_mm: infosRecorte.retangAlturaMm ?? 0,
          retang_largura_mm: infosRecorte.retangLarguraMm ?? 0,
          circular_raio_mm: infosRecorte.circularRaioMm ?? 0,
          incluir_baguete: infosRecorte.incluirBaguete ?? false,
        };
      }

      if (await incluirComponente(payload)) {
        limparIncluirNovoComponente();
      }
    } finally {
      setLoadingIncluirComponente(false);
    }
  };

  const buscarFiltros = async () => {
    const retTamanhosCaixa = await apiGet(
      "/cadastro/fechadura/listar_tamanhos_caixa/",
      { tipo_fechadura: tipoFechadura ?? undefined }
    );
    setTamanhosCaixaFecha(
      (retTamanhosCaixa ?? []).map((e) => ({
        label: String(parseInt(e.tam_caixa)),
        value: parseInt(e.tam_caixa),
      }))
    );
  };

  useEffect(() => {
    buscarFiltros();
  }, []);

  useEffect(() => {
    if (!usinarParaFechadura) {
      setTamanhoCaixaFecha(null);
      setTipoFechadura(null);
      setMarcaFechadura(null);
    } else {
      setMarcaFechadura(marcasFechadura[0].value);
    }
  }, [usinarParaFechadura]);

  const custoBarraReforco = parseFloat(params?.preco_barra_ferro_est_folha);

  const handleChangeInfoRecorte = (campoAlterado, novoValor) => {
    let novasInfosRecorte = { ...infosRecorte, [campoAlterado]: novoValor };

    if (campoAlterado === "tipoRecorte") {
      if (!["RETANG_PEQ", "RETANG_GRD"].includes(novoValor)) {
        novasInfosRecorte["incluirBaguete"] = false;
      }

      if (novoValor === null) {
        novasInfosRecorte = { ...infosRecorteDefault };
      }
    }

    setInfosRecorte(novasInfosRecorte);
  };

  const buscarCoresDisponiveis = async (tipoMat) => {
    const ret = await apiGet(`cadastro/folha_porta/listar_cores/${tipoMat}/`);

    setCoresDisponiveis(ret);
  };

  useEffect(() => {
    setIdCor(null);
    buscarCoresDisponiveis(tipoMaterial);
    if (["PVC", "M25"].includes(tipoMaterial)) {
      setEspessuraFolha(espessurasFolhaPvcM25[0].value);
    }
  }, [tipoMaterial]);

  return (
    <>
      <Row>
        <RadioGroup
          label="Tipo de Material*"
          options={tiposMaterial}
          value={tipoMaterial}
          onChange={setTipoMaterial}
        />
        {tipoMaterial !== "CRU" && (
          <ComboBox
            label="Cor*"
            options={coresDisponiveis}
            onChange={setIdCor}
            aditional={{ desconsidera_crua: true }}
            md="auto"
            value={idCor}
            isSearchable={false}
            isDisabled={coresDisponiveis.length === 0}
          />
        )}
        <RadioGroup
          md={2}
          label="Espessura da Folha*"
          options={
            ["PVC", "M25"].includes(tipoMaterial)
              ? espessurasFolhaPvcM25
              : espessurasFolha
          }
          value={espessuraFolha}
          onChange={setEspessuraFolha}
          isSearchable={false}
        />
        <IntegerInput
          md={2}
          label="Largura da Folha Pronta*"
          value={larguraFolhaPronta}
          onChange={setLarguraFolhaPronta}
          hint={
            custoBarraReforco > 0
              ? `Folhas com largura superior a 1100mm (1,10m) terão adicional de R$ ${formatNumber(
                  custoBarraReforco,
                  true,
                  2
                )} para reforço da estrutura`
              : null
          }
        />
        <IntegerInput
          md={2}
          label="Altura da Folha Pronta*"
          value={alturaFolhaPronta}
          onChange={setAlturaFolhaPronta}
          hint={
            custoBarraReforco > 0
              ? `Folhas com altura superior a 2300mm (2,30m) terão adicional de R$ ${formatNumber(
                  custoBarraReforco,
                  true,
                  2
                )} para reforço da estrutura`
              : null
          }
        />
      </Row>
      <Row>
        {tipoMaterial === "CRU" && (
          <AsyncComboBox
            label="Fita de Borda - Somente para Portas Cruas"
            isConcatField
            concatModelName="fita_borda"
            defaultOptions
            onChange={setIdFitaBorda}
            md={4}
            isClearable
            ref={fitaBordaRef}
          />
        )}
        <FormCheckbox
          md={2}
          label="Usinar para fechadura"
          onChange={() => setUsinarParaFechadura(!usinarParaFechadura)}
          checked={usinarParaFechadura}
          padded={usinarParaFechadura || tipoMaterial === "CRU"}
        />
        {usinarParaFechadura && (
          <>
            <ComboBox
              md={2}
              label="Tamanho da Caixa"
              options={tamanhosCaixaFecha}
              value={tamanhoCaixaFecha}
              onChange={setTamanhoCaixaFecha}
            />
            <ComboBox
              md={2}
              label="Tipo de Fechadura"
              options={tiposFecha}
              value={tipoFechadura}
              onChange={setTipoFechadura}
            />
            <ComboBox
              md={2}
              label="Marca Fechadura"
              options={marcasFechadura}
              value={marcaFechadura}
              onChange={setMarcaFechadura}
            />
          </>
        )}
      </Row>
      <Row>
        <IntegerInput
          md={2}
          label="Quantidade"
          onChange={setQuantidadeComponente}
          value={quantidadeComponente}
        />
        <NumberInput
          md={2}
          label="Valor Unitário"
          disabled
          value={vlrUnitComponente}
        />
        <NumberInput
          md={2}
          label="Total do Item"
          disabled
          value={(quantidadeComponente ?? 0) * (vlrUnitComponente ?? 0)}
        />
        <InfoRecorteModal
          infosRecorte={infosRecorte}
          onChange={handleChangeInfoRecorte}
          mostrarCusto
          custoRecorte={params?.preco_recorte ?? "0"}
        />
        <FormButton
          md="auto"
          loading={loadingIncluirComponente}
          onClick={incluir}
          disabled={!idComponente}
          disabledHint="Preencha todos os campos marcados com *"
          color="info"
        >
          Incluir Componente
        </FormButton>
      </Row>
    </>
  );
};

export default IncluirFolhaPortaForm;
