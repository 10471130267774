import { GerenciamentoPedidos } from "../../pages/pedidos/gerenciamento/GerenciamentoPedidos";
import { ConfirmarPedido } from "../../pages/pedidos/inclusao/ConfirmarPedido";
import { IncluirAlterarPedido } from "../../pages/pedidos/inclusao/IncluirAlterarPedido";

export const alterarPedidoRoute = {
  path: "/pedidos/:id/alterar",
  name: "Alterar Pedido",
  component: IncluirAlterarPedido,
  hideFromMenu: true,
};

export const incluirPedidoRoute = {
  path: "/pedidos/incluir/",
  name: "Incluir Pedido",
  component: IncluirAlterarPedido,
  hideFromMenu: true,
};

export const confirmarPedidoRoute = {
  path: "/pedidos/:id/confirmar/",
  name: "Confirmar Pedido",
  component: ConfirmarPedido,
  hideFromMenu: true,
};

const pedidosRoutes = {
  path: "/pedidos/",
  name: "Pedidos",
  component: GerenciamentoPedidos,
};

export default pedidosRoutes;
