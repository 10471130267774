import { toastr } from "react-redux-toastr";
import React, { useState, useEffect } from "react";
import { useRef } from "react";
import FormLabel from "../../../../components/FormLabel";
import { Row, Label } from "reactstrap";

import {
  FormButton,
  FormCheckbox,
  IntegerInput,
  TextInput,
  RadioGroup,
  AsyncComboBox,
  ModalBase,
  ComboBox,
  Divider,
} from "../../../../components";
import { apiGet, useSingleRequest } from "../../../../api";
import InfoRecorteModal from "./InfoRecorteModal";
import { tiposMaterialKit } from "../../../../utils/materiais";
import { coresPortas } from "../../../../utils/formatacao";
import { useDebounce } from "../../../../coreUtils";
import ModalUsinagemFechadura from "./ModalUsinagemFechadura";

const tiposPassagem = [
  { label: "Porta Dupla", value: "DUPLA" },
  { label: "Vai e Vem", value: "VAIVEM2F" },
];

const ladosAbertura = [
  { label: "Direita", value: "DIR" },
  { label: "Esquerda", value: "ESQ" },
];

const espessurasPorta = [
  { label: "36mm", value: "36" },
  { label: "41mm", value: "41" },
];

const espessurasPortaPVC = [{ label: "36mm", value: "36" }];

const organizaVistas = [
  { label: "1 Fixa 1 Regulável", value: "1F1R" },
  { label: "2 Fixas", value: "2F" },
  { label: "2 Reguláveis", value: "2R" },
];

const infosRecorteDefault = {
  tipoRecorte: null,
  deslocPosBaseMm: 0,
  deslocPosDirMm: 0,
  retangAlturaMm: 0,
  retangLarguraMm: 0,
  circularRaioMm: 0,
  incluirBaguete: false,
};

function IncluirKitPortasDupla({ incluirItem, qtdPortas }) {
  const singleRequestWrapper = useSingleRequest();
  const [nomePorta, setNomePorta] = useState("");
  const [tipoMaterial, setTipoMaterial] = useState(tiposMaterialKit[0].value);
  // Portas Duplas sempre abrem para a direita
  const [ladoAbertura, setLadoAbertura] = useState("DIR");
  const [tipoPassagem, setTipoPassagem] = useState("DUPLA");
  const [espessuraPorta, setEspessuraPorta] = useState(
    espessurasPorta[0].value
  );
  const [altura, setAltura] = useState(null);
  const [largura, setLargura] = useState(null);
  const [espessura, setEspessura] = useState(null);
  const [larguraFolhaAbrir, setLarguraFolhaAbrir] = useState(null);
  const [dividirVaoNoMeio, setDividirVaoNoMeio] = useState(false);
  const [cor, setCor] = useState(null);
  const [dobradica, setDobradica] = useState(null);
  const [fechadura, setFechadura] = useState(null);
  const [incluirFechoUnha, setIncluirFechoUnha] = useState(false);
  const [larguraVista, setLarguraVista] = useState(null);
  const [qtdVistas, setQtdVistas] = useState("1F1R");
  const [negativo, setNegativo] = useState(null);
  const [itensEspeciais, setItensEspeciais] = useState([]);
  const [entregaPreMontada, setEntregarPreMontada] = useState(true);
  const [folhaPortaAbrir, setFolhaPortaAbrir] = useState(null);
  const [folhaPortaFixa, setFolhaPortaFixa] = useState(null);
  const [marco, setMarco] = useState(null);
  const [deveBuscarFolha, setDeveBuscarFolha] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  // Recorte
  const [infosRecorte, setInfosRecorte] = useState(infosRecorteDefault);

  const [largurasVistaDisp, setLargurasVistaDisp] = useState([]);
  const [coresDisponiveis, setCoresDisponiveis] = useState([]);

  // Controle
  const nomePortaRef = useRef();
  const inputAlturaRef = useRef();
  const inputLarguraRef = useRef();
  const inputEspessuraRef = useRef();
  const [loadingIncluir, setLoadingIncluir] = useState(false);

  const validarPorta = () => {
    if (largura == null) {
      toastr.warning(
        "Campo não informado",
        "Por favor, informe a largura do vão"
      );
      inputLarguraRef.current.focus();
      return false;
    }
    if (altura == null) {
      toastr.warning(
        "Campo não informado",
        "Por favor, informe a altura do vão"
      );
      inputAlturaRef.current.focus();
      return false;
    }
    if (espessura == null) {
      toastr.warning(
        "Campo não informado",
        "Por favor, informe a espessura do vão da parede"
      );
      inputEspessuraRef.current.focus();
      return false;
    }
    if (espessura >= 250 && qtdVistas !== "2R") {
      toastr.warning(
        "Atenção",
        "Para espessura maiores que 24cm, é necessário utilizar 2 vistas " +
          "reguláveis"
      );
      return;
    }
    if (folhaPortaAbrir == null) {
      toastr.warning(
        "Folha não encontrada",
        "Parece que o sistema não conseguiu definir a folha da porta. Por favor, revise os campos"
      );
      return false;
    }
    if (marco == null) {
      toastr.warning(
        "Marco não encontrado",
        "Parece que o sistema não conseguiu definir o marco. Por favor, revise os campos"
      );
      return false;
    }
    if (cor == null) {
      toastr.warning(
        "Cor de Acabamento não informada",
        "Por favor, selecione a cor de acabamento do kit"
      );
      return false;
    }
    if (larguraVista == null) {
      toastr.warning(
        "Vista não informada",
        "Por favor, selecione a largura da vista do kit"
      );
      return false;
    }
    if (dobradica == null) {
      toastr.warning(
        "Dobradiça não informada",
        "Por favor, selecione a dobradiça do kit"
      );
      return false;
    }
    if (ladoAbertura == null) {
      toastr.warning(
        "Lado de abertura não informado",
        "Por favor, selecione o lado de abertura"
      );
      return false;
    }

    return true;
  };

  const gerarPayload = ({
    somenteUsinagemFechadura = false,
    tamanhoCaixaFechaUsinagem = null,
    tipoFechaUsinagem = null,
    marcaFechaUsinagem = null,
  }) => {
    const payload = {
      descricao: nomePorta,
      vao_largura: largura,
      vao_altura: altura,
      vao_espessura: espessura,
      fol_largura_pronta: folhaPortaAbrir.largura_pronta,
      fol_altura_pronta: folhaPortaAbrir.altura_pronta,
      seg_fol_largura_pronta: folhaPortaFixa.largura_pronta,
      seg_fol_altura_pronta: folhaPortaFixa.altura_pronta,
      tipo_material: tipoMaterial,
      tipo_passagem: tipoPassagem,
      tem_usinagem: true,
      tem_montagem: entregaPreMontada,
      obs: "",
      id_dobradica: dobradica.value,
      id_fechadura: fechadura?.value ?? null,
      id_folha: folhaPortaAbrir.id,
      id_seg_folha: folhaPortaFixa.id,
      id_negativo: negativo?.value ?? null,
      id_caixa_trilho: null, // Utilizado na inclusão de Kits de Correr
      id_batente: null, // Utilizado na inclusão de Kits de Correr
      id_puxador: null, // Utilizado na inclusão de Kits de Correr
      id_grelha: null,
      incluir_fecho_unha_vaivem: incluirFechoUnha,
      largura_vista: larguraVista,
      quantidades_vista: qtdVistas,
      lado_abrir: ladoAbertura,
      id_marco: marco.id,
      usinagens_especiais: itensEspeciais.map((it) => ({
        id_usinagem: it.value,
      })),
      tem_recorte: false,
      somente_usinagem_fechadura: somenteUsinagemFechadura,
      tamanho_caixa_fecha_usinagem: tamanhoCaixaFechaUsinagem,
      tipo_fecha_usinagem: tipoFechaUsinagem,
      marca_fecha_usinagem: marcaFechaUsinagem,
    };

    if (infosRecorte.tipoRecorte !== null) {
      payload.tem_recorte = true;

      payload.info_recorte = {
        tipo_recorte: infosRecorte.tipoRecorte,
        desloc_pos_base_mm: infosRecorte.deslocPosBaseMm ?? 0,
        desloc_pos_dir_mm: infosRecorte.deslocPosDirMm ?? 0,
        retang_altura_mm: infosRecorte.retangAlturaMm ?? 0,
        retang_largura_mm: infosRecorte.retangLarguraMm ?? 0,
        circular_raio_mm: infosRecorte.circularRaioMm ?? 0,
        incluir_baguete: infosRecorte.incluirBaguete ?? false,
      };
    }

    return payload;
  };

  const incluirPorta = async () => {
    setLoadingIncluir(true);
    try {
      if (validarPorta()) {
        if (await incluirItem(gerarPayload({}))) {
          limparIncluirNovaPorta();
          nomePortaRef.current.focus();
        }
      }
    } finally {
      setLoadingIncluir(false);
    }
  };

  const incluirPortaSomenteUsinagemFechadura = async (
    tamanhoCaixaFechaUsinagem,
    tipoFechaUsinagem,
    marcaFechaUsinagem
  ) => {
    setLoadingIncluir(true);
    try {
      if (validarPorta()) {
        const payload = gerarPayload({
          somenteUsinagemFechadura: true,
          tamanhoCaixaFechaUsinagem: tamanhoCaixaFechaUsinagem,
          tipoFechaUsinagem: tipoFechaUsinagem,
          marcaFechaUsinagem: marcaFechaUsinagem,
        });

        if (await incluirItem(payload)) {
          limparIncluirNovaPorta();
          nomePortaRef.current.focus();
        }
      }
    } finally {
      setLoadingIncluir(false);
    }
  };

  const limparIncluirNovaPorta = () => {
    setAltura(null);
    inputAlturaRef.current.clear();
    setLargura(null);
    inputLarguraRef.current.clear();
    setEspessura(null);
    inputEspessuraRef.current.clear();
    setNomePorta("");
    nomePortaRef.current.focus();
    setFolhaPortaAbrir(null);
    setFolhaPortaFixa(null);
    setMarco(null);
    setEspessuraPorta(espessurasPorta[0].value);
    setLadoAbertura("DIR");
    setInfosRecorte(infosRecorteDefault);
    setQtdVistas("1F1R");
    setEntregarPreMontada(true);
    setLarguraFolhaAbrir(null);
  };

  const botaoIncluir = (
    <FormButton
      onClick={async () => {
        if ([null, undefined].includes(fechadura)) {
          const pedirDadosUsinaFecha = await new Promise((resolve) =>
            toastr.confirm(
              <h4>
                Você não informou nenhum fechadura. Deseja informar os dados
                para usinagem da folha e do marco?
              </h4>,
              {
                onOk: () => resolve(true),
                onCancel: () => resolve(false),
                okText: "Sim",
                cancelText: "Continuar Sem Fechadura",
              }
            )
          );
          if (pedirDadosUsinaFecha) {
            setModalOpen(true);

            return;
          }
        }

        incluirPorta();
      }}
      md="auto"
      disabled={folhaPortaAbrir == null}
      disabledHint="A Folha da Porta deve ser encontrada"
      loading={loadingIncluir}
    >
      Incluir Porta
    </FormButton>
  );

  const validarLargura = () => {
    let countErros = 0;

    if (largura !== null) {
      if (parseFloat(largura) < 300) {
        inputLarguraRef.current.setInvalid("A largura mínima do vão é 300mm");
        countErros++;
      }

      if (parseFloat(largura) > 2700) {
        inputLarguraRef.current.setInvalid("A largura máxima do vão é 2700mm");
        countErros++;
      }
    }

    return countErros;
  };

  const validarAltura = () => {
    let countErros = 0;

    if (altura !== null) {
      if (parseFloat(altura) < 300) {
        inputAlturaRef.current.setInvalid("A altura mínima do vão é 300mm");
        countErros++;
      }
      if (tipoMaterial === "PVC" && parseFloat(altura) > 2750) {
        inputAlturaRef.current.setInvalid(
          "A altura máxima do vão em portas PVC é de 2750mm"
        );
        countErros++;
      }
      if (tipoMaterial === "MDF" && parseFloat(altura) > 2650) {
        inputAlturaRef.current.setInvalid(
          "A altura máxima do vão em portas MDF é de 2650mm"
        );
        countErros++;
      }
    }

    return countErros;
  };

  const buscarFolha = useDebounce(async () => {
    if (
      tipoMaterial != null &&
      altura != null &&
      largura != null &&
      espessura != null &&
      espessuraPorta != null &&
      cor != null &&
      ((larguraFolhaAbrir && tipoPassagem === "DUPLA") ||
        tipoPassagem === "VAIVEM2F") &&
      deveBuscarFolha
    ) {
      if (validarAltura() + validarLargura() > 0) {
        return;
      }

      let negativoConsultar = negativo?.value ?? null;

      const ret = await singleRequestWrapper((options) =>
        apiGet(
          "/pedido/retorno_vao/",
          {
            mater: tipoMaterial,
            tipo_abre: tipoPassagem,
            vao_altu: altura,
            vao_larg: largura,
            vao_espe: espessura,
            esp_folha: espessuraPorta,
            id_cor: cor.value,
            id_negativo: negativoConsultar,
            tam_folha_abrir: larguraFolhaAbrir,
          },
          options
        )
      );

      if (ret) {
        setFolhaPortaAbrir(ret.folha_porta);
        setFolhaPortaFixa(ret.folha_porta_2);
        setMarco(ret.marco);
      } else {
        setFolhaPortaAbrir(null);
        setFolhaPortaFixa(null);
        setMarco(null);
      }
    } else {
      setFolhaPortaAbrir(null);
      setFolhaPortaFixa(null);
      setMarco(null);
    }
  }, 300);

  useEffect(() => {
    setFolhaPortaAbrir(null);
    setFolhaPortaFixa(null);
    setMarco(null);
    buscarFolha();
  }, [
    tipoMaterial,
    tipoPassagem,
    espessuraPorta,
    altura,
    largura,
    espessura,
    cor,
    deveBuscarFolha,
    negativo,
    larguraFolhaAbrir,
  ]);

  useEffect(() => {
    if (!cor?.aceita_negativo) {
      setNegativo(null);
    }
  }, [cor]);

  const buscarCoresDisponiveis = async (tipoMat, usaKitEco) => {
    const ret = await apiGet(
      `cadastro/marco/listar_cores/${tipoMat}/?usa_kit_eco=${usaKitEco}`
    );

    setCoresDisponiveis(ret);
  };

  useEffect(() => {
    setCor(null);
    if (tipoMaterial === "PVC") {
      setEspessuraPorta(espessurasPortaPVC[0].value);
    }
    if (tipoMaterial) {
      buscarCoresDisponiveis(tipoMaterial);
    } else {
      setCoresDisponiveis([]);
    }
  }, [tipoMaterial]);

  const buscarLargurasVistaDisp = async () => {
    const params = {
      tipo_material: tipoMaterial,
      id_cor: cor.value,
    };
    const ret = await apiGet("cadastro/vista/listar_larguras/", params);

    setLargurasVistaDisp(ret);
  };

  useEffect(() => {
    if (cor && tipoMaterial) {
      buscarLargurasVistaDisp();
    } else {
      setLargurasVistaDisp([]);
      setLarguraVista(null);
    }
  }, [cor, tipoMaterial]);

  const handleChangeInfoRecorte = (campoAlterado, novoValor) => {
    let novasInfosRecorte = { ...infosRecorte, [campoAlterado]: novoValor };

    if (campoAlterado === "tipoRecorte") {
      if (!["RETANG_PEQ", "RETANG_GRD"].includes(novoValor)) {
        novasInfosRecorte["incluirBaguete"] = false;
      }

      if (novoValor === null) {
        novasInfosRecorte = { ...infosRecorteDefault };
      }
    }

    setInfosRecorte(novasInfosRecorte);
  };

  useEffect(() => {
    setFechadura(null);
    if (tipoPassagem === "DUPLA") {
      setIncluirFechoUnha(false);
    }
  }, [tipoPassagem]);

  return (
    <>
      {modalOpen && (
        <ModalBase
          isOpen
          onConfirm={incluirPorta}
          title="Confirmação"
          size="sm"
          toggle={() => setModalOpen(!modalOpen)}
        >
          Você tem certeza que seja incluir a porta sem montagem do kit?
        </ModalBase>
      )}
      <Row className="pb-2">
        <TextInput
          md={8}
          label="Endereço da Porta/Ambiente"
          value={nomePorta}
          onChange={setNomePorta}
          ref={nomePortaRef}
          maxLength={15}
        />
        <RadioGroup
          label="Tipo de Material"
          options={tiposMaterialKit}
          value={tipoMaterial}
          onChange={setTipoMaterial}
          aditional={
            <>
              <span style={{ color: coresPortas.MDF, fontWeight: "bold" }}>
                Premium HD: Marcos em MDF Plus HD 30mm de Espessura
              </span>
              <br />
              <span style={{ color: coresPortas.PVC, fontWeight: "bold" }}>
                PVC: Marcos em PVC
              </span>
            </>
          }
        />
      </Row>
      <Row form className="pb-2">
        <IntegerInput
          md="auto"
          label={
            <span>
              Largura do <strong>VÃO</strong>
            </span>
          }
          onChange={(v) => {
            setLargura(v);
            setDeveBuscarFolha(false);
          }}
          placeholder="Em Milímetros"
          value={largura}
          onBlur={() => {
            validarLargura();
            setDeveBuscarFolha(true);
          }}
          ref={inputLarguraRef}
        />
        <FormLabel style={{ paddingTop: "26px" }} className="mr-4">{`${
          largura / 1000
        }m`}</FormLabel>
        <IntegerInput
          md="auto"
          label={
            <span>
              Altura do <strong>VÃO</strong>
            </span>
          }
          onChange={(v) => {
            setAltura(v);
            setDeveBuscarFolha(false);
          }}
          placeholder="Em Milímetros"
          value={altura}
          onBlur={() => {
            validarAltura();
            setDeveBuscarFolha(true);
          }}
          ref={inputAlturaRef}
        />
        <FormLabel style={{ paddingTop: "26px" }} className="mr-4">{`${
          altura / 1000
        }m`}</FormLabel>
        <IntegerInput
          md="auto"
          label={
            <span>
              Espessura da Parede do <strong>VÃO</strong>
            </span>
          }
          onChange={(v) => {
            setEspessura(v);
            setDeveBuscarFolha(false);
          }}
          placeholder="Em Milímetros"
          value={espessura}
          onBlur={() => setDeveBuscarFolha(true)}
          ref={inputEspessuraRef}
        />
        <FormLabel style={{ paddingTop: "26px" }}>{`${
          espessura / 10
        }cm`}</FormLabel>
        {folhaPortaAbrir && (
          <>
            <div
              style={{
                display: "block",
                marginLeft: "13.5px",
                marginRight: "13.5px",
                fontSize: "12px",
                fontStyle: "italic",
                fontWeight: "bolder",
                paddingTop: "15px",
              }}
            >
              <Label style={{ display: "block", paddingBottom: "5px" }}>
                Folha {tipoPassagem === "DUPLA" ? "Abrir" : "1"} Bruta{" "}
                {`${parseFloat(folhaPortaAbrir.altura).toLocaleString("pt-br", {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })} x ${parseFloat(folhaPortaAbrir.largura).toLocaleString(
                  "pt-br",
                  { minimumFractionDigits: 0, maximumFractionDigits: 0 }
                )}`}
              </Label>
              <Label style={{ display: "block" }}>
                Folha {tipoPassagem === "DUPLA" ? "Abrir" : "1"} Pronta{" "}
                {`${parseFloat(folhaPortaAbrir.altura_pronta).toLocaleString(
                  "pt-br",
                  { maximumFractionDigits: 0 }
                )} x ${parseFloat(
                  folhaPortaAbrir.largura_pronta
                ).toLocaleString("pt-br", { maximumFractionDigits: 0 })}`}
              </Label>
            </div>
          </>
        )}
        {folhaPortaFixa && (
          <>
            <div
              style={{
                display: "block",
                marginLeft: "13.5px",
                marginRight: "13.5px",
                fontSize: "12px",
                fontStyle: "italic",
                fontWeight: "bolder",
                paddingTop: "15px",
              }}
            >
              <Label style={{ display: "block", paddingBottom: "5px" }}>
                Folha {tipoPassagem === "DUPLA" ? "Fixa" : "2"} Bruta{" "}
                {`${parseFloat(folhaPortaFixa.altura).toLocaleString("pt-br", {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })} x ${parseFloat(folhaPortaFixa.largura).toLocaleString(
                  "pt-br",
                  { minimumFractionDigits: 0, maximumFractionDigits: 0 }
                )}`}
              </Label>
              <Label style={{ display: "block" }}>
                Folha {tipoPassagem === "DUPLA" ? "Fixa" : "2"} Pronta{" "}
                {`${parseFloat(folhaPortaFixa.altura_pronta).toLocaleString(
                  "pt-br",
                  { maximumFractionDigits: 0 }
                )} x ${parseFloat(folhaPortaFixa.largura_pronta).toLocaleString(
                  "pt-br",
                  { maximumFractionDigits: 0 }
                )}`}
              </Label>
            </div>
          </>
        )}
      </Row>
      <Row form>
        <RadioGroup
          label="Espessura da Porta"
          options={
            tipoMaterial === "MDF" ? espessurasPorta : espessurasPortaPVC
          }
          value={espessuraPorta}
          onChange={setEspessuraPorta}
          divClassName="mr-2"
        />
        <RadioGroup
          label="Lado de Abertura"
          options={ladosAbertura}
          value={ladoAbertura}
          disabled
          divClassName="mr-2"
        />
        <RadioGroup
          label="Tipo de Passagem"
          options={tiposPassagem}
          value={tipoPassagem}
          onChange={setTipoPassagem}
        />
        {tipoPassagem === "DUPLA" && (
          <>
            <IntegerInput
              md="auto"
              label="Largura Folha Abrir Principal"
              onChange={(v) => {
                setLarguraFolhaAbrir(v);
                setDeveBuscarFolha(false);
              }}
              placeholder="Em Milímetros"
              value={larguraFolhaAbrir}
              onBlur={() => setDeveBuscarFolha(true)}
            />
            <FormLabel style={{ paddingTop: "26px" }}>{`${
              larguraFolhaAbrir / 10
            }cm`}</FormLabel>
            <FormButton
              color="dark"
              label=""
              onClick={() => {
                setLarguraFolhaAbrir(parseInt((largura - 78) / 2));
                setDeveBuscarFolha(true);
              }}
              disabled={!largura}
              disabledHint="Por favor, informe a largura do vão"
            >
              Dividir Vão no Meio
            </FormButton>
          </>
        )}
      </Row>
      <Row>
        <ComboBox
          label="Cor de Acabamento"
          options={coresDisponiveis}
          onChange={(_, c) => setCor(c)}
          md="auto"
          value={cor?.value}
          isClearable
          isSearchable={false}
          isDisabled={coresDisponiveis.length === 0}
          className="mr-4"
        />
        {qtdPortas > 0 && botaoIncluir}
      </Row>
      <Divider className="mt-2" />
      <Row className="pb-2">
        <RadioGroup
          label="Vistas"
          options={organizaVistas}
          value={qtdVistas}
          onChange={setQtdVistas}
        />
        <ComboBox
          label="Vista"
          options={largurasVistaDisp}
          onChange={setLarguraVista}
          md={2}
          value={larguraVista}
          isClearable
          isDisabled={largurasVistaDisp.length === 0}
        />
        {cor?.aceita_negativo === true && (
          <AsyncComboBox
            label="Negativo"
            isConcatField
            concatModelName="negativo"
            defaultOptions
            onChange={(_, negativo) => setNegativo(negativo)}
            md={2}
            isClearable
            placeholder="Sem Negativo"
          />
        )}
      </Row>
      <Row className="pb-2">
        <AsyncComboBox
          label="Fechadura"
          isConcatField
          concatModelName="fechadura"
          onChange={(_, fechadura) => setFechadura(fechadura)}
          md={5}
          aditional={{ modo_abert: tipoPassagem }}
          value={fechadura?.value}
          isClearable
          defaultOptions
          placeholder={
            tipoPassagem === "VAIVEM2F" ? "Sem Fechadura" : "Selecione..."
          }
        />
        {tipoPassagem === "VAIVEM2F" && fechadura && (
          <FormCheckbox
            style={{ zIndex: 0 }}
            label="Incluir Fecho Unha"
            onChange={() => setIncluirFechoUnha(!incluirFechoUnha)}
            checked={incluirFechoUnha}
          />
        )}
        <AsyncComboBox
          label="Dobradiça"
          isConcatField
          concatModelName="dobradica"
          aditional={{ modo_abert: tipoPassagem }}
          defaultOptions
          onChange={(_, dobradica) => setDobradica(dobradica)}
          md={5}
          isClearable
        />
      </Row>
      <Row>
        <AsyncComboBox
          label="Itens Especiais"
          concatModelName="usinagens_especiais"
          aditional={{ pode_incluir_porta_dupla: true }}
          isConcatField
          defaultOptions
          isMulti
          onChange={setItensEspeciais}
          md={4}
        />
      </Row>
      <Row>
        <FormCheckbox
          style={{ zIndex: 0 }}
          label="Entregar pré-montada"
          onChange={() => setEntregarPreMontada(!entregaPreMontada)}
          checked={entregaPreMontada}
          disabled
        />
        <InfoRecorteModal
          infosRecorte={infosRecorte}
          onChange={handleChangeInfoRecorte}
          lado={ladoAbertura ?? "DIR"}
          portaDupla
        />
        {qtdPortas === 0 && botaoIncluir}
      </Row>
      {modalOpen && (
        <ModalUsinagemFechadura
          onConfirm={incluirPortaSomenteUsinagemFechadura}
          toggle={() => setModalOpen(false)}
        />
      )}
    </>
  );
}

export default IncluirKitPortasDupla;
