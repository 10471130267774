import { toastr } from "react-redux-toastr";
import React, { useState, useEffect } from "react";
import { useRef } from "react";
import FormLabel from "../../../../components/FormLabel";
import { Row, Label } from "reactstrap";

import {
  FormButton,
  FormCheckbox,
  IntegerInput,
  TextInput,
  RadioGroup,
  AsyncComboBox,
  ComboBox,
  Divider,
} from "../../../../components";
import { apiGet, useSingleRequest } from "../../../../api";
import InfoRecorteModal from "./InfoRecorteModal";
import {
  tiposMaterialKit,
  tiposMaterialKitEconomico,
} from "../../../../utils/materiais";
import { coresPortas } from "../../../../utils/formatacao";
import { useMemo } from "react";
import { useDebounce } from "../../../../coreUtils";
import ModalUsinagemFechadura from "./ModalUsinagemFechadura";

const ladosAbertura = [
  { label: "Direita", value: "DIR" },
  { label: "Esquerda", value: "ESQ" },
];

const tiposPassagem = [
  { label: "Abrir", value: "ABRIR" },
  { label: "Camarão", value: "CAMARAO" },
  { label: "Vai-Vem", value: "VAIVEM1F" },
];

const tiposPassagemPvcEconomico = [{ label: "Abrir", value: "ABRIR" }];

const espessurasPorta = [
  { label: "36mm", value: "36" },
  { label: "41mm", value: "41" },
];

const espessurasPortaMdf25mm = [{ label: "36mm", value: "36" }];

const organizaVistas = [
  { label: "1 Fixa 1 Regulável", value: "1F1R" },
  { label: "2 Fixas", value: "2F" },
  { label: "2 Reguláveis", value: "2R" },
];

const infosRecorteDefault = {
  tipoRecorte: null,
  deslocPosBaseMm: 0,
  deslocPosDirMm: 0,
  retangAlturaMm: 0,
  retangLarguraMm: 0,
  circularRaioMm: 0,
  incluirBaguete: false,
};

function IncluirKitPortasAbrir({ params, incluirItem, qtdPortas }) {
  const singleRequestWrapper = useSingleRequest();
  const [nomePorta, setNomePorta] = useState("");
  const [tipoMaterial, setTipoMaterial] = useState(tiposMaterialKit[0].value);
  const [ladoAbertura, setLadoAbertura] = useState();
  const [tipoPassagem, setTipoPassagem] = useState("ABRIR");
  const [espessuraPorta, setEspessuraPorta] = useState(
    espessurasPorta[0].value
  );
  const [altura, setAltura] = useState(null);
  const [largura, setLargura] = useState(null);
  const [espessura, setEspessura] = useState(null);
  const [cor, setCor] = useState(null);
  const [dobradica, setDobradica] = useState(null);
  const [fechadura, setFechadura] = useState(null);
  const [grelha, setGrelha] = useState(null);
  const [larguraVista, setLarguraVista] = useState(null);
  const [qtdVistas, setQtdVistas] = useState("1F1R");
  const [negativo, setNegativo] = useState(null);
  const [itensEspeciais, setItensEspeciais] = useState([]);
  const [entregaPreMontada, setEntregarPreMontada] = useState(true);
  const [adicionarGrelha, setAdicionarGrelha] = useState(false);
  const [folhaPorta, setFolhaPorta] = useState(null);
  const [marco, setMarco] = useState(null);
  const [deveBuscarFolha, setDeveBuscarFolha] = useState(false);
  const [incluirPortaSemMontagem, setIncluirPortaSemMontagem] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [idKitFerragem, setIdKitFerragem] = useState(null);

  // Recorte
  const [infosRecorte, setInfosRecorte] = useState(infosRecorteDefault);

  const [largurasVistaDisp, setLargurasVistaDisp] = useState([]);
  const [coresDisponiveis, setCoresDisponiveis] = useState([]);

  // Controle
  const nomePortaRef = useRef();
  const inputAlturaRef = useRef();
  const inputLarguraRef = useRef();
  const inputEspessuraRef = useRef();
  const [utilizarFolhaKitEconomico, setUtilizarFolhaKitEconomico] =
    useState(false);
  const [loadingIncluir, setLoadingIncluir] = useState(false);
  const [tiposMaterialLiberados, setTiposMaterialLiberados] =
    useState(tiposMaterialKit);

  const temKitEconomico = useMemo(
    () => params.oferecer_kit_economico === true,
    [params.oferecer_kit_economico]
  );

  useEffect(() => {
    if (temKitEconomico) {
      // Ajusta a descrição do MDF 25mm para adicionar a descrição Econômico
      // abaixo do item
      setTiposMaterialLiberados(tiposMaterialKitEconomico);
    }
  }, [temKitEconomico]);

  const validarPorta = async () => {
    if (largura == null) {
      toastr.warning(
        "Campo não informado",
        "Por favor, informe a largura do vão"
      );
      inputLarguraRef.current.focus();
      return false;
    }
    if (altura == null) {
      toastr.warning(
        "Campo não informado",
        "Por favor, informe a altura do vão"
      );
      inputAlturaRef.current.focus();
      return false;
    }
    if (espessura == null) {
      toastr.warning(
        "Campo não informado",
        "Por favor, informe a espessura do vão da parede"
      );
      inputEspessuraRef.current.focus();
      return false;
    }
    if (espessura >= 250 && qtdVistas !== "2R") {
      toastr.warning(
        "Atenção",
        "Para espessura maiores que 24cm, é necessário utilizar 2 vistas " +
          "reguláveis"
      );
      return;
    }
    if (folhaPorta == null) {
      toastr.warning(
        "Folha não encontrada",
        "Parece que o sistema não conseguiu definir a folha da porta. Por favor, revise os campos"
      );
      return false;
    }
    if (marco == null) {
      toastr.warning(
        "Marco não encontrado",
        "Parece que o sistema não conseguiu definir o marco. Por favor, revise os campos"
      );
      return false;
    }
    if (cor == null) {
      toastr.warning(
        "Cor de Acabamento não informada",
        "Por favor, selecione a cor de acabamento do kit"
      );
      return false;
    }
    if (larguraVista == null) {
      toastr.warning(
        "Vista não informada",
        "Por favor, selecione a largura da vista do kit"
      );
      return false;
    }
    if (dobradica == null) {
      toastr.warning(
        "Dobradiça não informada",
        "Por favor, selecione a dobradiça do kit"
      );
      return false;
    }
    if (ladoAbertura == null) {
      toastr.warning(
        "Lado de abertura não informado",
        "Por favor, selecione o lado de abertura"
      );
      return false;
    }

    if (!entregaPreMontada && !incluirPortaSemMontagem) {
      const incluir = await new Promise((resolve) =>
        toastr.confirm(
          <h4>
            Você tem certeza que seja incluir a porta sem montagem do kit?
          </h4>,
          {
            onOk: () => resolve(true),
            onCancel: () => resolve(false),
            okText: "Sim",
            cancelText: "Não",
          }
        )
      );
      if (!incluir) {
        return false;
      }
    }

    return true;
  };

  const gerarPayload = ({
    somenteUsinagemFechadura = false,
    tamanhoCaixaFechaUsinagem = null,
    tipoFechaUsinagem = null,
    marcaFechaUsinagem = null,
  }) => {
    const payload = {
      descricao: nomePorta,
      vao_largura: largura,
      vao_altura: altura,
      vao_espessura: espessura,
      fol_largura_pronta: folhaPorta.largura_pronta,
      fol_altura_pronta: folhaPorta.altura_pronta,
      seg_fol_largura_pronta: null,
      seg_fol_altura_pronta: null,
      tipo_material: tipoMaterial,
      tipo_passagem: tipoPassagem,
      tem_usinagem: true,
      tem_montagem: entregaPreMontada,
      obs: "",
      id_dobradica: dobradica.value,
      id_fechadura: fechadura?.value ?? null,
      id_folha: folhaPorta.id,
      id_seg_folha: null,
      id_negativo: negativo?.value ?? null,
      id_grelha: grelha?.value ?? null,
      id_caixa_trilho: null, // Utilizado na inclusão de Kits de Correr
      id_batente: null, // Utilizado na inclusão de Kits de Correr
      id_puxador: null, // Utilizado na inclusão de Kits de Correr
      incluir_fecho_unha_vaivem: false,
      largura_vista: larguraVista,
      quantidades_vista: qtdVistas,
      lado_abrir: ladoAbertura,
      id_marco: marco.id,
      usinagens_especiais: itensEspeciais.map((it) => ({
        id_usinagem: it.value,
      })),
      tem_recorte: false,
      somente_usinagem_fechadura: somenteUsinagemFechadura,
      tamanho_caixa_fecha_usinagem: tamanhoCaixaFechaUsinagem,
      tipo_fecha_usinagem: tipoFechaUsinagem,
      marca_fecha_usinagem: marcaFechaUsinagem,
    };

    if (infosRecorte.tipoRecorte !== null) {
      payload.tem_recorte = true;

      payload.info_recorte = {
        tipo_recorte: infosRecorte.tipoRecorte,
        desloc_pos_base_mm: infosRecorte.deslocPosBaseMm ?? 0,
        desloc_pos_dir_mm: infosRecorte.deslocPosDirMm ?? 0,
        retang_altura_mm: infosRecorte.retangAlturaMm ?? 0,
        retang_largura_mm: infosRecorte.retangLarguraMm ?? 0,
        circular_raio_mm: infosRecorte.circularRaioMm ?? 0,
        incluir_baguete: infosRecorte.incluirBaguete ?? false,
      };
    }

    if (idKitFerragem !== null) {
      payload.id_kit_ferragem = idKitFerragem;
    }

    return payload;
  };

  const incluirPorta = async () => {
    setLoadingIncluir(true);
    try {
      if (await validarPorta()) {
        const payload = gerarPayload({});

        if (await incluirItem(payload)) {
          limparIncluirNovaPorta();
          nomePortaRef.current.focus();
        }
      }
    } finally {
      setLoadingIncluir(false);
    }
  };

  const incluirPortaSomenteUsinagemFechadura = async (
    tamanhoCaixaFechaUsinagem,
    tipoFechaUsinagem,
    marcaFechaUsinagem
  ) => {
    setLoadingIncluir(true);
    try {
      if (await validarPorta()) {
        const payload = gerarPayload({
          somenteUsinagemFechadura: true,
          tamanhoCaixaFechaUsinagem: tamanhoCaixaFechaUsinagem,
          tipoFechaUsinagem: tipoFechaUsinagem,
          marcaFechaUsinagem: marcaFechaUsinagem,
        });

        if (await incluirItem(payload)) {
          limparIncluirNovaPorta();
          nomePortaRef.current.focus();
        }
      }
    } finally {
      setLoadingIncluir(false);
    }
  };

  useEffect(() => {
    if (tipoPassagem !== "CAMARAO") {
      setIdKitFerragem(null);
    }
  }, [tipoPassagem]);

  const limparIncluirNovaPorta = () => {
    setAltura(null);
    inputAlturaRef.current.clear();
    setLargura(null);
    inputLarguraRef.current.clear();
    setEspessura(null);
    inputEspessuraRef.current.clear();
    setNomePorta("");
    nomePortaRef.current.focus();
    setFolhaPorta(null);
    setMarco(null);
    setEspessuraPorta(espessurasPorta[0].value);
    setLadoAbertura(null);
    setInfosRecorte(infosRecorteDefault);
    setQtdVistas("1F1R");
    setEntregarPreMontada(true);
    setUtilizarFolhaKitEconomico(false);
  };

  useEffect(() => {
    if (!entregaPreMontada) {
      setIncluirPortaSemMontagem(false);
    }
  }, [entregaPreMontada]);

  useEffect(() => {
    if (tipoMaterial === "M25") {
      setEspessuraPorta(espessurasPortaMdf25mm[0].value);
      setNegativo(null);
      setLarguraVista(null);
      setUtilizarFolhaKitEconomico(false);
    }
  }, [tipoMaterial]);

  useEffect(() => {
    if (tipoMaterial !== "MDF" || utilizarFolhaKitEconomico) {
      setTipoPassagem(tiposPassagemPvcEconomico[0].value);
    }

    if (utilizarFolhaKitEconomico) {
      setEspessuraPorta(espessurasPortaMdf25mm[0].value);
    }
  }, [tipoMaterial, utilizarFolhaKitEconomico]);

  const botaoIncluir = (
    <FormButton
      onClick={async () => {
        if ([null, undefined].includes(fechadura)) {
          const pedirDadosUsinaFecha = await new Promise((resolve) =>
            toastr.confirm(
              <h4>
                Você não informou nenhum fechadura. Deseja informar os dados
                para usinagem da folha e do marco?
              </h4>,
              {
                onOk: () => resolve(true),
                onCancel: () => resolve(false),
                okText: "Sim",
                cancelText: "Continuar Sem Fechadura",
              }
            )
          );
          if (pedirDadosUsinaFecha) {
            setModalOpen(true);

            return;
          }
        }

        incluirPorta();
      }}
      md="auto"
      disabled={folhaPorta == null}
      disabledHint="A Folha da Porta deve ser encontrada"
      loading={loadingIncluir}
    >
      Incluir Porta
    </FormButton>
  );

  const validarLargura = () => {
    let countErros = 0;

    if (largura !== null) {
      if (parseFloat(largura) < 300) {
        inputLarguraRef.current.setInvalid("A largura mínima do vão é 300mm");
        countErros++;
      }

      if (parseFloat(largura) > 1800) {
        inputLarguraRef.current.setInvalid("A largura máxima do vão é 1800mm");
        countErros++;
      }

      if (tipoMaterial === "M25") {
        if (parseFloat(largura) > 1080) {
          inputLarguraRef.current.setInvalid(
            "A largura máxima do vão em portas MDF 25mm é de 1080mm"
          );
          countErros++;
        }
        if (parseFloat(largura) < 620) {
          inputLarguraRef.current.setInvalid(
            "A largura mínima do vão em portas MDF 25mm é de 620mm"
          );
          countErros++;
        }
      }
    }

    return countErros;
  };

  const validarAltura = () => {
    let countErros = 0;

    if (altura !== null) {
      if (parseFloat(altura) < 300) {
        inputAlturaRef.current.setInvalid("A altura mínima do vão é 300mm");
        countErros++;
      }
      if (tipoMaterial === "PVC" && parseFloat(altura) > 2750) {
        inputAlturaRef.current.setInvalid(
          "A altura máxima do vão em portas PVC é de 2750mm"
        );
        countErros++;
      }
      if (tipoMaterial === "MDF" && parseFloat(altura) > 2650) {
        inputAlturaRef.current.setInvalid(
          "A altura máxima do vão em portas MDF é de 2650mm"
        );
        countErros++;
      }
      if (tipoMaterial === "M25") {
        if (parseFloat(altura) > 2160) {
          inputAlturaRef.current.setInvalid(
            "A altura máxima do vão em portas MDF 25mm é de 2160mm"
          );
          countErros++;
        }
        if (parseFloat(altura) < 1000) {
          inputAlturaRef.current.setInvalid(
            "A altura mínima do vão em portas MDF 25mm é de 1000mm"
          );
          countErros++;
        }
      }
    }

    return countErros;
  };

  const buscarFolha = useDebounce(async () => {
    if (
      tipoMaterial != null &&
      altura != null &&
      largura != null &&
      espessura != null &&
      espessuraPorta != null &&
      cor != null &&
      deveBuscarFolha
    ) {
      if (validarAltura() + validarLargura() > 0) {
        return;
      }

      let negativoConsultar = negativo?.value ?? null;
      if (tipoMaterial === "M25") {
        negativoConsultar = null;
      }

      const ret = await singleRequestWrapper((options) =>
        apiGet(
          "/pedido/retorno_vao/",
          {
            mater: tipoMaterial,
            tipo_abre: tipoPassagem,
            vao_altu: altura,
            vao_larg: largura,
            vao_espe: espessura,
            esp_folha: espessuraPorta,
            id_cor: cor.value,
            id_negativo: negativoConsultar,
            largura_vista: larguraVista,
            utiliza_folha_eco: utilizarFolhaKitEconomico,
          },
          options
        )
      );

      if (ret) {
        setFolhaPorta(ret.folha_porta);
        setMarco(ret.marco);
      } else {
        setFolhaPorta(null);
        setMarco(null);
      }
    } else {
      setFolhaPorta(null);
      setMarco(null);
    }
  }, 300);

  useEffect(() => {
    setFolhaPorta(null);
    setMarco(null);
    buscarFolha();
  }, [
    tipoMaterial,
    espessuraPorta,
    altura,
    largura,
    espessura,
    cor,
    deveBuscarFolha,
    negativo,
    larguraVista,
    utilizarFolhaKitEconomico,
  ]);

  useEffect(() => {
    if (!cor?.aceita_negativo) {
      setNegativo(null);
    }
  }, [cor]);

  useEffect(() => {
    setGrelha(null);
  }, [adicionarGrelha]);

  const buscarCoresDisponiveis = async (tipoMat, usaKitEco) => {
    const ret = await apiGet(
      `cadastro/marco/listar_cores/${tipoMat}/?usa_kit_eco=${usaKitEco}`
    );

    setCoresDisponiveis(ret);
  };

  useEffect(() => {
    setCor(null);
    if (tipoMaterial || utilizarFolhaKitEconomico) {
      buscarCoresDisponiveis(tipoMaterial, utilizarFolhaKitEconomico);
    } else {
      setCoresDisponiveis([]);
    }
  }, [tipoMaterial, utilizarFolhaKitEconomico]);

  const buscarLargurasVistaDisp = async () => {
    const params = {
      tipo_material: tipoMaterial,
      id_cor: cor.value,
    };
    const ret = await apiGet("cadastro/vista/listar_larguras/", params);

    setLargurasVistaDisp(ret);
  };

  useEffect(() => {
    if (cor && tipoMaterial) {
      buscarLargurasVistaDisp();
    } else {
      setLargurasVistaDisp([]);
      setLarguraVista(null);
    }
  }, [cor, tipoMaterial]);

  const handleChangeInfoRecorte = (campoAlterado, novoValor) => {
    let novasInfosRecorte = { ...infosRecorte, [campoAlterado]: novoValor };

    if (campoAlterado === "tipoRecorte") {
      if (!["RETANG_PEQ", "RETANG_GRD"].includes(novoValor)) {
        novasInfosRecorte["incluirBaguete"] = false;
      }

      if (novoValor === null) {
        novasInfosRecorte = { ...infosRecorteDefault };
      }
    }

    setInfosRecorte(novasInfosRecorte);
  };

  useEffect(() => {
    setFechadura(null);
    if (["CAMARAO", "VAIVEM1F"].includes(tipoPassagem)) {
      setEntregarPreMontada(true);
    } else {
      setItensEspeciais([]);
      setAdicionarGrelha(false);
      setGrelha(null);
    }
  }, [tipoPassagem]);

  useEffect(() => {
    if (utilizarFolhaKitEconomico) {
      setNegativo(null);
      setCor(null);
    }
  }, [utilizarFolhaKitEconomico]);

  return (
    <>
      <Row className="pb-2">
        <TextInput
          md={temKitEconomico ? 6 : 8}
          label="Endereço da Porta/Ambiente"
          value={nomePorta}
          onChange={setNomePorta}
          ref={nomePortaRef}
          maxLength={15}
        />
        {temKitEconomico && !["M25", "CRU"].includes(tipoMaterial) && (
          <FormCheckbox
            className="pl-0 pr-2"
            label="Utilizar Folha Econômica"
            checked={utilizarFolhaKitEconomico}
            onChange={() =>
              setUtilizarFolhaKitEconomico(!utilizarFolhaKitEconomico)
            }
          />
        )}
        <RadioGroup
          label="Tipo de Material"
          options={tiposMaterialLiberados}
          value={tipoMaterial}
          onChange={setTipoMaterial}
          aditional={
            <>
              <span style={{ color: coresPortas.MDF, fontWeight: "bold" }}>
                Premium HD: Marcos em MDF Plus HD 30mm de Espessura
              </span>
              <br />
              {temKitEconomico && (
                <>
                  <span style={{ color: coresPortas.M25, fontWeight: "bold" }}>
                    Econômico: Marcos em MDF Plus 25mm de Espessura
                  </span>
                  <br />
                </>
              )}
              <span style={{ color: coresPortas.PVC, fontWeight: "bold" }}>
                PVC: Marcos em PVC
              </span>
            </>
          }
        />
      </Row>
      <Row form className="pb-2">
        <IntegerInput
          md="auto"
          label={
            <span>
              Largura do <strong>VÃO</strong>
            </span>
          }
          onChange={(v) => {
            setLargura(v);
            setDeveBuscarFolha(false);
          }}
          placeholder="Em Milímetros"
          value={largura}
          onBlur={() => {
            validarLargura();
            setDeveBuscarFolha(true);
          }}
          ref={inputLarguraRef}
        />
        <FormLabel style={{ paddingTop: "26px" }} className="mr-4">{`${
          largura / 1000
        }m`}</FormLabel>
        <IntegerInput
          md="auto"
          label={
            <span>
              Altura do <strong>VÃO</strong>
            </span>
          }
          onChange={(v) => {
            setAltura(v);
            setDeveBuscarFolha(false);
          }}
          placeholder="Em Milímetros"
          value={altura}
          onBlur={() => {
            validarAltura();
            setDeveBuscarFolha(true);
          }}
          ref={inputAlturaRef}
        />
        <FormLabel style={{ paddingTop: "26px" }} className="mr-4">{`${
          altura / 1000
        }m`}</FormLabel>
        <IntegerInput
          md="auto"
          label={
            <span>
              Espessura da Parede do <strong>VÃO</strong>
            </span>
          }
          onChange={(v) => {
            setEspessura(v);
            setDeveBuscarFolha(false);
          }}
          placeholder="Em Milímetros"
          value={espessura}
          onBlur={() => setDeveBuscarFolha(true)}
          ref={inputEspessuraRef}
        />
        <FormLabel style={{ paddingTop: "26px" }}>{`${
          espessura / 10
        }cm`}</FormLabel>
        {folhaPorta && (
          <>
            <div
              style={{
                display: "block",
                marginLeft: "27px",
                marginRight: "27px",
                fontSize: "12px",
                fontStyle: "italic",
                fontWeight: "bolder",
                paddingTop: "15px",
              }}
            >
              <Label style={{ display: "block", paddingBottom: "5px" }}>
                Folha Bruta{" "}
                {`A: ${parseFloat(folhaPorta.altura).toLocaleString("pt-br", {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })} | L: ${parseFloat(folhaPorta.largura).toLocaleString(
                  "pt-br",
                  { minimumFractionDigits: 0, maximumFractionDigits: 0 }
                )} | E: ${parseFloat(folhaPorta.espessura).toLocaleString(
                  "pt-br",
                  { minimumFractionDigits: 0, maximumFractionDigits: 0 }
                )}`}
              </Label>
              <Label style={{ display: "block" }}>
                Folha Pronta{" "}
                {`A: ${parseFloat(folhaPorta.altura_pronta).toLocaleString(
                  "pt-br",
                  { maximumFractionDigits: 0 }
                )} | L: ${parseFloat(folhaPorta.largura_pronta).toLocaleString(
                  "pt-br",
                  { maximumFractionDigits: 0 }
                )}`}
              </Label>
            </div>
          </>
        )}
        {marco && (
          <>
            <div
              style={{
                display: "block",
                marginLeft: "27px",
                marginRight: "27px",
                fontSize: "12px",
                fontStyle: "italic",
                fontWeight: "bolder",
                paddingTop: "15px",
              }}
            >
              <Label style={{ display: "block", paddingBottom: "5px" }}>
                Marco{" "}
                {`C: ${parseFloat(marco.comprimento).toLocaleString("pt-br", {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })} | L: ${parseFloat(marco.espessura).toLocaleString("pt-br", {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })} | R: ${parseFloat(marco.rebaixo).toLocaleString("pt-br", {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })}`}
              </Label>
            </div>
          </>
        )}
      </Row>
      <Row>
        <RadioGroup
          label="Espessura da Porta"
          options={
            tipoMaterial === "MDF" && !utilizarFolhaKitEconomico
              ? espessurasPorta
              : espessurasPortaMdf25mm
          }
          value={espessuraPorta}
          onChange={setEspessuraPorta}
        />
        <div style={{ width: "27px" }}></div>
        <RadioGroup
          label="Lado de Abertura"
          options={ladosAbertura}
          value={ladoAbertura}
          onChange={setLadoAbertura}
        />
        <RadioGroup
          label="Tipo de Passagem"
          options={
            tipoMaterial === "MDF" && !utilizarFolhaKitEconomico
              ? tiposPassagem
              : tiposPassagemPvcEconomico
          }
          value={tipoPassagem}
          onChange={setTipoPassagem}
        />
        <ComboBox
          label="Cor de Acabamento"
          options={coresDisponiveis}
          onChange={(_, c) => setCor(c)}
          md="auto"
          value={cor?.value}
          isClearable
          isSearchable={false}
          isDisabled={coresDisponiveis.length === 0}
          className="mr-4"
        />
        {qtdPortas > 0 && botaoIncluir}
      </Row>
      <Divider className="mt-2" />
      <Row className="pb-2">
        <RadioGroup
          label="Vistas"
          options={organizaVistas}
          value={qtdVistas}
          onChange={setQtdVistas}
        />
        <ComboBox
          label="Vista"
          options={largurasVistaDisp}
          onChange={setLarguraVista}
          md={2}
          value={larguraVista}
          isClearable
          isDisabled={largurasVistaDisp.length === 0}
        />
        {cor?.aceita_negativo === true &&
          tipoMaterial !== "M25" &&
          !utilizarFolhaKitEconomico &&
          tipoPassagem !== "CAMARAO" &&
          tipoPassagem !== "VAIVEM1F" && (
            <AsyncComboBox
              label="Negativo"
              isConcatField
              concatModelName="negativo"
              defaultOptions
              onChange={(_, negativo) => setNegativo(negativo)}
              md={2}
              isClearable
              placeholder="Sem Negativo"
            />
          )}
        {tipoPassagem === "CAMARAO" && (
          <AsyncComboBox
            label="Kit de Ferragem"
            isConcatField
            concatModelName="kit_ferragem"
            aditional={{ tipo_kit: "PTCMR" }}
            defaultOptions
            onChange={setIdKitFerragem}
            placeholder="Selecione um kit de ferragens"
          />
        )}
      </Row>
      <Row className="pb-2">
        <AsyncComboBox
          label="Fechadura"
          isConcatField
          concatModelName="fechadura"
          onChange={(_, fechadura) => setFechadura(fechadura)}
          md={5}
          aditional={{ modo_abert: tipoPassagem }}
          value={fechadura?.value}
          isClearable
          defaultOptions
        />
        <AsyncComboBox
          label="Dobradiça"
          isConcatField
          concatModelName="dobradica"
          aditional={{ modo_abert: tipoPassagem }}
          defaultOptions
          onChange={(_, dobradica) => setDobradica(dobradica)}
          md={5}
          isClearable
        />
      </Row>
      <Row>
        {tipoPassagem !== "CAMARAO" && (
          <>
            <AsyncComboBox
              label="Itens Especiais"
              concatModelName="usinagens_especiais"
              isConcatField
              defaultOptions
              isMulti
              onChange={setItensEspeciais}
              md={4}
            />
            <FormCheckbox
              style={{ zIndex: 0 }}
              label="Adicionar Grelha"
              onChange={() => setAdicionarGrelha(!adicionarGrelha)}
              checked={adicionarGrelha}
            />
            {adicionarGrelha && (
              <AsyncComboBox
                label="Grelha"
                isConcatField
                concatModelName="grelha"
                onChange={(_, grelha) => setGrelha(grelha)}
                md={4}
                value={grelha?.value}
                isClearable
                defaultOptions
              />
            )}
          </>
        )}
      </Row>
      <Row>
        <FormCheckbox
          style={{ zIndex: 0 }}
          label="Entregar pré-montada"
          onChange={() => setEntregarPreMontada(!entregaPreMontada)}
          checked={entregaPreMontada}
          disabled={["CAMARAO", "VAIVEM1F"].includes(tipoPassagem)}
        />
        {tipoPassagem !== "CAMARAO" && (
          <InfoRecorteModal
            infosRecorte={infosRecorte}
            onChange={handleChangeInfoRecorte}
            lado={ladoAbertura}
          />
        )}
        {qtdPortas === 0 && botaoIncluir}
      </Row>
      {modalOpen && (
        <ModalUsinagemFechadura
          onConfirm={incluirPortaSomenteUsinagemFechadura}
          toggle={() => setModalOpen(false)}
        />
      )}
    </>
  );
}

export default IncluirKitPortasAbrir;
