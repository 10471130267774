import React from "react";
import {
  ModalDetalhesIcone,
  TableCheck,
  TableDelete,
} from "../../../../components";
import { formatNumber } from "../../../../coreUtils";
import { Table } from "../../../../components/Table";
import { coresPortas } from "../../../../utils/formatacao";
import { HiPhoto } from "react-icons/hi2";
import { Modal, ModalBody, ModalHeader, UncontrolledTooltip } from "reactstrap";
import { useState } from "react";

export const PortasPedidoGrid = ({ data, handleDeleteItem }) => {
  const [fotoFechadura, setFotoFechadura] = useState(null);
  const [nomeFechadura, setNomeFechadura] = useState(null);
  const [modalFotoFechaIsOpen, setModalFotoFechaIsOpen] = useState(false);

  const toggleModalDetalhes = (row) => {};

  const toggleModalFoto = (row) => {
    setFotoFechadura(row?.foto_fechadura);
    setNomeFechadura(row?.nome_fechadura);
    setModalFotoFechaIsOpen(!modalFotoFechaIsOpen);
  };

  const columns = [
    {
      dataField: "item",
      text: "#",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "descricao_material",
      text: "Material",
      align: "left",
      headerAlign: "left",
    },
    {
      dataField: "descricao",
      text: "Nome da Porta",
      align: "left",
      headerAlign: "left",
    },
    {
      dataField: "vao",
      text: "Vão",
      align: "center",
      headerAlign: "center",
      // A: Altura | L: Largura | E: Espessura
      formatter: (_, row) =>
        `A: ${formatNumber(row.vao_altura, true, 0, true)} ` +
        `L: ${formatNumber(row.vao_largura, true, 0, true)} ` +
        `E: ${formatNumber(row.vao_espessura, true, 0, true)}`,
    },
    {
      dataField: "folha_pronta",
      text: "Folha Pronta",
      align: "center",
      headerAlign: "center",
      // A: Altura | L: Largura
      formatter: (_, row) => {
        if (row.tipo_passagem === "PASSAGEM") {
          return "";
        }

        return (
          `A: ${formatNumber(row.fol_altura_pronta, true, 0, true)} ` +
          `L: ${formatNumber(row.fol_largura_pronta, true, 0, true)}`
        );
      },
    },
    {
      dataField: "modo_abertura",
      text: "Abertura",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "lado_abertura",
      text: "Lado",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "acabamento",
      text: "Cor - Negativo",
      align: "left",
      headerAlign: "left",
      formatter: (_, row) => {
        if (row.nome_negativo) {
          return `${row.nome_cor_acabamento} - ${row.nome_negativo}`;
        } else {
          return row.nome_cor_acabamento;
        }
      },
    },
    {
      dataField: "vista",
      text: "Vista",
      align: "left",
      headerAlign: "left",
    },
    {
      dataField: "fechadura",
      text: "Fechadura",
      align: "left",
      headerAlign: "left",
      formatter: (c, row) => (
        <>
          {c}
          {row.foto_fechadura && (
            <>
              <HiPhoto
                size={16}
                style={{
                  marginLeft: "7px",
                  cursor: "pointer",
                }}
                onClick={() => toggleModalFoto(row)}
                id={`view-photo-fechadura-${row.id}`}
              />
              <UncontrolledTooltip
                target={`view-photo-fechadura-${row.id}`}
                placement="left"
              >
                Visualizar Foto da Fechadura
              </UncontrolledTooltip>
            </>
          )}
        </>
      ),
    },
    {
      dataField: "tem_montagem",
      text: "Pré-Montada",
      align: "center",
      headerAlign: "center",
      formatter: (cell) => <TableCheck value={cell} />,
    },
    {
      dataField: "tem_usinagem",
      text: "Usinagem",
      align: "center",
      headerAlign: "center",
      formatter: (cell) => <TableCheck value={cell} />,
    },
    {
      dataField: "det",
      isDummyField: true,
      align: "center",
      headerAlign: "center",
      formatter: (_, row) => (
        <ModalDetalhesIcone toggle={() => toggleModalDetalhes(row)} />
      ),
      hidden: true,
    },
    {
      dataField: "vlr_total",
      text: "Valor",
      align: "right",
      headerAlign: "right",
      formatter: (c) => formatNumber(c, true, 2),
    },
    {
      dataField: "act_delete",
      text: "",
      align: "center",
      headerAlign: "center",
      formatter: (c, row) => (
        <TableDelete
          onClick={() => {
            if (handleDeleteItem) {
              handleDeleteItem(row.id);
            }
          }}
        />
      ),
    },
  ];

  return (
    <>
      {fotoFechadura && (
        <Modal
          isOpen={modalFotoFechaIsOpen}
          toggle={toggleModalFoto}
          centered
          returnFocusAfterClose={false}
        >
          <ModalHeader toggle={toggleModalFoto}>{nomeFechadura}</ModalHeader>
          <ModalBody style={{ justifyContent: "center", display: "flex" }}>
            <img
              src={fotoFechadura}
              height={275}
              alt="Imagem da Fechadura do Item"
            />
          </ModalBody>
        </Modal>
      )}
      <Table
        data={data}
        columns={columns}
        paginated
        pageSize={data.length}
        growIntoPageSize
        showRegisterCount={false}
        defineSize={false}
        selectable={false}
        rowStyle={(row) => {
          const style = {
            color: coresPortas[row.tipo_material],
            fontWeight: "bold",
          };
          if (row.tipo_passagem === "CAMARAO") {
            style.color = coresPortas.CAMARAO;
          }
          return style;
        }}
      />
    </>
  );
};
