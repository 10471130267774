import React from "react";
import { Provider } from "react-redux";
import ReduxToastr from "react-redux-toastr";
import store from "./redux/store/index.ts";
import "react-datetime/css/react-datetime.css";
import Routes from "./routes/Routes";
import "./assets/scss/classic.scss";

const App = () => {
  return (
    <Provider store={store}>
      <Routes />
      <ReduxToastr
        timeOut={6000}
        newestOnTop={true}
        preventDuplicates
        transitionIn="bounceInDown"
        transitionOut="bounceOutUp"
        closeOnToastrClick
      />
    </Provider>
  );
};

export default App;
