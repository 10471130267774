import { toastr } from "react-redux-toastr";
import React, { useState, useCallback, useEffect } from "react";
import { useRef } from "react";
import FormLabel from "../../../../components/FormLabel";

import {
  FormButton,
  IntegerInput,
  TextInput,
  RadioGroup,
  ComboBox,
} from "../../../../components";
import { apiGet } from "../../../../api";
import { tiposMaterial } from "../../../../utils/materiais";
import { coresPortas } from "../../../../utils/formatacao";
import { useMemo } from "react";
import { Label, Row } from "reactstrap";

const organizaVistas = [
  { label: "1 Fixa 1 Regulável", value: "1F1R" },
  { label: "2 Fixas", value: "2F" },
  { label: "2 Reguláveis", value: "2R" },
];

function IncluirKitMarcoPassagem({ incluirItem }) {
  const [nomePorta, setNomePorta] = useState("");
  const [tipoMaterial, setTipoMaterial] = useState(tiposMaterial[0].value);
  const [altura, setAltura] = useState(null);
  const [largura, setLargura] = useState(null);
  const [espessura, setEspessura] = useState(null);
  const [cor, setCor] = useState(null);
  const [larguraVista, setLarguraVista] = useState(null);
  const [qtdVistas, setQtdVistas] = useState("1F1R");
  const [marco, setMarco] = useState(null);
  const [deveBuscarFolha, setDeveBuscarFolha] = useState(false);

  const [largurasVistaDisp, setLargurasVistaDisp] = useState([]);
  const [coresDisponiveis, setCoresDisponiveis] = useState([]);

  // Controle
  const nomePortaRef = useRef();
  const inputAlturaRef = useRef();
  const inputLarguraRef = useRef();
  const inputEspessuraRef = useRef();
  const [loadingIncluir, setLoadingIncluir] = useState(false);
  const tiposMaterialLiberados = useMemo(
    () => tiposMaterial.filter((e) => e.value === "MDF"),
    []
  );

  const validarPorta = () => {
    if (largura == null) {
      toastr.warning(
        "Campo não informado",
        "Por favor, informe a largura do vão"
      );
      inputLarguraRef.current.focus();
      return false;
    }
    if (altura == null) {
      toastr.warning(
        "Campo não informado",
        "Por favor, informe a altura do vão"
      );
      inputAlturaRef.current.focus();
      return false;
    }
    if (espessura == null) {
      toastr.warning(
        "Campo não informado",
        "Por favor, informe a espessura do vão da parede"
      );
      inputEspessuraRef.current.focus();
      return false;
    }
    if (marco == null) {
      toastr.warning(
        "Marco não encontrado",
        "Parece que o sistema não conseguiu definir o marco. Por favor, revise os campos"
      );
      return false;
    }
    if (cor == null) {
      toastr.warning(
        "Cor de Acabamento não informada",
        "Por favor, selecione a cor de acabamento do kit"
      );
      return false;
    }

    return true;
  };

  const incluirPorta = async () => {
    setLoadingIncluir(true);
    try {
      if (!validarPorta()) return;

      const payload = {
        descricao: nomePorta,
        vao_largura: largura,
        vao_altura: altura,
        vao_espessura: espessura,
        fol_largura_pronta: 0,
        fol_altura_pronta: 0,
        seg_fol_largura_pronta: null,
        seg_fol_altura_pronta: null,
        tipo_material: tipoMaterial,
        tipo_passagem: "PASSAGEM",
        tem_usinagem: true,
        tem_montagem: false,
        obs: "",
        id_dobradica: null,
        id_fechadura: null,
        id_folha: 0,
        id_seg_folha: null,
        id_negativo: null,
        id_grelha: null,
        id_caixa_trilho: null,
        id_batente: null,
        id_puxador: null,
        incluir_fecho_unha_vaivem: false,
        largura_vista: larguraVista,
        quantidades_vista: qtdVistas,
        lado_abrir: null,
        id_marco: marco.id,
        usinagens_especiais: [],
        tem_recorte: false,
        somente_usinagem_fechadura: false,
        tamanho_caixa_fecha_usinagem: null,
        tipo_fecha_usinagem: null,
        marca_fecha_usinagem: null,
      };

      if (await incluirItem(payload)) {
        limparIncluirNovaPorta();
        nomePortaRef.current.focus();
      }
    } finally {
      setLoadingIncluir(false);
    }
  };

  const limparIncluirNovaPorta = () => {
    setAltura(null);
    inputAlturaRef.current.clear();
    setLargura(null);
    inputLarguraRef.current.clear();
    setEspessura(null);
    inputEspessuraRef.current.clear();
    setNomePorta("");
    nomePortaRef.current.focus();
    setMarco(null);
    setQtdVistas("1F1R");
  };

  const botaoIncluir = (
    <FormButton
      onClick={incluirPorta}
      md="auto"
      disabled={marco == null}
      disabledHint="O Marco deve ser encontrado"
      loading={loadingIncluir}
    >
      Incluir Kit
    </FormButton>
  );

  const validarLargura = () => {
    let countErros = 0;

    if (largura !== null) {
      if (parseFloat(largura) < 300) {
        inputLarguraRef.current.setInvalid("A largura mínima do vão é 300mm");
        countErros++;
      }

      if (parseFloat(largura) > 2720) {
        inputLarguraRef.current.setInvalid("A largura máxima do vão é 2720mm");
        countErros++;
      }
    }

    return countErros;
  };

  const validarAltura = () => {
    let countErros = 0;

    if (altura !== null) {
      if (parseFloat(altura) < 300) {
        inputAlturaRef.current.setInvalid("A altura mínima do vão é 300mm");
        countErros++;
      }
      if (parseFloat(altura) > 2720) {
        inputAlturaRef.current.setInvalid("A altura máxima do vão é 2720mm");
        countErros++;
      }
    }

    return countErros;
  };

  const buscarFolha = useCallback(async () => {
    if (
      tipoMaterial != null &&
      altura != null &&
      largura != null &&
      espessura != null &&
      cor != null &&
      deveBuscarFolha
    ) {
      if (validarAltura() + validarLargura() > 0) {
        return;
      }

      const ret = await apiGet("/pedido/retorno_vao/", {
        mater: tipoMaterial,
        tipo_abre: "PASSAGEM",
        vao_altu: altura,
        vao_larg: largura,
        vao_espe: espessura,
        id_cor: cor.value,
      });

      if (ret) {
        setMarco(ret.marco);
      } else {
        setMarco(null);
      }
    } else {
      setMarco(null);
    }
  }, [tipoMaterial, altura, largura, cor, deveBuscarFolha]);

  useEffect(() => {
    buscarFolha();
  }, [buscarFolha]);

  const buscarLargurasVistaDisp = async () => {
    const params = {
      tipo_material: tipoMaterial,
      id_cor: cor.value,
    };
    const ret = await apiGet("cadastro/vista/listar_larguras/", params);

    setLargurasVistaDisp(ret);
  };

  useEffect(() => {
    if (cor && tipoMaterial) {
      buscarLargurasVistaDisp();
    } else {
      setLargurasVistaDisp([]);
      setLarguraVista(null);
    }
  }, [cor, tipoMaterial]);

  const buscarCoresDisponiveis = async () => {
    const ret = await apiGet(
      `cadastro/marco/listar_cores/MDF/?tipo_marco=PASG`
    );

    setCoresDisponiveis(ret);
  };

  useEffect(() => {
    buscarCoresDisponiveis();
  }, []);

  return (
    <>
      <Row className="pb-2">
        <TextInput
          md={8}
          label="Endereço do Kit/Ambiente"
          value={nomePorta}
          onChange={setNomePorta}
          ref={nomePortaRef}
          maxLength={15}
        />
        <RadioGroup
          label="Tipo de Material"
          options={tiposMaterialLiberados}
          value={tipoMaterial}
          onChange={setTipoMaterial}
          aditional={
            <>
              <span style={{ color: coresPortas.MDF, fontWeight: "bold" }}>
                Premium HD: Marcos em MDF Plus HD 30mm de Espessura
              </span>
            </>
          }
        />
      </Row>
      <Row form className="pb-2">
        <IntegerInput
          md="auto"
          label={
            <span>
              Largura do <strong>VÃO</strong>
            </span>
          }
          onChange={(v) => {
            setLargura(v);
            setDeveBuscarFolha(false);
          }}
          placeholder="Em Milímetros"
          value={largura}
          onBlur={() => {
            validarLargura();
            setDeveBuscarFolha(true);
          }}
          ref={inputLarguraRef}
        />
        <FormLabel style={{ paddingTop: "26px" }} className="mr-4">{`${
          largura / 1000
        }m`}</FormLabel>
        <IntegerInput
          md="auto"
          label={
            <span>
              Altura do <strong>VÃO</strong>
            </span>
          }
          onChange={(v) => {
            setAltura(v);
            setDeveBuscarFolha(false);
          }}
          placeholder="Em Milímetros"
          value={altura}
          onBlur={() => {
            validarAltura();
            setDeveBuscarFolha(true);
          }}
          ref={inputAlturaRef}
        />
        <FormLabel style={{ paddingTop: "26px" }} className="mr-4">{`${
          altura / 1000
        }m`}</FormLabel>
        <IntegerInput
          md="auto"
          label={
            <span>
              Espessura da Parede do <strong>VÃO</strong>
            </span>
          }
          onChange={(v) => {
            setEspessura(v);
            setDeveBuscarFolha(false);
          }}
          placeholder="Em Milímetros"
          value={espessura}
          onBlur={() => setDeveBuscarFolha(true)}
          ref={inputEspessuraRef}
        />
        <FormLabel style={{ paddingTop: "26px" }}>{`${
          espessura / 10
        }cm`}</FormLabel>
        {marco && (
          <>
            <div
              style={{
                display: "block",
                marginLeft: "27px",
                marginRight: "27px",
                fontSize: "12px",
                fontStyle: "italic",
                fontWeight: "bolder",
                paddingTop: "15px",
              }}
            >
              <Label style={{ display: "block", paddingBottom: "5px" }}>
                Marco{" "}
                {`C: ${parseFloat(marco.comprimento).toLocaleString("pt-br", {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })} | L: ${parseFloat(marco.espessura).toLocaleString("pt-br", {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })}`}
              </Label>
            </div>
          </>
        )}
      </Row>
      <Row>
        <ComboBox
          label="Cor de Acabamento"
          options={coresDisponiveis}
          onChange={(_, c) => setCor(c)}
          md="auto"
          value={cor?.value}
          isClearable
          isSearchable={false}
          isDisabled={coresDisponiveis.length === 0}
          className="mr-4"
        />
        <RadioGroup
          label="Vistas"
          options={organizaVistas}
          value={qtdVistas}
          onChange={setQtdVistas}
        />
        <ComboBox
          label="Vista"
          options={largurasVistaDisp}
          onChange={setLarguraVista}
          md={2}
          value={larguraVista}
          isClearable
          isDisabled={largurasVistaDisp.length === 0}
        />
        {botaoIncluir}
      </Row>
    </>
  );
}

export default IncluirKitMarcoPassagem;
