import { ComponentType } from "react";
import { Icon } from "react-feather";
import authRoutes from "./modules/auth";
import pedidosRoutes, {
  confirmarPedidoRoute,
  incluirPedidoRoute,
  alterarPedidoRoute,
} from "./modules/pedidos";
import cadastroClienteRoute from "./modules/cadastro_cliente";

export interface RoutePermission {
  id: string;
  liberado: boolean;
}

export interface RouteType {
  id?: string;
  path: string;
  children?: RouteType[];
  name?: string;
  badgeColor?: string;
  badgeText?: string;
  icon?: Icon;
  component?: ComponentType;
  hideFromMenu?: Boolean;
}

export const checkRoutes = (
  routes: RouteType[],
  permissions: RoutePermission[]
) => {
  const allowedRoutes: RouteType[] = [];

  routes.forEach((route) => {
    if (route!.children) {
      // Verifica se a rota possui filhos com permissão
      route.children = checkRoutes(route!.children, permissions);
      if (route.children.length > 0) allowedRoutes.push(route);
    } else {
      allowedRoutes.push(route);
    }
  });

  return allowedRoutes;
};

export const defaultRoutes: RouteType[] = [
  cadastroClienteRoute,
  pedidosRoutes,
  incluirPedidoRoute,
  alterarPedidoRoute,
  confirmarPedidoRoute,
];

export const pageRoutes: RouteType[] = [authRoutes];

const routes: RouteType[] = [cadastroClienteRoute, pedidosRoutes];

// All routes
export default routes;
